import React,{useState,useEffect} from 'react'
import { IoIosMenu } from "react-icons/io";
import Drawer from './components/Drawer';
import { Link, useLocation } from 'react-router-dom';
import logo from './images/logo.png'

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();

    function menu(){
        setIsDrawerOpen(!isDrawerOpen);
    }
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (location.hash) {
          scrollToSection(location.hash.substring(1));
        }
    }, [location])

    const headerOptions= [
        {
            optionName:"Home",
            link:"/"
        },
        {
            optionName:"Aws",
            link:"/#exams"
        },
        {
            optionName:"Microsoft",
            link:"/#exams"
        },
        {
            optionName:"About Us",
            link:"/#aboutus"
        }
    ];

    return (
        <div className='px-3'>
            <div className='h-max w-[1200px] max-w-full mt-5 mx-auto p-3 sm:p-2 flex justify-around items-center flex-row flex-nowrap bg-white shadow-[0px_0px_20px_10px_rgba(205,225,255,0.5)] rounded-full'>
                <Link to={"/"}><img src={logo} alt='AceExams' className='h-[50px] md:h-[40px] sm:h-[30px] m-1'/></Link>

                <div className='h-max w-max max-w-full flex justify-evenly items-center m-1 p-1 flex-row gap-3 font-mulish font-normal sm:m-1 sm:p-1'>
                    {
                        headerOptions.map((ele,id)=>{
                            return <p className='text-base m-2 mx-4 md:hidden sm:hidden' key={id}><Link to={ele.link}>{ele.optionName}</Link></p>
                        })
                    }
                </div>

                <div className='h-max w-max flex justify-center items-center flex-row flex-nowrap gap-2'>
                    <div className='h-max w-max max-w-full flex justify-center items-center flex-col flex-nowrap'>
                        <h1 className='h-max w-max m-0.5 font-mulish font-black text-base sm:text-sm bg-gradient-to-r from-[#0496EE] to-[#2AAFFF] text-white py-1 px-6 rounded-full'>Pro</h1>
                        <h1 className='h-max w-max font-mulish font-normal m-0.5 text-[8px]'>(Coming soon)</h1>
                    </div>

                    <IoIosMenu className='h-max w-max text-2xl cursor-pointer m-2 mx-4 sm:text-lg sm:mx-3 hidden md:block sm:block' onClick={menu}/>
                </div>
            </div>
            <Drawer isOpen={isDrawerOpen} onClose={menu} />        
        </div>
    )
}

export default Header
