export default [

  {
    "1": "Use an AWS Systems Manager Parameter Store SecureString parameter that uses an AWS Key Management Service (AWS KMS) AWS managed key to store the access token. Add a resource-based policy to the parameter to allow access from other accounts. Update the IAM role of the EC2 instances with permissions to access Parameter Store. Retrieve the token from Parameter Store with the decrypt flag enabled. Use the decrypted access token to send the message to the chat.",
    "2": "Encrypt the access token by using an AWS Key Management Service (AWS KMS) customer managed key. Store the access token in an Amazon DynamoDB table. Update the IAM role of the EC2 instances with permissions to access DynamoDB and AWS KMS. Retrieve the token from DynamoDDecrypt the token by using AWS KMS on the EC2 instances. Use the decrypted access token to send the message to the chat.",
    "3": "Use AWS Secrets Manager with an AWS Key Management Service (AWS KMS) customer managed key to store the access token. Add a resource-based policy to the secret to allow access from other accounts. Update the IAM role of the EC2 instances with permissions to access Secrets Manager. Retrieve the token from Secrets Manager. Use the decrypted access token to send the message to the chat.",
    "4": "Encrypt the access token by using an AWS Key Management Service (AWS KMS) AWS managed key. Store the access token in an Amazon S3 bucket. Add a bucket policy to the S3 bucket to allow access from other accounts. Update the IAM role of the EC2 instances with permissions to access Amazon S3 and AWS KMS. Retrieve the token from the S3 bucket. Decrypt the token by using AWS KMS on the EC2 instances. Use the decrypted access token to send the massage to the chat.",
    "5": 1,
    "no": "1",
    "question": "A company is implementing an application on Amazon EC2 instances. The application needs to process incoming transactions. When the application detects a transaction that is not valid, the application must send a chat message to the company's support team. To send the message, the application needs to retrieve the access token to authenticate by using the chat API. A developer needs to implement a solution to store the access token. The access token must be encrypted at rest and in transit. The access token must also be accessible from other AWS accounts. Which solution will meet these requirements with the LEAST management overhead?",
    "answer": "C"
  },
    {
        "1": "Configure Amazon EC2 to deliver the EC2 instance lifecycle events from all accounts to the Amazon EventBridge event bus of the main account. Add an EventBridge rule to the event bus of the main account that matches all EC2 instance lifecycle events. Add the SQS queue as a target of the rule.",
        "2": "Use the resource policies of the SQS queue in the main account to give each account permissions to write to that SQS queue. Add to the Amazon EventBridge event bus of each account an EventBridge rule that matches all EC2 instance lifecycle events. Add the SQS queue in the main account as a target of the rule.",
        "3": "Write an AWS Lambda function that scans through all EC2 instances in the company accounts to detect EC2 instance lifecycle changes. Configure the Lambda function to write a notification message to the SQS queue in the main account if the function detects an EC2 instance lifecycle change. Add an Amazon EventBridge scheduled rule that invokes the Lambda function every minute.",
        "4": "Configure the permissions on the main account event bus to receive events from all accounts. Create an Amazon EventBridge rule in each account to send all the EC2 instance lifecycle events to the main account event bus. Add an EventBridge rule to the main account event bus that matches all EC2 instance lifecycle events. Set the SQS queue as a target for the rule.",
        "5": 1,
        "no": "2",
        "question": "A company is running Amazon EC2 instances in multiple AWS accounts. A developer needs to implement an application that collects all the lifecycle events of the EC2 instances. The application needs to store the lifecycle events in a single Amazon Simple Queue Service (Amazon SQS) queue in the company's main AWS account for further processing.Which solution will meet these requirements?",
        "answer": "D"
      },

      {
        "1": "Use S3 Event Notifications to validate the file upload and download requests and update the user interface (UI).",
        "2": "Save the details of the uploaded files in a separate Amazon DynamoDB table. Filter the list of files in the user interface (UI) by comparing the current user ID with the user ID associated with the file in the table.",
        "3": "Use Amazon API Gateway and an AWS Lambda function to upload and download files. Validate each request in the Lambda function before performing the requested operation.",
        "4": "Use an IAM policy within the Amazon Cognito identity prefix to restrict users to use their own folders in Amazon S3.",
        "5": 1,
        "no": "3",
        "question": "An application is using Amazon Cognito user pools and identity pools for secure access. A developer wants to integrate the user-specific file upload and download features in the application with Amazon S3. The developer must ensure that the files are saved and retrieved in a secure manner and that users can access only their own files. The file sizes range from 3 KB to 300 MB. Which option will meet these requirements with the HIGHEST level of security?",
        "answer": "D"
      },
      {
        "1": "AWS Batch",
        "2": "AWS Step Functions",
        "3": "AWS Glue",
        "4": "AWS Lambda",
        "5": 1,
        "no": "4",
        "question": "A company is building a scalable data management solution by using AWS services to improve the speed and agility of development. The solution will ingest large volumes of data from various sources and will process this data through multiple business rules and transformations. The solution requires business rules to run in sequence and to handle reprocessing of data if errors occur when the business rules run. The company needs the solution to be scalable and to require the least possible maintenance. Which AWS service should the company use to manage and automate the orchestration of the data flows to meet these requirements?",
        "answer": "B"
      },
      {
        "1": "The Lambda function's concurrency limit has been exceeded.",
        "2": "DynamoDB table requires a global secondary index (GSI) to support writes.",
        "3": "The Lambda function does not have IAM permissions to write to DynamoDB.",
        "4": "The DynamoDB table is not running in the same Availability Zone as the Lambda function.",
        "5": 1,
        "no": "5",
        "question": "A developer has created an AWS Lambda function that is written in Python. The Lambda function reads data from objects in Amazon S3 and writes data to an Amazon DynamoDB table. The function is successfully invoked from an S3 event notification when an object is created. However, the function fails when it attempts to write to the DynamoDB table. What is the MOST likely cause of this issue?",
        "answer": "C"
      },
      {
        "1": "Create a separate CloudFormation template for each EC2 instance type in the list.",
        "2": "In the Resources section of the CloudFormation template, create resources for each EC2 instance type in the list.",
        "3": "In the CloudFormation template, create a separate parameter for each EC2 instance type in the list.",
        "4": "In the CloudFormation template, create a parameter with the list of EC2 instance types as AllowedValues.",
        "5": 1,
        "no": "6",
        "question": "A developer is creating an AWS CloudFormation template to deploy Amazon EC2 instances across multiple AWS accounts. The developer must choose the EC2 instances from a list of approved instance types. How can the developer incorporate the list of approved instance types in the CloudFormation template?",
        "answer": "D"
      },
      {
        "1": "Retry the batch operation immediately.",
        "2": "Retry the batch operation with exponential backoff and randomized delay.",
        "3": "Update the application to use an AWS software development kit (AWS SDK) to make the requests.",
        "4": "Increase the provisioned read capacity of the DynamoDB tables that the operation accesses.",
        "5": "Increase the provisioned write capacity of the DynamoDB tables that the operation accesses.",
        "no": "7",
        "question": "A developer is creating an AWS CloudFormation template to deploy Amazon EC2 instances across multiple AWS accounts. The developer must choose the EC2 instances from a list of approved instance types. How can the developer incorporate the list of approved instance types in the CloudFormation template?",
        "answer": "BD"
      },
      {
        "1": "Install and run the X-Ray SDK on the on-premises servers to capture and relay the data to the X-Ray service.",
        "2": "Install and run the X-Ray daemon on the on-premises servers to capture and relay the data to the X-Ray service.",
        "3": "Capture incoming requests on-premises and configure an AWS Lambda function to pull, process, and relay relevant data to X-Ray using the PutTraceSegments API call.",
        "4": "Capture incoming requests on-premises and configure an AWS Lambda function to pull, process, and relay relevant data to X-Ray using the PutTelemetryRecords API call.",
        "5": 1,
        "no": "8",
        "question": "A company is running a custom application on a set of on-premises Linux servers that are accessed using Amazon API Gateway. AWS X-Ray tracing has been enabled on the API test stage. How can a developer enable X-Ray tracing on the on-premises servers with the LEAST amount of configuration?",
        "answer": "B"
      },
      {
        "1": "Store the API credentials in AWS Secrets Manager. Retrieve the API credentials at runtime by using the AWS SDK. Use the credentials to make the API call.",
        "2": "Store the API credentials in a local code variable. Push the code to a secure Git repository. Use the local code variable at runtime to make the API call.",
        "3": "Store the API credentials as an object in a private Amazon S3 bucket. Restrict access to the S3 object by using IAM policies. Retrieve the API credentials at runtime by using the AWS SDK. Use the credentials to make the API call.",
        "4": "Store the API credentials in an Amazon DynamoDB table. Restrict access to the table by using resource-based policies. Retrieve the API credentials at runtime by using the AWS SDK. Use the credentials to make the API call.",
        "5": 1,
        "no": "9",
        "question": "A company wants to share information with a third party. The third party has an HTTP API endpoint that the company can use to share the information. The company has the required API key to access the HTTP API.The company needs a way to manage the API key by using code. The integration of the API key with the application code cannot affect application performance.Which solution will meet these requirements MOST securely?",
        "answer": "B"
      },
      {
        "1": "Update the application to retrieve the variables from AWS Systems Manager Parameter Store. Use unique paths in Parameter Store for each variable in each environment. Store the credentials in AWS Secrets Manager in each environment.",
        "2": "Update the application to retrieve the variables from AWS Key Management Service (AWS KMS). Store the API URL and credentials as unique keys for each environment.",
        "3": "Update the application to retrieve the variables from an encrypted file that is stored with the application. Store the API URL and credentials in unique files for each environment.",
        "4": "Update the application to retrieve the variables from each of the deployed environments. Define the authentication information and API URL in the ECS task definition as unique names during the deployment process.",
        "5": 1,
        "no": "10",
        "question": "A developer is deploying a new application to Amazon Elastic Container Service (Amazon ECS). The developer needs to securely store and retrieve different types of variables. These variables include authentication information for a remote API, the URL for the API, and credentials. The authentication information and API URL must be available to all current and future deployed versions of the application across development, testing, and production environments.How should the developer retrieve the variables with the FEWEST application changes?",
        "answer": "B"
      },
      {
        "1": "Encode each employee's contact information and photos using Base64. Store the information in an Amazon DynamoDB table using a sort key.",
        "2": "Store each employee's contact information in an Amazon DynamoDB table along with the object keys for the photos stored in Amazon S3.",
        "3": "Use Amazon Cognito user pools to implement the employee directory in a fully managed software-as-a-service (SaaS) method.",
        "4": "Store employee contact information in an Amazon RDS DB instance with the photos stored in Amazon Elastic File System (Amazon EFS).",
        "5": 1,
        "no": "11",
        "question": "A company is migrating legacy internal applications to AWS. Leadership wants to rewrite the internal employee directory to use native AWS services. A developer needs to create a solution for storing employee contact details and high-resolution photos for use with the new application. Which solution will enable the search and retrieval of each employee's individual details and high-resolution photos using AWS APIs?",
        "answer": "B"
      },      
      {
        "1": "Use Amazon Cognito user pools to manage user accounts. Create an Amazon Cognito user pool authorizer in API Gateway to control access to the API. Use the Lambda function to store the photos and details in the DynamoDB table. Retrieve previously uploaded photos directly from the DynamoDB table.",
        "2": "Use Amazon Cognito user pools to manage user accounts. Create an Amazon Cognito user pool authorizer in API Gateway to control access to the API. Use the Lambda function to store the photos in Amazon S3. Store the object's S3 key as part of the photo details in the DynamoDB table. Retrieve previously uploaded photos by querying DynamoDB for the S3 key.",
        "3": "Create an IAM user for each user of the application during the sign-up process. Use IAM authentication to access the API Gateway API. Use the Lambda function to store the photos in Amazon S3. Store the object's S3 key as part of the photo details in the DynamoDB table. Retrieve previously uploaded photos by querying DynamoDB for the S3 key.",
        "4": "Create a users table in DynamoDB. Use the table to manage user accounts. Create a Lambda authorizer that validates user credentials against the users table. Integrate the Lambda authorizer with API Gateway to control access to the API. Use the Lambda function to store the photos in Amazon S3. Store the object's S3 key as par of the photo details in the DynamoDB table. Retrieve previously uploaded photos by querying DynamoDB for the S3 key.",
        "5": 1,
        "no": "12",
        "question": "A developer is creating an application that will give users the ability to store photos from their cellphones in the cloud. The application needs to support tens of thousands of users. The application uses an Amazon API Gateway REST API that is integrated with AWS Lambda functions to process the photos. The application stores details about the photos in Amazon DynamoDB. Users need to create an account to access the application. In the application, users must be able to upload photos and retrieve previously uploaded photos. The photos will range in size from 300 KB to 5 MB. Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "B"
      },
      {
        "1": "Create a different Amazon Simple Notification Service (Amazon SNS) topic for each partner. Configure the Lambda function to publish messages for each partner to the partner's SNS topic.",
        "2": "Create a different Lambda function for each partner. Configure the Lambda function to notify each partner's service endpoint directly.",
        "3": "Create an Amazon Simple Notification Service (Amazon SNS) topic. Configure the Lambda function to publish messages with specific attributes to the SNS topic. Subscribe each partner to the SNS topic. Apply the appropriate filter policy to the topic subscriptions.",
        "4": "Create one Amazon Simple Notification Service (Amazon SNS) topic. Subscribe all partners to the SNS topic.",
        "5": 1,
        "no": "13",
        "question": "A company receives food orders from multiple partners. The company has a microservices application that uses Amazon API Gateway APIs with AWS Lambda integration. Each partner sends orders by calling a customized API that is exposed through API Gateway. The API call invokes a shared Lambda function to process the orders. Partners need to be notified after the Lambda function processes the orders. Each partner must receive updates for only the partner's own orders. The company wants to add new partners in the future with the fewest code changes possible. Which solution will meet these requirements in the MOST scalable way?",
        "answer": "C"
      },
      {
        "1": "Set up an S3 event notification that invokes the removePii function when an S3 GET request is made. Call Amazon S3 by using a GET request to access the object without PII.",
        "2": "Set up an S3 event notification that invokes the removePii function when an S3 PUT request is made. Call Amazon S3 by using a PUT request to access the object without PII.",
        "3": "Create an S3 Object Lambda access point from the S3 console. Select the removePii function. Use S3 Access Points to access the object without PII.",
        "4": "Create an S3 access point from the S3 console. Use the access point name to call the GetObjectLegalHold S3 API function. Pass in the removePii function name to access the object without PII.",
        "5": 1,
        "no": "14",
        "question": "A financial company must store original customer records for 10 years for legal reasons. A complete record contains personally identifiable information (PII). According to local regulations, PII is available to only certain people in the company and must not be shared with third parties. The company needs to make the records available to third-party organizations for statistical analysis without sharing the PII. A developer wants to store the original immutable record in Amazon S3. Depending on who accesses the S3 document, the document should be returned as is or with all the PII removed. The developer has written an AWS Lambda function to remove the PII from the document. The function is named removePii. What should the developer do so that the company can meet the PII requirements while maintaining only one copy of the document?",
        "answer": "C"
      },
      {
        "1": "Use AWS OpsWorks to perform blue/green deployments.",
        "2": "Use a function alias with different versions.",
        "3": "Maintain deployment packages for older versions in Amazon S3.",
        "4": "Use AWS CodePipeline for deployments and rollbacks.",
        "5": 1,
        "no": "15",
        "question": "A developer is deploying an AWS Lambda function The developer wants the ability to return to older versions of the function quickly and seamlessly. How can the developer achieve this goal with the LEAST operational overhead?",
        "answer": "B"
      },
      {
        "1": "Increase the function's CPU core count.",
        "2": "Increase the function's memory.",
        "3": "Increase the function's reserved concurrency.",
        "4": "Increase the function's timeout.",
        "5": 1,
        "no": "16",
        "question": "A developer has written an AWS Lambda function. The function is CPU-bound. The developer wants to ensure that the function returns responses quickly. How can the developer improve the function's performance?",
        "answer": "B"
      },
      {
        "1": "BeforeInstall -> ApplicationStop -> ApplicationStart -> AfterInstall",
        "2": "ApplicationStop -> BeforeInstall -> AfterInstall -> ApplicationStart",
        "3": "BeforeInstall -> ApplicationStop -> ValidateService -> ApplicationStart",
        "4": "ApplicationStop -> BeforeInstall -> ValidateService -> ApplicationStart",
        "5": 1,
        "no": "17",
        "question": "For a deployment using AWS Code Deploy, what is the run order of the hooks for in-place deployments?",
        "answer": "B"
      },
      {
        "1": "Write the results of payment processing API calls to Amazon CloudWatch. Use Amazon CloudWatch Logs Insights to query the CloudWatch logs. Schedule the Lambda function to check the CloudWatch logs and notify the existing SNS topic.",
        "2": "Publish custom metrics to CloudWatch that record the failures of the external payment processing API calls. Configure a CloudWatch alarm to notify the existing SNS topic when error rate exceeds the specified rate.",
        "3": "Publish the results of the external payment processing API calls to a new Amazon SNS topic. Subscribe the support team members to the new SNS topic.",
        "4": "Write the results of the external payment processing API calls to Amazon S3. Schedule an Amazon Athena query to run at regular intervals. Configure Athena to send notifications to the existing SNS topic when the error rate exceeds the specified rate.",
        "5": 1,
        "no": "18",
        "question": "A company is building a serverless application on AWS. The application uses an AWS Lambda function to process customer orders 24 hours a day, 7 days a week. The Lambda function calls an external vendor's HTTP API to process payments. During load tests, a developer discovers that the external vendor payment processing API occasionally times out and returns errors. The company expects that some payment processing API calls will return errors. The company wants the support team to receive notifications in near real time only when the payment processing external API error rate exceed 5% of the total number of transactions in an hour. Developers need to use an existing Amazon Simple Notification Service (Amazon SNS) topic that is configured to notify the support team. Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Enable API caching in API Gateway.",
        "2": "Configure API Gateway to use an interface VPC endpoint.",
        "3": "Enable cross-origin resource sharing (CORS) for the APIs.",
        "4": "Configure usage plans and API keys in API Gateway.",
        "5": 1,
        "no": "19",
        "question": "A company is offering APIs as a service over the internet to provide unauthenticated read access to statistical information that is updated daily. The company uses Amazon API Gateway and AWS Lambda to develop the APIs. The service has become popular, and the company wants to enhance the responsiveness of the APIs. Which action can help the company achieve this goal?",
        "answer": "A"
      },
      {
        "1": "Create an Amazon DynamoDB table. Configure the table with a primary key that consists of the title as the partition key and the release year as the sort key. Create a global secondary index that uses the genre as the partition key and the title as the sort key.",
        "2": "Create an Amazon DynamoDB table. Configure the table with a primary key that consists of the genre as the partition key and the release year as the sort key. Create a global secondary index that uses the title as the partition key.",
        "3": "On an Amazon RDS DB instance, create a table that contains columns for title, release year, and genre. Configure the title as the primary key.",
        "4": "On an Amazon RDS DB instance, create a table where the primary key is the title and all other data is encoded into JSON format as one additional column.",
        "5": 1,
        "no": "20",
        "question": "A developer wants to store information about movies. Each movie has a title, release year, and genre. The movie information also can include additional properties about the cast and production crew. This additional information is inconsistent across movies. For example, one movie might have an assistant director, and another movie might have an animal trainer. The developer needs to implement a solution to support the following use cases: \n For a given title and release year, get all details about the movie that has that title and release year. \n For a given title, get all details about all movies that have that title. \n For a given genre, get all details about all movies in that genre. \nWhich data store configuration will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Define a development stage on the API Gateway API. Instruct the other developers to point the endpoints to the development stage.",
        "2": "Define a new API Gateway API that points to the new API application code. Instruct the other developers to point the endpoints to the new API.",
        "3": "Implement a query parameter in the API application code that determines which code version to call.",
        "4": "Specify new API Gateway endpoints for the API endpoints that the developer wants to add.",
        "5": 1,
        "no": "21",
        "question": "A developer maintains an Amazon API Gateway REST API. Customers use the API through a frontend UI and Amazon Cognito authentication. The developer has a new version of the API that contains new endpoints and backward-incompatible interface changes. The developer needs to provide beta access to other developers on the team without affecting customers. Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "A"
      },
      {
        "1": "Create an Amazon ElastiCache for Redis instance. Enable encryption of data in transit and at rest. Store frequently accessed data in the cache.",
        "2": "Create an Amazon ElastiCache for Memcached instance. Enable encryption of data in transit and at rest. Store frequently accessed data in the cache.",
        "3": "Create an Amazon RDS for MySQL read replica. Connect to the read replica by using SSL. Configure the read replica to store frequently accessed data.",
        "4": "Create an Amazon DynamoDB table and a DynamoDB Accelerator (DAX) cluster for the table. Store frequently accessed data in the DynamoDB table.",
        "5": 1,
        "no": "22",
        "question": "A developer is creating an application that will store personal health information (PHI). The PHI needs to be encrypted at all times. An encrypted Amazon RDS for MySQL DB instance is storing the data. The developer wants to increase the performance of the application by caching frequently accessed data while adding the ability to sort or rank the cached datasets. Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Mount an Amazon Elastic Block Store (Amazon EBS) volume onto one of the EC2 instances. Deploy a file system on the EBS volume. Use the host operating system to share a folder. Update the application code to read and write configuration files from the shared folder.",
        "2": "Deploy a micro EC2 instance with an instance store volume. Use the host operating system to share a folder. Update the application code to read and write configuration files from the shared folder.",
        "3": "Create an Amazon S3 bucket to host the repository. Migrate the existing .xml files to the S3 bucket. Update the application code to use the AWS SDK to read and write configuration files from Amazon S3.",
        "4": "Create an Amazon S3 bucket to host the repository. Migrate the existing .xml files to the S3 bucket. Mount the S3 bucket to the EC2 instances as a local volume. Update the application code to read and write configuration files from the disk.",
        "5": 1,
        "no": "23",
        "question": "A company has a multi-node Windows legacy application that runs on premises. The application uses a network shared folder as a centralized configuration repository to store configuration files in .xml format. The company is migrating the application to Amazon EC2 instances. As part of the migration to AWS, a developer must identify a solution that provides high availability for the repository. Which solution will meet this requirement MOST cost-effectively?",
        "answer": "C"
      },
      {
        "1": "Host each website by using AWS Amplify with a serverless backend. Conned the repository branches that correspond to each of the desired environments. Start deployments by merging code changes to a desired branch.",
        "2": "Host each website in AWS Elastic Beanstalk with multiple environments. Use the EB CLI to link each repository branch. Integrate AWS CodePipeline to automate deployments from version control code merges.",
        "3": "Host each website in different Amazon S3 buckets for each environment. Configure AWS CodePipeline to pull source code from version control. Add an AWS CodeBuild stage to copy source code to Amazon S3.",
        "4": "Host each website on its own Amazon EC2 instance. Write a custom deployment script to bundle each website's static assets. Copy the assets to Amazon EC2. Set up a workflow to run the script when code is merged.",
        "5": 1,
        "no": "24",
        "question": "A company wants to deploy and maintain static websites on AWS. Each website's source code is hosted in one of several version control systems, including AWS CodeCommit, Bitbucket, and GitHub. The company wants to implement phased releases by using development, staging, user acceptance testing, and production environments in the AWS Cloud. Deployments to each environment must be started by code merges on the relevant Git branch. The company wants to use HTTPS for all data exchange. The company needs a solution that does not require servers to run continuously. Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "A"
      },
      {
        "1": "Use a multi-AZ Amazon RDS deployment. Increase the number of connections that the code makes to the database or increase the connection pool size if a connection pool is in use.",
        "2": "Use a multi-AZ Amazon RDS deployment. Modify the code so that queries access the secondary RDS instance.",
        "3": "Deploy Amazon RDS with one or more read replicas. Modify the application code so that queries use the URL for the read replicas.",
        "4": "Use open source replication software to create a copy of the MySQL database on an Amazon EC2 instance. Modify the application code so that queries use the IP address of the EC2 instance.",
        "5": 1,
        "no": "25",
        "question": "A company is migrating an on-premises database to Amazon RDS for MySQL. The company has read-heavy workloads. The company wants to refactor the code to achieve optimum read performance for queries. Which solution will meet this requirement with LEAST current and future effort?",
        "answer": "C"
      },
      {
        "1": "Create an Amazon RDS for MySQL DB instance. Store the unique identifier for each request in a database table. Modify the Lambda function to check the table for the identifier before processing the request.",
        "2": "Create an Amazon DynamoDB table. Store the unique identifier for each request in the table. Modify the Lambda function to check the table for the identifier before processing the request.",
        "3": "Create an Amazon DynamoDB table. Store the unique identifier for each request in the table. Modify the Lambda function to return a client error response when the function receives a duplicate request.",
        "4": "Create an Amazon ElastiCache for Memcached instance. Store the unique identifier for each request in the cache. Modify the Lambda function to check the cache for the identifier before processing the request.",
        "5": 1,
        "no": "26",
        "question": "A developer is creating an application that will be deployed on IoT devices. The application will send data to a RESTful API that is deployed as an AWS Lambda function. The application will assign each API request a unique identifier. The volume of API requests from the application can randomly increase at any given time of day. During periods of request throttling, the application might need to retry requests. The API must be able to handle duplicate requests without inconsistencies or data loss. Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Create new AMIs, and specify encryption parameters. Copy the encrypted AMIs to the destination Region. Delete the unencrypted AMIs.",
        "2": "Use AWS Key Management Service (AWS KMS) to enable encryption on the unencrypted AMIs. Copy the encrypted AMIs to the destination Region.",
        "3": "Use AWS Certificate Manager (ACM) to enable encryption on the unencrypted AMIs. Copy the encrypted AMIs to the destination Region.",
        "4": "Copy the unencrypted AMIs to the destination Region. Enable encryption by default in the destination Region.",
        "5": 1,
        "no": "27",
        "question": "A developer wants to expand an application to run in multiple AWS Regions. The developer wants to copy Amazon Machine Images (AMIs) with the latest changes and create a new application stack in the destination Region. According to company requirements, all AMIs must be encrypted in all Regions. However, not all the AMIs that the company uses are encrypted. How can the developer expand the application to run in the destination Region while meeting the encryption requirement?",
        "answer": "A"
      },
      {
        "1": "Create four access points that allow access to the central S3 bucket. Assign an access point to each web application bucket.",
        "2": "Create a bucket policy that allows access to the central S3 bucket. Attach the bucket policy to the central S3 bucket",
        "3": "Create a cross-origin resource sharing (CORS) configuration that allows access to the central S3 bucket. Add the CORS configuration to the central S3 bucket.",
        "4": "Create a Content-MD5 header that provides a message integrity check for the central S3 bucket. Insert the Content-MD5 header for each web application request.",
        "5": 1,
        "no": "28",
        "question": "A company hosts a client-side web application for one of its subsidiaries on Amazon S3. The web application can be accessed through Amazon CloudFront from https://www.example.com. After a successful rollout, the company wants to host three more client-side web applications for its remaining subsidiaries on three separate S3 buckets. To achieve this goal, a developer moves all the common JavaScript files and web fonts to a central S3 bucket that serves the web applications. However, during testing, the developer notices that the browser blocks the JavaScript files and web fonts. What should the developer do to prevent the browser from blocking the JavaScript files and web fonts?",
        "answer": "C"
      },
      {
        "1": "Implement retries with exponential backoff.",
        "2": "Use a PutRecord API instead of PutRecords.",
        "3": "Reduce the frequency and/or size of the requests.",
        "4": "Use Amazon SNS instead of Kinesis.",
        "5": "Reduce the number of KCL consumers.",
        "no": "29",
        "question": "An application is processing clickstream data using Amazon Kinesis. The clickstream data feed into Kinesis experiences periodic spikes. The PutRecords API call occasionally fails and the logs show that the failed call returns the response shown below: Which techniques will help mitigate this exception? (Choose two.)",
        "answer": "AC"
      },

      {
        "1": "Create an AWS Lambda function that uses Amazon Simple Email Service (Amazon SES) to send the email notification. Add an Amazon API Gateway API to invoke the function. Call the API from the client side when login confirmation is received.",
        "2": "Create an AWS Lambda function that uses Amazon Simple Email Service (Amazon SES) to send the email notification. Add an Amazon Cognito post authentication Lambda trigger for the function.",
        "3": "Create an AWS Lambda function that uses Amazon Simple Email Service (Amazon SES) to send the email notification. Create an Amazon CloudWatch Logs log subscription filter to invoke the function based on the login status.",
        "4": "Configure Amazon Cognito to stream all logs to Amazon Kinesis Data Firehose. Create an AWS Lambda function to process the streamed logs and to send the email notification based on the login status of each user.",
        "5": 1,
        "no": "30",
        "question": "A company has an application that uses Amazon Cognito user pools as an identity provider. The company must secure access to user records. The company has set up multi-factor authentication (MFA). The company also wants to send a login activity notification by email every time a user logs in. What is the MOST operationally efficient solution that meets this requirement?",
        "answer": "B"
      },

      {
        "1": "Create an AWS Key Management Service (AWS KMS) key. Assign the KMS key to the S3 bucket.",
        "2": "Set the x-amz-server-side-encryption header when invoking the PutObject API operation.",
        "3": "Provide the encryption key in the HTTP header of every request.",
        "4": "Apply TLS to encrypt the traffic to the S3 bucket.",
        "5": 1,
        "no": "31",
        "question": "A developer has an application that stores data in an Amazon S3 bucket. The application uses an HTTP API to store and retrieve objects. When the PutObject API operation adds objects to the S3 bucket the developer must encrypt these objects at rest by using server-side encryption with Amazon S3 managed keys (SSE-S3). Which solution will meet this requirement?",
        "answer": "B"
      },

      {
        "1": "Create and deploy an AWS Lambda function in each desired Region. Configure the Lambda function to create a stack from an AWS CloudFormation template in that Region when the function is invoked.",
        "2": "Create an AWS CloudFormation template that defines the load test resources. Use the AWS CLI create-stack-set command to create a stack set in the desired Regions.",
        "3": "Create an AWS Systems Manager document that defines the resources. Use the document to create the resources in the desired Regions.",
        "4": "Create an AWS CloudFormation template that defines the load test resources. Use the AWS CLI deploy command to create a stack from the template in each Region.",
        "5": 1,
        "no": "32",
        "question": "A developer needs to perform geographic load testing of an API. The developer must deploy resources to multiple AWS Regions to support the load testing of the API. How can the developer meet these requirements without additional application code?",
        "answer": "B"
      },

      {
        "1": "Import the SSL/TLS certificate into AWS Certificate Manager (ACM) in the same Region as the API. Create a DNS A record for the custom domain.",
        "2": "Import the SSL/TLS certificate into CloudFront. Create a DNS CNAME record for the custom domain.",
        "3": "Import the SSL/TLS certificate into AWS Certificate Manager (ACM) in the same Region as the API. Create a DNS CNAME record for the custom domain.",
        "4": "Import the SSL/TLS certificate into AWS Certificate Manager (ACM) in the us-east-1 Region. Create a DNS CNAME record for the custom domain.",
        "5": 1,
        "no": "33",
        "question": "A developer is creating an application that includes an Amazon API Gateway REST API in the us-east-2 Region. The developer wants to use Amazon CloudFront and a custom domain name for the API. The developer has acquired an SSL/TLS certificate for the domain from a third-party provider. How should the developer configure the custom domain for the application?",
        "answer": "D"
      },

      {
        "1": "CloudFormation serverless intrinsic functions",
        "2": "AWS Elastic Beanstalk",
        "3": "AWS Serverless Application Model (AWS SAM)",
        "4": "AWS Cloud Development Kit (AWS CDK)",
        "5": 1,
        "no": "34",
        "question": "A developer is creating a template that uses AWS CloudFormation to deploy an application. The application is serverless and uses Amazon API Gateway, Amazon DynamoDB, and AWS Lambda. Which AWS service or tool should the developer use to define serverless resources in YAML?",
        "answer": "C"
      },
      {
        "1": "Create an event with Amazon EventBridge that will monitor the S3 bucket and then insert the records into DynamoDB.",
        "2": "Configure an S3 event to invoke an AWS Lambda function that inserts records into DynamoDB.",
        "3": "Create an AWS Lambda function that will poll the S3 bucket and then insert the records into DynamoDB.",
        "4": "Create a cron job that will run at a scheduled time and insert the records into DynamoDB.",
        "5": 1,
        "no": "35",
        "question": "A developer wants to insert a record into an Amazon DynamoDB table as soon as a new file is added to an Amazon S3 bucket. Which set of steps would be necessary to achieve this?",
        "answer": "B"
      },
      {
        "1": "Add a CloudFormation Deletion Policy attribute with the Retain value to the database resource.",
        "2": "Update the CloudFormation stack policy to prevent updates to the database.",
        "3": "Modify the database to use a Multi-AZ deployment.",
        "4": "Create a CloudFormation stack set for the web application and database deployments.",
        "5": "Add a Cloud Formation DeletionPolicy attribute with the Retain value to the stack.",
        "no": "36",
        "question": "A development team maintains a web application by using a single AWS CloudFormation template. The template defines web servers and an Amazon RDS database. The team uses the Cloud Formation template to deploy the Cloud Formation stack to different environments. During a recent application deployment, a developer caused the primary development database to be dropped and recreated. The result of this incident was a loss of data. The team needs to avoid accidental database deletion in the future. Which solutions will meet these requirements? (Choose two)",
        "answer": "AB"
      },
      {
        "1": "Define a resource-based policy on the S3 bucket to deny access when a request meets the condition “aws:SecureTransport”: “false”.",
        "2": "Define a resource-based policy on the S3 bucket to allow access when a request meets the condition “aws:SecureTransport”: “false”.",
        "3": "Define a role-based policy on the other accounts' roles to deny access when a request meets the condition of “aws:SecureTransport”: “false”.",
        "4": "Define a resource-based policy on the KMS key to deny access when a request meets the condition of “aws:SecureTransport”: “false”.",
        "5": 1,
        "no": "37",
        "question": "A company has an Amazon S3 bucket that contains sensitive data. The data must be encrypted in transit and at rest. The company encrypts the data in the S3 bucket by using an AWS Key Management Service (AWS KMS) key. A developer needs to grant several other AWS accounts the permission to use the S3 GetObject operation to retrieve the data from the S3 bucket. How can the developer enforce that all requests to retrieve the data provide encryption in transit?",
        "answer": "A"
      },
      {
        "1": "Update the IAM instance profile that is attached to the EC2 instance to include the S3:* permission for the S3 bucket.",
        "2": "Update the IAM instance profile that is attached to the EC2 instance to include the S3:ListBucket permission for the S3 bucket.",
        "3": "Update the developer's user permissions to include the S3:ListBucket permission for the S3 bucket.",
        "4": "Update the S3 bucket policy by including the S3:ListBucket permission and by setting the Principal element to specify the account number of the EC2 instance.",
        "5": 1,
        "no": "38",
        "question": "An application that is hosted on an Amazon EC2 instance needs access to files that are stored in an Amazon S3 bucket. The application lists the objects that are stored in the S3 bucket and displays a table to the user. During testing, a developer discovers that the application does not show any objects in the list. What is the MOST secure way to resolve this issue?",
        "answer": "B"
      },
      {
        "1": "Amazon S3 with encrypted files prefixed with “config”",
        "2": "AWS Secrets Manager secrets with a tag that is named SecretString",
        "3": "AWS Systems Manager Parameter Store SecureString parameters",
        "4": "CloudFormation NoEcho parameters",
        "5": 1,
        "no": "39",
        "question": "A company is planning to securely manage one-time fixed license keys in AWS. The company's development team needs to access the license keys in automaton scripts that run in Amazon EC2 instances and in AWS CloudFormation stacks. Which solution will meet these requirements MOST cost-effectively?",
        "answer": "C"
      },
      {
        "1": "Create the Lambda function. Configure VPC1 access for the function. Attach a security group named SG1 to both the Lambda function and the database. Configure the security group inbound and outbound rules to allow TCP traffic on Port 3306.",
        "2": "Create and launch a Lambda function in a new public subnet that is in a new VPC named VPC2. Create a peering connection between VPC1 and VPC2.",
        "3": "Create the Lambda function. Configure VPC1 access for the function. Assign a security group named SG1 to the Lambda function. Assign a second security group named SG2 to the database. Add an inbound rule to SG1 to allow TCP traffic from Port 3306.",
        "4": "Export the data from the Aurora database to Amazon S3. Create and launch a Lambda function in VPC1. Configure the Lambda function query the data from Amazon S3.",
        "5": 1,
        "no": "40",
        "question": "A company has deployed infrastructure on AWS. A development team wants to create an AWS Lambda function that will retrieve data from an Amazon Aurora database. The Amazon Aurora database is in a private subnet in company's VPC. The VPC is named VPC1. The data is relational in nature. The Lambda function needs to access the data securely. Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "CacheHitCount",
        "2": "IntegrationLatency",
        "3": "CacheMissCount",
        "4": "Latency",
        "5": "Count",
        "no": "41",
        "question": "A developer is building a web application that uses Amazon API Gateway to expose an AWS Lambda function to process requests from clients. During testing, the developer notices that the API Gateway times out even though the Lambda function finishes under the set time limit. Which of the following API Gateway metrics in Amazon CloudWatch can help the developer troubleshoot the issue? (Choose two.)",
        "answer": "BD"
      },

      {
        "1": "AWS CodeDeploy",
        "2": "AWS CodeArtifact",
        "3": "AWS CodeCommit",
        "4": "Amazon CodeGuru",
        "5": 1,
        "no": "42",
        "question": "A development team wants to build a continuous integration/continuous delivery (CI/CD) pipeline. The team is using AWS CodePipeline to automate the code build and deployment. The team wants to store the program code to prepare for the CI/CD pipeline. Which AWS service should the team use to store the program code?",
        "answer": "C"
      },
      {
        "1": "the /tmp directory",
        "2": "Amazon Elastic File System (Amazon EFS)",
        "3": "Amazon Elastic Block Store (Amazon EBS)",
        "4": "Amazon S3",
        "5": 1,
        "no": "43",
        "question": "A developer is designing an AWS Lambda function that creates temporary files that are less than 10 MB during invocation. The temporary files will be accessed and modified multiple times during invocation. The developer has no need to save or retrieve these files in the future. Where should the temporary files be stored?",
        "answer": "A"
      },
      {
        "1": "Package each Python library in its own .zip file archive. Deploy each Lambda function with its own copy of the library.",
        "2": "Create a Lambda layer with the required Python library. Use the Lambda layer in both Lambda functions.",
        "3": "Combine the two Lambda functions into one Lambda function. Deploy the Lambda function as a single .zip file archive.",
        "4": "Download the Python library to an S3 bucket. Program the Lambda functions to reference the object URLs.",
        "5": 1,
        "no": "44",
        "question": "A developer is designing a serverless application with two AWS Lambda functions to process photos. One Lambda function stores objects in an Amazon S3 bucket and stores the associated metadata in an Amazon DynamoDB table. The other Lambda function fetches the objects from the S3 bucket by using the metadata from the DynamoDB table. Both Lambda functions use the same Python library to perform complex computations and are approaching the quota for the maximum size of zipped deployment packages. What should the developer do to reduce the size of the Lambda deployment packages with the LEAST operational overhead?",
        "answer": "B"
      },
      {
        "1": "Obtain the request identifier from the AWS request ID field in the context object. Configure the application to write logs to standard output.",
        "2": "Obtain the request identifier from the AWS request ID field in the event object. Configure the application to write logs to a file.",
        "3": "Obtain the request identifier from the AWS request ID field in the event object. Configure the application to write logs to standard output.",
        "4": "Obtain the request identifier from the AWS request ID field in the context object. Configure the application to write logs to a file.",
        "5": 1,
        "no": "45",
        "question": "A developer is writing an AWS Lambda function. The developer wants to log key events that occur while the Lambda function runs. The developer wants to include a unique identifier to associate the events with a specific function invocation. The developer adds the following code to the Lambda function: Which solution will meet this requirement?",
        "answer": "A"
      },
      {
        "1": "Create an Amazon Kinesis data stream, and attach it to the DynamoDB table. Create a trigger to connect the data stream to the Lambda function.",
        "2": "Create an Amazon EventBridge rule to invoke the Lambda function on a regular schedule. Conned to the DynamoDB table from the Lambda function to detect changes.",
        "3": "Enable DynamoDB Streams on the table. Create a trigger to connect the DynamoDB stream to the Lambda function.",
        "4": "Create an Amazon Kinesis Data Firehose delivery stream, and attach it to the DynamoDB table. Configure the delivery stream destination as the Lambda function.",
        "5": 1,
        "no": "46",
        "question": "A developer is working on a serverless application that needs to process any changes to an Amazon DynamoDB table with an AWS Lambda function. How should the developer configure the Lambda function to detect changes to the DynamoDB table?",
        "answer": "C"
      },
      {
        "1": "Use EC2 Image Builder to create an Amazon Machine Image (AMI). Install all the patches and agents that are needed to manage and run the application. Update the Auto Scaling group launch configuration to use the AMI.",
        "2": "Use EC2 Image Builder to create an Amazon Machine Image (AMI). Install the latest version of the application and all the patches and agents that are needed to manage and run the application. Update the Auto Scaling group launch configuration to use the AMI.",
        "3": "Set up AWS CodeDeploy to deploy the most recent version of the application at runtime.",
        "4": "Set up AWS CodePipeline to deploy the most recent version of the application at runtime.",
        "5": "Remove any commands that perform operating system patching from the UserData script.",
        "no": "47",
        "question": "An application uses an Amazon EC2 Auto Scaling group. A developer notices that EC2 instances are taking a long time to become available during scale-out events. The UserData script is taking a long time to run. The developer must implement a solution to decrease the time that elapses before an EC2 instance becomes available. The solution must make the most recent version of the application available at all times and must apply all available security updates. The solution also must minimize the number of images that are created. The images must be validated. Which combination of steps should the developer take to meet these requirements? (Choose two.)",
        "answer": "AC"
      },
      {
        "1": "Store the credentials in AWS Systems Manager Parameter Store. Select the database that the parameter will access. Use the default AWS Key Management Service (AWS KMS) key to encrypt the parameter. Enable automatic rotation for the parameter. Use the parameter from Parameter Store on the Lambda function to connect to the database.",
        "2": "Encrypt the credentials with the default AWS Key Management Service (AWS KMS) key. Store the credentials as environment variables for the Lambda function. Create a second Lambda function to generate new credentials and to rotate the credentials by updating the environment variables of the first Lambda function. Invoke the second Lambda function by using an Amazon EventBridge rule that runs on a schedule. Update the database to use the new credentials. On the first Lambda function, retrieve the credentials from the environment variables. Decrypt the credentials by using AWS KMS, Connect to the database.",
        "3": "Store the credentials in AWS Secrets Manager. Set the secret type to Credentials for Amazon RDS database. Select the database that the secret will access. Use the default AWS Key Management Service (AWS KMS) key to encrypt the secret. Enable automatic rotation for the secret. Use the secret from Secrets Manager on the Lambda function to connect to the database.",
        "4": "Encrypt the credentials by using AWS Key Management Service (AWS KMS). Store the credentials in an Amazon DynamoDB table. Create a second Lambda function to rotate the credentials. Invoke the second Lambda function by using an Amazon EventBridge rule that runs on a schedule. Update the DynamoDB table. Update the database to use the generated credentials. Retrieve the credentials from DynamoDB with the first Lambda function. Connect to the database.",
        "5": 1,
        "no": "48",
        "question": "A developer is creating an AWS Lambda function that needs credentials to connect to an Amazon RDS for MySQL database. An Amazon S3 bucket currently stores the credentials. The developer needs to improve the existing solution by implementing credential rotation and secure storage. The developer also needs to provide integration with the Lambda function. Which solution should the developer use to store and retrieve the credentials with the LEAST management overhead?",
        "answer": "C"
      },

      {
        "1": "Access on all buckets except the “DOC-EXAMPLE-BUCKET” bucket",
        "2": "Access on all buckets that start with “DOC-EXAMPLE-BUCKET” except the “DOC-EXAMPLE-BUCKET/secrets” bucket",
        "3": "Access on all objects in the “DOC-EXAMPLE-BUCKET” bucket along with access to all S3 actions for objects in the “DOC-EXAMPLE-BUCKET” bucket that start with “secrets”",
        "4": "Access on all objects in the “DOC-EXAMPLE-BUCKET” bucket except on objects that start with “secrets”",
        "5": 1,
        "no": "49",
        "question": "A developer has written the following IAM policy to provide access to an Amazon S3 bucket: Which access does the policy allow regarding the s3:GetObject and s3:PutObject actions?",
        "answer": "D"
      },

      {
        "1": "Create an AWS Lambda function. Use API Gateway proxy integration to return constant HTTP responses.",
        "2": "Create an Amazon EC2 instance that serves the backend REST API by using an AWS CloudFormation template.",
        "3": "Customize the API Gateway stage to select a response type based on the request.",
        "4": "Use a request mapping template to select the mock integration response.",
        "5": 1,
        "no": "50",
        "question": "A developer is creating a mobile app that calls a backend service by using an Amazon API Gateway REST API. For integration testing during the development phase, the developer wants to simulate different backend responses without invoking the backend service. Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "D"
      },
      {
        "1": "Install an AWS SDK on the on-premises server to automatically send logs to CloudWatch.",
        "2": "Download the CloudWatch agent to the on-premises server. Configure the agent to use IAM user credentials with permissions for CloudWatch.",
        "3": "Upload log files from the on-premises server to Amazon S3 and have CloudWatch read the files.",
        "4": "Upload log files from the on-premises server to an Amazon EC2 instance and have the instance forward the logs to CloudWatch.",
        "5": 1,
        "no": "51",
        "question": "A developer has a legacy application that is hosted on-premises. Other applications hosted on AWS depend on the on-premises application for proper functioning. In case of any application errors, the developer wants to be able to use Amazon CloudWatch to monitor and troubleshoot all applications from one place. How can the developer accomplish this?",
        "answer": "B"
      },
      {
        "1": "Implement Kinesis Data Firehose data transformation as an AWS Lambda function. Configure the function to remove the customer identifiers. Set an Amazon S3 bucket as the destination of the delivery stream.",
        "2": "Launch an Amazon EC2 instance. Set the EC2 instance as the destination of the delivery stream. Run an application on the EC2 instance to remove the customer identifiers. Store the transformed data in an Amazon S3 bucket.",
        "3": "Create an Amazon OpenSearch Service instance. Set the OpenSearch Service instance as the destination of the delivery stream. Use search and replace to remove the customer identifiers. Export the data to an Amazon S3 bucket.",
        "4": "Create an AWS Step Functions workflow to remove the customer identifiers. As the last step in the workflow, store the transformed data in an Amazon S3 bucket. Set the workflow as the destination of the delivery stream.",
        "5": 1,
        "no": "52",
        "question": "An Amazon Kinesis Data Firehose delivery stream is receiving customer data that contains personally identifiable information. A developer needs to remove pattern-based customer identifiers from the data and store the modified data in an Amazon S3 bucket. What should the developer do to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Set the image resize Lambda function as a destination of the avatar generator Lambda function for the events that fail processing.",
        "2": "Create an Amazon Simple Queue Service (Amazon SQS) queue. Set the SQS queue as a destination with an on failure condition for the avatar generator Lambda function. Configure the image resize Lambda function to poll from the SQS queue.",
        "3": "Create an AWS Step Functions state machine that invokes the avatar generator Lambda function and uses the image resize Lambda function as a fallback. Create an Amazon EventBridge rule that matches events from the S3 bucket to invoke the state machine.",
        "4": "Create an Amazon Simple Notification Service (Amazon SNS) topic. Set the SNS topic as a destination with an on failure condition for the avatar generator Lambda function. Subscribe the image resize Lambda function to the SNS topic.",
        "5": 1,
        "no": "53",
        "question": "A developer is using an AWS Lambda function to generate avatars for profile pictures that are uploaded to an Amazon S3 bucket. The Lambda function is automatically invoked for profile pictures that are saved under the /original/ S3 prefix. The developer notices that some pictures cause the Lambda function to time out. The developer wants to implement a fallback mechanism by using another Lambda function that resizes the profile picture. Which solution will meet these requirements with the LEAST development effort?",
        "answer": "A"
      },
      {
        "1": "Use an EC2 instance to host the MySQL database. Store the session data and the application data in the MySQL database.",
        "2": "Use Amazon ElastiCache for Memcached to store and manage the session data. Use an Amazon RDS for MySQL DB instance to store the application data.",
        "3": "Use Amazon ElastiCache for Memcached to store and manage the session data and the application data.",
        "4": "Use the EC2 instance store to manage the session data. Use an Amazon RDS for MySQL DB instance to store the application data.",
        "5": 1,
        "no": "54",
        "question": "A developer needs to migrate an online retail application to AWS to handle an anticipated increase in traffic. The application currently runs on two servers: one server for the web application and another server for the database. The web server renders webpages and manages session state in memory. The database server hosts a MySQL database that contains order details. When traffic to the application is heavy, the memory usage for the web server approaches 100% and the application slows down considerably. The developer has found that most of the memory increase and performance decrease is related to the load of managing additional user sessions. For the web server migration, the developer will use Amazon EC2 instances with an Auto Scaling group behind an Application Load Balancer. Which additional set of changes should the developer make to the application to improve the application's performance?",
        "answer": "B"
      },

      {
        "1": "Amazon S3",
        "2": "AWS CloudTrail",
        "3": "Amazon CloudWatch",
        "4": "Amazon DynamoDB",
        "5": 1,
        "no": "55",
        "question": "An application uses Lambda functions to extract metadata from files uploaded to an S3 bucket; the metadata is stored in Amazon DynamoDB. The application starts behaving unexpectedly, and the developer wants to examine the logs of the Lambda function code for errors. Based on this system configuration, where would the developer find the logs?",
        "answer": "C"
      },

      {
        "1": "Increase the number of shards of the Kinesis data stream.",
        "2": "Decrease the timeout of the Lambda function.",
        "3": "Increase the memory that is allocated to the Lambda function.",
        "4": "Decrease the number of shards of the Kinesis data stream.",
        "5": "Increase the timeout of the Lambda function.",
        "no": "56",
        "question": "A company is using an AWS Lambda function to process records from an Amazon Kinesis data stream. The company recently observed slow processing of the records. A developer notices that the iterator age metric for the function is increasing and that the Lambda run duration is constantly above normal. Which actions should the developer take to increase the processing speed? (Choose two.)",
        "answer": "AC"
      },

      {
        "1": "Build the container image and run the docker scan command locally. Mitigate any findings before pushing changes to the source code repository. Write a pre-commit hook that enforces the use of this workflow before commit.",
        "2": "Create a new CodePipeline stage that occurs after the container image is built. Configure ECR basic image scanning to scan on image push. Use an AWS Lambda function as the action provider. Configure the Lambda function to check the scan results and to fail the pipeline if there are findings.",
        "3": "Create a new CodePipeline stage that occurs after source code has been retrieved from its repository. Run a security scanner on the latest revision of the source code. Fail the pipeline if there are findings.",
        "4": "Add an action to the deployment stage of the pipeline so that the action occurs before the deployment to the EKS cluster. Configure ECR basic image scanning to scan on image push. Use an AWS Lambda function as the action provider. Configure the Lambda function to check the scan results and to fail the pipeline if there are findings.",
        "5":1,
        "no": "57",
        "question": "A company needs to harden its container images before the images are in a running state. The company's application uses Amazon Elastic Container Registry (Amazon ECR) as an image registry. Amazon Elastic Kubernetes Service (Amazon EKS) for compute, and an AWS CodePipeline pipeline that orchestrates a continuous integration and continuous delivery (CI/CD) workflow. Dynamic application security testing occurs in the final stage of the pipeline after a new image is deployed to a development namespace in the EKS cluster. A developer needs to place an analysis stage before this deployment to analyze the container image earlier in the CI/CD pipeline. Which solution will meet these requirements with the MOST operational efficiency?",
        "answer": "B"
      },

      {
        "1": "Add a second cache behavior to the distribution with the same origin as the default cache behavior. Set the path pattern for the second cache behavior to the path of the login page, and make viewer access unrestricted. Keep the default cache behavior's settings unchanged.",
        "2": "Add a second cache behavior to the distribution with the same origin as the default cache behavior. Set the path pattern for the second cache behavior to *, and make viewer access restricted. Change the default cache behavior's path pattern to the path of the login page, and make viewer access unrestricted.",
        "3": "Add a second origin as a failover origin to the default cache behavior. Point the failover origin to the S3 bucket. Set the path pattern for the primary origin to *, and make viewer access restricted. Set the path pattern for the failover origin to the path of the login page, and make viewer access unrestricted.",
        "4": "Add a bucket policy to the S3 bucket to allow read access. Set the resource on the policy to the Amazon Resource Name (ARN) of the login page object in the S3 bucket. Add a CloudFront function to the default cache behavior to redirect unauthorized requests to the login page's S3 URL.",
        "5":1,
        "no": "58",
        "question": "A developer is testing a new file storage application that uses an Amazon CloudFront distribution to serve content from an Amazon S3 bucket. The distribution accesses the S3 bucket by using an origin access identity (OAI). The S3 bucket's permissions explicitly deny access to all other users. The application prompts users to authenticate on a login page and then uses signed cookies to allow users to access their personal storage directories. The developer has configured the distribution to use its default cache behavior with restricted viewer access and has set the origin to point to the S3 bucket. However, when the developer tries to navigate to the login page, the developer receives a 403 Forbidden error. The developer needs to implement a solution to allow unauthenticated access to the login page. The solution also must keep all private content secure. Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Run the amplify add test command in the Amplify CLI.",
        "2": "Create unit tests in the application. Deploy the unit tests by using the amplify push command in the Amplify CLI.",
        "3": "Add a test phase to the amplify.yml build settings for the application.",
        "4": "Add a test phase to the aws-exports.js file for the application.",
        "5":1,
        "no": "59",
        "question": "A developer is using AWS Amplify Hosting to build and deploy an application. The developer is receiving an increased number of bug reports from users. The developer wants to add end-to-end testing to the application to eliminate as many bugs as possible before the bugs reach production. Which solution should the developer implement to meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Inspect the frontend logs for API failures. Call the POST API manually by using the requests from the log file.",
        "2": "Create and inspect the Lambda dead-letter queue. Troubleshoot the failed functions. Reprocess the events.",
        "3": "Inspect the Lambda logs in Amazon CloudWatch for possible errors. Fix the errors.",
        "4": "Make sure that caching is disabled for the POST API in API Gateway.",
        "5":1,
        "no": "60",
        "question": "An ecommerce company is using an AWS Lambda function behind Amazon API Gateway as its application tier. To process orders during checkout, the application calls a POST API from the frontend. The POST API invokes the Lambda function asynchronously. In rare situations, the application has not processed orders. The Lambda application logs show no errors or failures. What should a developer do to solve this problem?",
        "answer": "B"
      },
      {
        "1": "Generate the reports and then store the reports as Amazon DynamoDB items that have a specified TTL. Generate a URL that retrieves the reports from DynamoDB. Provide the URL to customers through the web application.",
        "2": "Generate the reports and then store the reports in an Amazon S3 bucket that uses server-side encryption. Attach the reports to an Amazon Simple Notification Service (Amazon SNS) message. Subscribe the customer to email notifications from Amazon SNS.",
        "3": "Generate the reports and then store the reports in an Amazon S3 bucket that uses server-side encryption. Generate a presigned URL that contains an expiration date Provide the URL to customers through the web application. Add S3 Lifecycle configuration rules to the S3 bucket to delete old reports.",
        "4": "Generate the reports and then store the reports in an Amazon RDS database with a date stamp. Generate an URL that retrieves the reports from the RDS database. Provide the URL to customers through the web application. Schedule an hourly AWS Lambda function to delete database records that have expired date stamps.",
        "5":1,
        "no": "61",
        "question": "A company is building a web application on AWS. When a customer sends a request, the application will generate reports and then make the reports available to the customer within one hour. Reports should be accessible to the customer for 8 hours. Some reports are larger than 1 MB. Each report is unique to the customer. The application should delete all reports that are older than 2 days. Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "C"
      },
      {
        "1": "Change the Auto Scaling group to six desired instances.",
        "2": "Change the deployment policy to traffic splitting. Specify an evaluation time of 1 hour.",
        "3": "Change the deployment policy to rolling with additional batch. Specify a batch size of 1.",
        "4": "Change the deployment policy to rolling. Specify a batch size of 2.",
        "5":1,
        "no": "62",
        "question": "A company has deployed an application on AWS Elastic Beanstalk. The company has configured the Auto Scaling group that is associated with the Elastic Beanstalk environment to have five Amazon EC2 instances. If the capacity is fewer than four EC2 instances during the deployment, application performance degrades. The company is using the all-at-once deployment policy. What is the MOST cost-effective way to solve the deployment issue?",
        "answer": "C"
      },
      {
        "1": "Manually instrument the X-Ray SDK in the application code.",
        "2": "Use the X-Ray auto-instrumentation agent.",
        "3": "Use Amazon Macie to detect and hide PII. Call the X-Ray API from AWS Lambda.",
        "4": "Use AWS Distro for Open Telemetry.",
        "5":1,
        "no": "63",
        "question": "A developer is incorporating AWS X-Ray into an application that handles personal identifiable information (PII). The application is hosted on Amazon EC2 instances. The application trace messages include encrypted PII and go to Amazon CloudWatch. The developer needs to ensure that no PII goes outside of the EC2 instances. Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Configure the DB cluster's public access setting to Yes.",
        "2": "Configure an Amazon RDS database proxy for he Lambda functions.",
        "3": "Configure a NAT gateway and a security group for the Lambda functions.",
        "4": "Configure the VPC, subnets, and a security group for the Lambda functions.",
        "5":1,
        "no": "64",
        "question": "A developer is migrating some features from a legacy monolithic application to use AWS Lambda functions instead. The application currently stores data in an Amazon Aurora DB cluster that runs in private subnets in a VPC. The AWS account has one VPC deployed. The Lambda functions and the DB cluster are deployed in the same AWS Region in the same AWS account. The developer needs to ensure that the Lambda functions can securely access the DB cluster without crossing the public internet. Which solution will meet these requirements?",
        "answer": "D"
      },

      {
        "no": "65",
        "question": "A developer is building a new application on AWS. The application uses an AWS Lambda function that retrieves information from an Amazon DynamoDB table. The developer hard coded the DynamoDB table name into the Lambda function code. The table name might change over time. The developer does not want to modify the Lambda code if the table name changes. Which solution will meet these requirements MOST efficiently?",
        "1": "Create a Lambda environment variable to store the table name. Use the standard method for the programming language to retrieve the variable.",
        "2": "Store the table name in a file. Store the file in the /tmp folder. Use the SDK for the programming language to retrieve the table name.",
        "3": "Create a file to store the table name. Zip the file and upload the file to the Lambda layer. Use the SDK for the programming language to retrieve the table name.",
        "4": "Create a global variable that is outside the handler in the Lambda function to store the table name.",
        "5":1,
        "answer": "A"
      },
      {
        "no": "66",
        "question": "A company has a critical application on AWS. The application exposes an HTTP API by using Amazon API Gateway. The API is integrated with an AWS Lambda function. The application stores data in an Amazon RDS for MySQL DB instance with 2 virtual CPUs (vCPUs) and 64 GB of RAM. Customers have reported that some of the API calls return HTTP 500 Internal Server Error responses. Amazon CloudWatch Logs shows errors for “too many connections.” The errors occur during peak usage times that are unpredictable. The company needs to make the application resilient. The database cannot be down outside of scheduled maintenance hours. Which solution will meet these requirements?",
        "1": "Decrease the number of vCPUs for the DB instance. Increase the max_connections setting.",
        "2": "Use Amazon RDS Proxy to create a proxy that connects to the DB instance. Update the Lambda function to connect to the proxy.",
        "3": "Add a CloudWatch alarm that changes the DB instance class when the number of connections increases to more than 1,000.",
        "4": "Add an Amazon EventBridge rule that increases the max_connections setting of the DB instance when CPU utilization is above 75%.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "67",
        "question": "A company has installed smart meters in all its customer locations. The smart meters measure power usage at 1-minute intervals and send the usage readings to a remote endpoint for collection. The company needs to create an endpoint that will receive the smart meter readings and store the readings in a database. The company wants to store the location ID and timestamp information. The company wants to give its customers low-latency access to their current usage and historical usage on demand. The company expects demand to increase significantly. The solution must not impact performance or include downtime while scaling. Which solution will meet these requirements MOST cost-effectively?",
        "1": "Store the smart meter readings in an Amazon RDS database. Create an index on the location ID and timestamp columns. Use the columns to filter on the customers' data.",
        "2": "Store the smart meter readings in an Amazon DynamoDB table. Create a composite key by using the location ID and timestamp columns. Use the columns to filter on the customers' data.",
        "3": "Store the smart meter readings in Amazon ElastiCache for Redis. Create a SortedSet key by using the location ID and timestamp columns. Use the columns to filter on the customers' data.",
        "4": "Store the smart meter readings in Amazon S3. Partition the data by using the location ID and timestamp columns. Use Amazon Athena to filter on the customers' data.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "68",
        "question": "A company is building a serverless application that uses AWS Lambda functions. The company needs to create a set of test events to test Lambda functions in a development environment. The test events will be created once and then will be used by all the developers in an IAM developer group. The test events must be editable by any of the IAM users in the IAM developer group. Which solution will meet these requirements?",
        "1": "Create and store the test events in Amazon S3 as JSON objects. Allow S3 bucket access to all IAM users.",
        "2": "Create the test events. Configure the event sharing settings to make the test events shareable",
        "3": "Create and store the test events in Amazon DynamoDB. Allow access to DynamoDB by using IAM roles.",
        "4": "Create the test events. Configure the event sharing settings to make the test events private.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "69",
        "question": "A developer is configuring an application's deployment environment in AWS CodePipeline. The application code is stored in a GitHub repository. The developer wants to ensure that the repository package's unit tests run in the new deployment environment. The developer has already set the pipeline's source provider to GitHub and has specified the repository and branch to use in the deployment. \n\nWhich combination of steps should the developer take next to meet these requirements with the LEAST overhead? (Choose two.)",
        "1": "Create an AWS CodeCommit project. Add the repository package's build and test commands to the project's buildspec.",
        "2": "Create an AWS CodeBuild project. Add the repository package's build and test commands to the project's buildspec.",
        "3": "Create an AWS CodeDeploy project. Add the repository package's build and test commands to the project's buildspec.",
        "4": "Add an action to the source stage. Specify the newly created project as the action provider. Specify the build artifact as the action's input artifact.",
        "5":"Add a new stage to the pipeline after the source stage. Add an action to the new stage. Specify the newly created project as the action provider. Specify the source artifact as the action's input artifact.",
        "answer": "BE"
      },
      {
        "no": "70",
        "question": "An engineer created an A/B test of a new feature on an Amazon CloudWatch Evidently project. The engineer configured two variations of the feature (Variation A and Variation B) for the test. The engineer wants to work exclusively with Variation A. The engineer needs to make updates so that Variation A is the only variation that appears when the engineer hits the application's endpoint.\n\nWhich solution will meet this requirement?",
        "1": "Add an override to the feature. Set the identifier of the override to the engineer's user ID. Set the variation to Variation A.",
        "2": "Add an override to the feature. Set the identifier of the override to Variation A. Set the variation to 100%.",
        "3": "Add an experiment to the project. Set the identifier of the experiment to Variation B. Set the variation to 0%.",
        "4": "Add an experiment to the project. Set the identifier of the experiment to the AWS account's account ISet the variation to Variation A.",
        "5":1,
        "answer": "A"
      },

      {
        "no": "71",
        "question": "A developer is working on an existing application that uses Amazon DynamoDB as its data store. The DynamoDB table has the following attributes: partNumber (partition key), vendor (sort key), description, productFamily, and productType. When the developer analyzes the usage patterns, the developer notices that there are application modules that frequently look for a list of products based on the productFamily and productType attributes.\n\nThe developer wants to make changes to the application to improve performance of the query operations.\n\nWhich solution will meet these requirements?",
        "1": "Create a global secondary index (GSI) with productFamily as the partition key and productType as the sort key.",
        "2": "Create a local secondary index (LSI) with productFamily as the partition key and productType as the sort key.",
        "3": "Recreate the table. Add partNumber as the partition key and vendor as the sort key. During table creation, add a local secondary index (LSI) with productFamily as the partition key and productType as the sort key.",
        "4": "Update the queries to use Scan operations with productFamily as the partition key and productType as the sort key.",
        "5":1,
        "answer": "A"
      },
      {
        "no": "72",
        "question": "A developer creates a VPC named VPC-A that has public and private subnets. The developer also creates an Amazon RDS database inside the private subnet of VPC-A. To perform some queries, the developer creates an AWS Lambda function in the default VPC. The Lambda function has code to access the RDS database. When the Lambda function runs, an error message indicates that the function cannot connect to the RDS database.\n\nHow can the developer solve this problem?",
        "1": "Modify the RDS security group. Add a rule to allow traffic from all the ports from the VPC CIDR block.",
        "2": "Redeploy the Lambda function in the same subnet as the RDS instance. Ensure that the RDS security group allows traffic from the Lambda function.",
        "3": "Create a security group for the Lambda function. Add a new rule in the RDS security group to allow traffic from the new Lambda security group.",
        "4": "Create an IAM role. Attach a policy that allows access to the RDS database. Attach the role to the Lambda function.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "73",
        "question": "A company runs an application on AWS. The company deployed the application on Amazon EC2 instances. The application stores data on Amazon Aurora.\n\nThe application recently logged multiple application-specific custom DECRYP_ERROR errors to Amazon CloudWatch logs. The company did not detect the issue until the automated tests that run every 30 minutes failed. A developer must implement a solution that will monitor for the custom errors and alert a development team in real time when these errors occur in the production environment.\n\nWhich solution will meet these requirements with the LEAST operational overhead?",
        "1": "Configure the application to create a custom metric and to push the metric to CloudWatch. Create an AWS CloudTrail alarm. Configure the CloudTrail alarm to use an Amazon Simple Notification Service (Amazon SNS) topic to send notifications.",
        "2": "Create an AWS Lambda function to run every 5 minutes to scan the CloudWatch logs for the keyword DECRYP_ERROR. Configure the Lambda function to use Amazon Simple Notification Service (Amazon SNS) to send a notification.",
        "3": "Use Amazon CloudWatch Logs to create a metric filter that has a filter pattern for DECRYP_ERROR. Create a CloudWatch alarm on this metric for a threshold >=1. Configure the alarm to send Amazon Simple Notification Service (Amazon SNS) notifications.",
        "4": "Install the CloudWatch unified agent on the EC2 instance. Configure the application to generate a metric for the keyword DECRYP_ERROR errors. Configure the agent to send Amazon Simple Notification Service (Amazon SNS) notifications.",
        "5":1,
        "answer": "C"
      },
      {
        "no": "74",
        "question": "A developer created an AWS Lambda function that accesses resources in a VPC. The Lambda function polls an Amazon Simple Queue Service (Amazon SQS) queue for new messages through a VPC endpoint. Then the function calculates a rolling average of the numeric values that are contained in the messages. After initial tests of the Lambda function, the developer found that the value of the rolling average that the function returned was not accurate.\n\nHow can the developer ensure that the function calculates an accurate rolling average?",
        "1": "Set the function's reserved concurrency to 1. Calculate the rolling average in the function. Store the calculated rolling average in Amazon ElastiCache.",
        "2": "Modify the function to store the values in Amazon ElastiCache. When the function initializes, use the previous values from the cache to calculate the rolling average.",
        "3": "Set the function's provisioned concurrency to 1. Calculate the rolling average in the function. Store the calculated rolling average in Amazon ElastiCache.",
        "4": "Modify the function to store the values in the function's layers. When the function initializes, use the previously stored values to calculate the rolling average.",
        "5":1,
        "answer": "A"
      },
      {
        "no": "75",
        "question": "A developer is writing unit tests for a new application that will be deployed on AWS. The developer wants to validate all pull requests with unit tests and merge the code with the main branch only when all tests pass.\n\nThe developer stores the code in AWS CodeCommit and sets up AWS CodeBuild to run the unit tests. The developer creates an AWS Lambda function to start the CodeBuild task. The developer needs to identify the CodeCommit events in an Amazon EventBridge event that can invoke the Lambda function when a pull request is created or updated.\n\nWhich CodeCommit event will meet these requirements?",
        "1":"NA",
        "2": "NA",
        "3": "NA",
        "4": "NA",
        "5":1,
        "answer": "C"
      },
      {
        "no": "76",
        "question": "A developer deployed an application to an Amazon EC2 instance. The application needs to know the public IPv4 address of the instance.\n\nHow can the application find this information?",
        "1":"Query the instance metadata from http://169.254.169.254/latest/meta-data/.",
        "2": "Query the instance user data from http://169.254.169.254/latest/user-data/.",
        "3": "Query the Amazon Machine Image (AMI) information from http://169.254.169.254/latest/meta-data/ami/.",
        "4": "Check the hosts file of the operating system.",
        "5":1,
        "answer": "A"
      },
      {
        "no": "77",
        "question": "An application under development is required to store hundreds of video files. The data must be encrypted within the application prior to storage, with a unique key for each video file.\n\nHow should the developer code the application?",
        "1":"Use the KMS Encrypt API to encrypt the data. Store the encrypted data key and data.",
        "2": "Use a cryptography library to generate an encryption key for the application. Use the encryption key to encrypt the data. Store the encrypted data.",
        "3": "Use the KMS GenerateDataKey API to get a data key. Encrypt the data with the data key. Store the encrypted data key and data.",
        "4": "Upload the data to an S3 bucket using server side-encryption with an AWS KMS key.",
        "5":1,
        "answer": "C"
      },
      {
        "no": "78",
        "question": "A company is planning to deploy an application on AWS behind an Elastic Load Balancer. The application uses an HTTP/HTTPS listener and must access the client IP addresses.\n\nWhich load-balancing solution meets these requirements?",
        "1":"Use an Application Load Balancer and the X-Forwarded-For headers.",
        "2": "Use a Network Load Balancer (NLB). Enable proxy protocol support on the NLB and the target application.",
        "3": "Use an Application Load Balancer. Register the targets by the instance ID.",
        "4": "Use a Network Load Balancer and the X-Forwarded-For headers.",
        "5":1,
        "answer": "A"
      },
      {
        "no": "79",
        "question": "A developer wants to debug an application by searching and filtering log data. The application logs are stored in Amazon CloudWatch Logs. The developer creates a new metric filter to count exceptions in the application logs. However, no results are returned from the logs.\n\nWhat is the reason that no filtered results are being returned?",
        "1":"A setup of the Amazon CloudWatch interface VPC endpoint is required for filtering the CloudWatch Logs in the VPC.",
        "2": "CloudWatch Logs only publishes metric data for events that happen after the filter is created.",
        "3": "The log group for CloudWatch Logs should be first streamed to Amazon OpenSearch Service before metric filtering returns the results.",
        "4": "Metric data points for logs groups can be filtered only after they are exported to an Amazon S3 bucket.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "80",
        "question": "A company is planning to use AWS CodeDeploy to deploy an application to Amazon Elastic Container Service (Amazon ECS). During the deployment of a new version of the application, the company initially must expose only 10% of live traffic to the new version of the deployed application. Then, after 15 minutes elapse, the company must route all the remaining live traffic to the new version of the deployed application.\n\nWhich CodeDeploy predefined configuration will meet these requirements?",
        "1":"CodeDeployDefault.ECSCanary10Percent15Minutes",
        "2": "CodeDeployDefault.LambdaCanary10Percent5Minutes",
        "3": "CodeDeployDefault.LambdaCanary10Percentl15Minutes",
        "4": "CodeDeployDefault.ECSLinear10PercentEvery1Minutes",
        "5":1,
        "answer": "A"
      },
      {
        "no": "81",
        "question": "A company hosts a batch processing application on AWS Elastic Beanstalk with instances that run the most recent version of Amazon Linux. The application sorts and processes large datasets. \n\nIn recent weeks, the application's performance has decreased significantly during a peak period for traffic. A developer suspects that the application issues are related to the memory usage. The developer checks the Elastic Beanstalk console and notices that memory usage is not being tracked.\n\nHow should the developer gather more information about the application performance issues?",
        "1": "Configure the Amazon CloudWatch agent to push logs to Amazon CloudWatch Logs by using port 443.",
        "2": "Configure the Elastic Beanstalk .ebextensions directory to track the memory usage of the instances.",
        "3": "Configure the Amazon CloudWatch agent to track the memory usage of the instances.",
        "4": "Configure an Amazon CloudWatch dashboard to track the memory usage of the instances.",
        "5":1,
        "answer": "C"
      },
      {
        "no": "82",
        "question": "A developer is building a highly secure healthcare application using serverless components. This application requires writing temporary data to /tmp storage on an AWS Lambda function.\n\nHow should the developer encrypt this data?",
        "1": "Enable Amazon EBS volume encryption with an AWS KMS key in the Lambda function configuration so that all storage attached to the Lambda function is encrypted.",
        "2": "Set up the Lambda function with a role and key policy to access an AWS KMS key. Use the key to generate a data key used to encrypt all data prior to writing to /tmp storage.",
        "3": "Use OpenSSL to generate a symmetric encryption key on Lambda startup. Use this key to encrypt the data prior to writing to /tmp.",
        "4": "Use an on-premises hardware security module (HSM) to generate keys, where the Lambda function requests a data key from the HSM and uses that to encrypt data on all requests to the function.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "83",
        "question": "A developer has created an AWS Lambda function to provide notification through Amazon Simple Notification Service (Amazon SNS) whenever a file is uploaded to Amazon S3 that is larger than 50 MB. The developer has deployed and tested the Lambda function by using the CLI. However, when the event notification is added to the S3 bucket and a 3,000 MB file is uploaded, the Lambda function does not launch.\n\nWhich of the following is a possible reason for the Lambda function's inability to launch?",
        "1": "The S3 event notification does not activate for files that are larger than 1,000 MB.",
        "2": "The resource-based policy for the Lambda function does not have the required permissions to be invoked by Amazon S3.",
        "3": "Lambda functions cannot be invoked directly from an S3 event.",
        "4": "The S3 bucket needs to be made public.",
        "5":1,
        "answer": "B"
      },
      {
        "no": "84",
        "question": "A developer is creating a Ruby application and needs to automate the deployment, scaling, and management of an environment without requiring knowledge of the underlying infrastructure.\n\nWhich service would best accomplish this task?",
        "1": "AWS CodeDeploy",
        "2": "AWS CloudFormation",
        "3": "AWS OpsWorks",
        "4": "AWS Elastic Beanstalk",
        "5":1,
        "answer": "D"
      },
      {
        "no": "85",
        "question": "A company has a web application that is deployed on AWS. The application uses an Amazon API Gateway API and an AWS Lambda function as its backend.\n\nThe application recently demonstrated unexpected behavior. A developer examines the Lambda function code, finds an error, and modifies the code to resolve the problem. Before deploying the change to production, the developer needs to run tests to validate that the application operates properly.\n\nThe application has only a production environment available. The developer must create a new development environment to test the code changes. The developer must also prevent other developers from overwriting these changes during the test cycle.\n\nWhich combination of steps will meet these requirements with the LEAST development effort? (Choose two.)",
        "1": "Create a new resource in the current stage. Create a new method with Lambda proxy integration. Select the Lambda function. Add the hotfix alias. Redeploy the current stage. Test the backend.",
        "2": "Update the Lambda function in the API Gateway API integration request to use the hotfix alias. Deploy the API Gateway API to a new stage named hotfix. Test the backend.",
        "3": "Modify the Lambda function by fixing the code. Test the Lambda function. Create the alias hotfix. Point the alias to the $LATEST version.",
        "4": "Modify the Lambda function by fixing the code. Test the Lambda function. When the Lambda function is working as expected, publish the Lambda function as a new version. Create the alias hotfix. Point the alias to the new version.",
        "5":"Create a new API Gateway API for the development environment. Add a resource and method with Lambda integration. Choose the Lambda function and the hotfix alias. Deploy to a new stage. Test the backend.",
        "answer": "BD"
      },
      {
        "no": "86",
        "question": "A developer is implementing an AWS Cloud Development Kit (AWS CDK) serverless application. The developer will provision several AWS Lambda functions and Amazon API Gateway APIs during AWS CloudFormation stack creation. The developer's workstation has the AWS Serverless Application Model (AWS SAM) and the AWS CDK installed locally.\n\nHow can the developer test a specific Lambda function locally?",
        "1": "Run the sam package and sam deploy commands. Create a Lambda test event from the AWS Management Console. Test the Lambda function.",
        "2": "Run the cdk synth and cdk deploy commands. Create a Lambda test event from the AWS Management Console. Test the Lambda function.",
        "3": "Run the cdk synth and sam local invoke commands with the function construct identifier and the path to the synthesized CloudFormation template.",
        "4": "Run the cdk synth and sam local start-lambda commands with the function construct identifier and the path to the synthesized CloudFormation template.",
        "5":1,
        "answer": "C"
      },
      {
        "no": "87",
        "question": "A company's new mobile app uses Amazon API Gateway. As the development team completes a new release of its APIs, a developer must safely and transparently roll out the API change.\n\nWhat is the SIMPLEST solution for the developer to use for rolling out the new API version to a limited number of users through API Gateway?",
        "1": "Create a new API in API Gateway. Direct a portion of the traffic to the new API using an Amazon Route 53 weighted routing policy.",
        "2": "Validate the new API version and promote it to production during the window of lowest expected utilization.",
        "3": "Implement an Amazon CloudWatch alarm to trigger a rollback if the observed HTTP 500 status code rate exceeds a predetermined threshold.",
        "4": "Use the canary release deployment option in API Gateway. Direct a percentage of the API traffic using the canarySettings setting.",
        "5":1,
        "answer": "D"
      },
      {
      "no": "88",
      "question": "A company caches session information for a web application in an Amazon DynamoDB table. The company wants an automated way to delete old items from the table.\n\nWhat is the simplest way to do this?",
      "1": "Write a script that deletes old records; schedule the script as a cron job on an Amazon EC2 instance.",
      "2": "Add an attribute with the expiration time; enable the Time To Live feature based on that attribute.",
      "3": "Each day, create a new table to hold session data; delete the previous day's table.",
      "4": "Add an attribute with the expiration time; name the attribute ItemExpiration.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "89",
      "question": "A company is using an Amazon API Gateway REST API endpoint as a webhook to publish events from an on-premises source control management (SCM) system to Amazon EventBridge. The company has configured an EventBridge rule to listen for the events and to control application deployment in a central AWS account. The company needs to receive the same events across multiple receiver AWS accounts.\n\nHow can a developer meet these requirements without changing the configuration of the SCM system?",
      "1": "Deploy the API Gateway REST API to all the required AWS accounts. Use the same custom domain name for all the gateway endpoints so that a single SCM webhook can be used for all events from all accounts.",
      "2": "Deploy the API Gateway REST API to all the receiver AWS accounts. Create as many SCM webhooks as the number of AWS accounts.",
      "3": "Grant permission to the central AWS account for EventBridge to access the receiver AWS accounts. Add an EventBridge event bus on the receiver AWS accounts as the targets to the existing EventBridge rule.",
      "4": "Convert the API Gateway type from REST API to HTTP API.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "90",
      "question": "A company moved some of its secure files to a private Amazon S3 bucket that has no public access. The company wants to develop a serverless application that gives its employees the ability to log in and securely share the files with other users.\n\nWhich AWS feature should the company use to share and access the files securely?",
      "1": "Amazon Cognito user pool",
      "2": "S3 presigned URLs",
      "3": "S3 bucket policy",
      "4": "Amazon Cognito identity pool",
      "5":1,
      "answer": "B"
    },
    {
      "no": "91",
      "question": "A company needs to develop a proof of concept for a web service application. The application will show the weather forecast for one of the company's office locations. The application will provide a REST endpoint that clients can call. Where possible, the application should use caching features provided by AWS to limit the number of requests to the backend service. The application backend will receive a small amount of traffic only during testing.\n\nWhich approach should the developer take to provide the REST endpoint MOST cost-effectively?",
      "1": "Create a container image. Deploy the container image by using Amazon Elastic Kubernetes Service (Amazon EKS). Expose the functionality by using Amazon API Gateway.",
      "2": "Create an AWS Lambda function by using the AWS Serverless Application Model (AWS SAM). Expose the Lambda functionality by using Amazon API Gateway.",
      "3": "Create a container image. Deploy the container image by using Amazon Elastic Container Service (Amazon ECS). Expose the functionality by using Amazon API Gateway.",
      "4": "Create a microservices application. Deploy the application to AWS Elastic Beanstalk. Expose the AWS Lambda functionality by using an Application Load Balancer.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "92",
      "question": "An e-commerce web application that shares session state on-premises is being migrated to AWS. The application must be fault tolerant, natively highly scalable, and any service interruption should not affect the user experience.\n\nWhat is the best option to store the session state?",
      "1": "Store the session state in Amazon ElastiCache.",
      "2": "Store the session state in Amazon CloudFront.",
      "3": "Store the session state in Amazon S3.",
      "4": "Enable session stickiness using elastic load balancers.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "93",
      "question": "A developer is building an application that uses Amazon DynamoDB. The developer wants to retrieve multiple specific items from the database with a single API call.\n\nWhich DynamoDB API call will meet these requirements with the MINIMUM impact on the database?",
      "1": "BatchGetItem",
      "2": "GetItem",
      "3": "Scan",
      "4": "Query",
      "5":1,
      "answer": "A"
    },
    {
      "no": "94",
      "question": "A developer has written an application that runs on Amazon EC2 instances. The developer is adding functionality for the application to write objects to an Amazon S3 bucket.\n\nWhich policy must the developer modify to allow the instances to write these objects?",
      "1": "The IAM policy that is attached to the EC2 instance profile role",
      "2": "The session policy that is applied to the EC2 instance role session",
      "3": "The AWS Key Management Service (AWS KMS) key policy that is attached to the EC2 instance profile role",
      "4": "The Amazon VPC endpoint policy",
      "5":1,
      "answer": "A"
    },
    {
      "no": "95",
      "question": "A developer is leveraging a Border Gateway Protocol (BGP)-based AWS VPN connection to connect from on-premises to Amazon EC2 instances in the developer's account. The developer is able to access an EC2 instance in subnet A, but is unable to access an EC2 instance in subnet B in the same VPC.\n\nWhich logs can the developer use to verify whether the traffic is reaching subnet B?",
      "1": "VPN logs",
      "2": "BGP logs",
      "3": "VPC Flow Logs",
      "4": "AWS CloudTrail logs",
      "5":1,
      "answer": "C"
    },
    {
      "no": "96",
      "question": "A developer is creating a service that uses an Amazon S3 bucket for image uploads. The service will use an AWS Lambda function to create a thumbnail of each image. Each time an image is uploaded, the service needs to send an email notification and create the thumbnail. The developer needs to configure the image processing and email notifications setup.\n\nWhich solution will meet these requirements?",
      "1": "Create an Amazon Simple Notification Service (Amazon SNS) topic. Configure S3 event notifications with a destination of the SNS topic. Subscribe the Lambda function to the SNS topic. Create an email notification subscription to the SNS topic.",
      "2": "Create an Amazon Simple Notification Service (Amazon SNS) topic. Configure S3 event notifications with a destination of the SNS topic. Subscribe the Lambda function to the SNS topic. Create an Amazon Simple Queue Service (Amazon SQS) queue. Subscribe the SQS queue to the SNS topic. Create an email notification subscription to the SQS queue.",
      "3": "Create an Amazon Simple Queue Service (Amazon SQS) queue. Configure S3 event notifications with a destination of the SQS queue. Subscribe the Lambda function to the SQS queue. Create an email notification subscription to the SQS queue.",
      "4": "Create an Amazon Simple Queue Service (Amazon SQS) queue. Send S3 event notifications to Amazon EventBridge. Create an EventBridge rule that runs the Lambda function when images are uploaded to the S3 bucket. Create an EventBridge rule that sends notifications to the SQS queue. Create an email notification subscription to the SQS queue.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "97",
      "question": "A developer has designed an application to store incoming data as JSON files in Amazon S3 objects. Custom business logic in an AWS Lambda function then transforms the objects, and the Lambda function loads the data into an Amazon DynamoDB table. Recently, the workload has experienced sudden and significant changes in traffic. The flow of data to the DynamoDB table is becoming throttled.\n\nThe developer needs to implement a solution to eliminate the throttling and load the data into the DynamoDB table more consistently.\n\nWhich solution will meet these requirements?",
      "1": "Refactor the Lambda function into two functions. Configure one function to transform the data and one function to load the data into the DynamoDB table. Create an Amazon Simple Queue Service (Amazon SQS) queue in between the functions to hold the items as messages and to invoke the second function.",
      "2": "Turn on auto scaling for the DynamoDB table. Use Amazon CloudWatch to monitor the table's read and write capacity metrics and to track consumed capacity.",
      "3": "Create an alias for the Lambda function. Configure provisioned concurrency for the application to use.",
      "4": "Refactor the Lambda function into two functions. Configure one function to store the data in the DynamoDB table. Configure the second function to process the data and update the items after the data is stored in DynamoDB. Create a DynamoDB stream to invoke the second function after the data is stored.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "98",
      "question": "A developer is creating an AWS Lambda function in VPC mode. An Amazon S3 event will invoke the Lambda function when an object is uploaded into an S3 bucket. The Lambda function will process the object and produce some analytic results that will be recorded into a file. Each processed object will also generate a log entry that will be recorded into a file.\n\nOther Lambda functions, AWS services, and on-premises resources must have access to the result files and log file. Each log entry must also be appended to the same shared log file. The developer needs a solution that can share files and append results into an existing file.\n\nWhich solution should the developer use to meet these requirements?",
      "1": "Create an Amazon Elastic File System (Amazon EFS) file system. Mount the EFS file system in Lambda. Store the result files and log file in the mount point. Append the log entries to the log file.",
      "2": "Create an Amazon Elastic Block Store (Amazon EBS) Multi-Attach enabled volume. Attach the EBS volume to all Lambda functions. Update the Lambda function code to download the log file, append the log entries, and upload the modified log file to Amazon EBS.",
      "3": "Create a reference to the /tmp local directory. Store the result files and log file by using the directory reference. Append the log entry to the log file.",
      "4": "Create a reference to the /opt storage directory. Store the result files and log file by using the directory reference. Append the log entry to the log file.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "99",
      "question": "A company has an AWS Lambda function that processes incoming requests from an Amazon API Gateway API. The API calls the Lambda function by using a Lambda alias. A developer updated the Lambda function code to handle more details related to the incoming requests. The developer wants to deploy the new Lambda function for more testing by other developers with no impact to customers that use the API.\n\nWhich solution will meet these requirements with the LEAST operational overhead?",
      "1": "Create a new version of the Lambda function. Create a new stage on API Gateway with integration to the new Lambda version. Use the new API Gateway stage to test the Lambda function.",
      "2": "Update the existing Lambda alias used by API Gateway to a weighted alias. Add the new Lambda version as an additional Lambda function with a weight of 10%. Use the existing API Gateway stage for testing.",
      "3": "Create a new version of the Lambda function. Create and deploy a second Lambda function to filter incoming requests from API Gateway. If the filtering Lambda function detects a test request, the filtering Lambda function will invoke the new Lambda version of the code. For other requests, the filtering Lambda function will invoke the old Lambda version. Update the API Gateway API to use the filtering Lambda function.",
      "4": "Create a new version of the Lambda function. Create a new API Gateway API for testing purposes. Update the integration of the new API with the new Lambda version. Use the new API for testing.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "100",
      "question": "A company uses AWS Lambda functions and an Amazon S3 trigger to process images into an S3 bucket. A development team set up multiple environments in a single AWS account.\n\nAfter a recent production deployment, the development team observed that the development S3 buckets invoked the production environment Lambda functions. These invocations caused unwanted execution of development S3 files by using production Lambda functions. The development team must prevent these invocations. The team must follow security best practices.\n\nWhich solution will meet these requirements?",
      "1": "Update the Lambda execution role for the production Lambda function to add a policy that allows the execution role to read from only the production environment S3 bucket.",
      "2": "Move the development and production environments into separate AWS accounts. Add a resource policy to each Lambda function to allow only S3 buckets that are within the same account to invoke the function.",
      "3": "Add a resource policy to the production Lambda function to allow only the production environment S3 bucket to invoke the function.",
      "4": "Move the development and production environments into separate AWS accounts. Update the Lambda execution role for each function to add a policy that allows the execution role to read from the S3 bucket that is within the same account.",
      "5":1,
      "answer": "B"
    },

    {
      "no": "101",
      "question": "A company is using an AWS Lambda function to process records from an Amazon Kinesis data stream. The company recently observed slow processing of the records. A developer notices that the iterator age metric for the function is increasing and that the Lambda run duration is constantly above normal.\n\nWhich actions should the developer take to increase the processing speed? (Choose two.)",
      "1": "Increase the number of shards of the Kinesis data stream.",
      "2": "Decrease the timeout of the Lambda function.",
      "3": "Increase the memory that is allocated to the Lambda function.",
      "4": "Decrease the number of shards of the Kinesis data stream.",
      "5":"Increase the timeout of the Lambda function.",
      "answer": "AC"
    },
    {
      "no": "102",
      "question": "A developer is making changes to a custom application that uses AWS Elastic Beanstalk.\n\nWhich solutions will update the Elastic Beanstalk environment with the new application version after the developer completes the changes? (Choose two.)",
      "1": "Package the application code into a .zip file. Use the AWS Management Console to upload the zip file and deploy the packaged application.",
      "2": "Package the application code into a .tar file. Use the AWS Management Console to create a new application version from the .tar file. Update the environment by using the AWS CLI.",
      "3": "Package the application code into a .tar file. Use the AWS Management Console to upload the .tar file and deploy the packaged application.",
      "4": "Package the application code into a .zip file. Use the AWS CLI to create a new application version from the .zip file and to update the environment.",
      "5":"Package the application code into a .zip file. Use the AWS Management Console to create a new application version from the .zip file. Rebuild the environment by using the AWS CLI.",
      "answer": "AD"
    },

    {
      "no": "103",
      "question": "A company has an application where reading objects from Amazon S3 is based on the type of user. The user types are registered user and guest user. The company has 25,000 users and is growing. Information is pulled from an S3 bucket depending on the user type.\n\nWhich approaches are recommended to provide access to both user types? (Choose two.)",
      "1": "Provide a different access key and secret access key in the application code for registered users and guest users to provide read access to the objects.",
      "2": "Use S3 bucket policies to restrict read access to specific IAM users.",
      "3": "Use Amazon Cognito to provide access using authenticated and unauthenticated roles.",
      "4": "Create a new IAM user for each user and grant read access.",
      "5":"Use the AWS IAM service and let the application assume the different roles using the AWS Security Token Service (AWS STS) AssumeRole action depending on the type of user and provide read access to Amazon S3 using the assumed role.",
      "answer": "CE"
    },

    {
      "no": "104",
      "question": "A developer is writing an application to analyze the traffic to a fleet of Amazon EC2 instances. The EC2 instances run behind a public Application Load Balancer(ALB). An HTTP server runs on each of the EC2 instances, logging all requests to a log file. The developer wants to capture the client public IP addresses. The developer analyzes the log files and notices only the IP address of the ALB. What must the developer do to capture the client public IP addresses in the log file?",
      "1": "Add a Host header to the HTTP server log configuration file.",
      "2": "Install the Amazon CloudWatch Logs agent on each EC2 instance. Configure the agent to write to the log file.",
      "3": "Install the AWS X-Ray daemon on each EC2 instance. Configure the daemon to write to the log file.",
      "4": "Add an X-Forwarded-For header to the HTTP server log configuration file",
      "5":1,
      "answer": "D"
    },
    {
      "no": "105",
      "question": "A developer is writing a new AWS Serverless Application Model (AWS SAM) template with a new AWS Lambda function. The Lambda function runs complex code. The developer wants to test the Lambda function with more CPU power.\n\nWhat should the developer do to meet this requirement?",
      "1": "Increase the runtime engine version.",
      "2": "Increase the timeout.",
      "3": "Increase the number of Lambda layers.",
      "4": "Increase the memory.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "106",
      "question": "A developer uses a single AWS CloudFormation template to configure the test environment and the production environment for an application. The developer handles environment-specific requirements in the CloudFormation template.\n\nThe developer decides to update the Amazon EC2 Auto Scaling launch template with new Amazon Machine Images (AMIs) for each environment. The CloudFormation update for the new AMIs is successful in the test environment, but the update fails in the production environment.\n\nWhat are the possible causes of the CloudFormation update failure in the production environment? (Choose two.)",
      "1": "The new AMIs do not fulfill the specified conditions in the CloudFormation template.",
      "2": "The service quota for the number of EC2 vCPUs in the AWS Region has been exceeded.",
      "3": "The security group that is specified in the CloudFormation template does not exist.",
      "4": "CloudFormation does not recognize the template change as an update.",
      "5":"CloudFormation does not have sufficient IAM permissions to make the changes.",
      "answer": "AB"
    },
    {
      "no": "107",
      "question": "A developer is creating a serverless web application and maintains different branches of code. The developer wants to avoid updating the Amazon API Gateway target endpoint each time a new code push is performed.\n\nWhat solution would allow the developer to perform a code push efficiently, without the need to update the API Gateway?",
      "1": "Associate different AWS Lambda functions to an API Gateway target endpoint.",
      "2": "Create different stages in API Gateway. then associate API Gateway with AWS Lambda.",
      "3": "Create aliases and versions in AWS Lambda.",
      "4": "Tag the AWS Lambda functions with different names.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "108",
      "question": "A developer needs to deploy an application running on AWS Fargate using Amazon ECS. The application has environment variables that must be passed to a container for the application to initialize.\n\nHow should the environment variables be passed to the container?",
      "1": "Define an array that includes the environment variables under the environment parameter within the service definition.",
      "2": "Define an array that includes the environment variables under the environment parameter within the task definition.",
      "3": "Define an array that includes the environment variables under the entryPoint parameter within the task definition.",
      "4": "Define an array that includes the environment variables under the entryPoint parameter within the service definition.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "109",
      "question": "A developer must extend an existing application that is based on the AWS Serverless Application Model (AWS SAM). The developer has used the AWS SAM CLI to create the project. The project contains different AWS Lambda functions.\n\nWhich combination of commands must the developer use to redeploy the AWS SAM application? (Choose two.)",
      "1": "sam init",
      "2": "sam validate",
      "3": "sam build",
      "4": "sam deploy",
      "5":"sam publish",
      "answer": "CD"
    },
    {
      "no": "110",
      "question": "A developer manages an application that interacts with Amazon RDS. After observing slow performance with read queries, the developer implements Amazon ElastiCache to update the cache immediately following the primary database update.\n\nWhat will be the result of this approach to caching?",
      "1": "Caching will increase the load on the database instance because the cache is updated for every database update.",
      "2": "Caching will slow performance of the read queries because the cache is updated when the cache cannot find the requested data.",
      "3": "The cache will become large and expensive because the infrequently requested data is also written to the cache.",
      "4": "Overhead will be added to the initial response time because the cache is updated only after a cache miss.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "111",
      "question": "A developer has a stateful web server on-premises that is being migrated to AWS. The developer must have greater elasticity in the new design. How should the developer re-factor the application to make it more elastic? (Choose two.)",
      "1": "Use pessimistic concurrency on Amazon DynamoDB.",
      "2": "Use Amazon CloudFront with an Auto Scaling group.",
      "3": "Use Amazon CloudFront with an AWS Web Application Firewall.",
      "4": "Store session state data in an Amazon DynamoDB table.",
      "5":"Use an ELB with an Auto Scaling group.",
      "answer": "DE"
    },
    {
      "no": "112",
      "question": "A developer has a legacy application that is hosted on-premises. Other applications hosted on AWS depend on the on-premises application for proper functioning.\n\nIn case of any application errors, the developer wants to be able to use Amazon CloudWatch to monitor and troubleshoot all applications from one place.\n\nHow can the developer accomplish this?",
      "1": "Install an AWS SDK on the on-premises server to automatically send logs to CloudWatch.",
      "2": "Download the CloudWatch agent to the on-premises server. Configure the agent to use IAM user credentials with permissions for CloudWatch.",
      "3": "Upload log files from the on-premises server to Amazon S3 and have CloudWatch read the files.",
      "4": "Upload log files from the on-premises server to an Amazon EC2 instance and have the instance forward the logs to CloudWatch.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "113",
      "question": "A developer is designing a serverless application with two AWS Lambda functions to process photos. One Lambda function stores objects in an Amazon S3 bucket and stores the associated metadata in an Amazon DynamoDB table. The other Lambda function fetches the objects from the S3 bucket by using the metadata from the DynamoDB table. Both Lambda functions use the same Python library to perform complex computations and are approaching the quota for the maximum size of zipped deployment packages.\n\nWhat should the developer do to reduce the size of the Lambda deployment packages with the LEAST operational overhead?",
      "1": "Package each Python library in its own .zip file archive. Deploy each Lambda function with its own copy of the library.",
      "2": "Create a Lambda layer with the required Python library. Use the Lambda layer in both Lambda functions.",
      "3": "Combine the two Lambda functions into one Lambda function. Deploy the Lambda function as a single .zip file archive.",
      "4": "Download the Python library to an S3 bucket. Program the Lambda functions to reference the object URLs.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "114",
      "question": "A developer is adding a feature to a client-side application so that users can upload videos to an Amazon S3 bucket. What is the MOST secure way to give the application the ability to write files to the S3 bucket?",
      "1": "Update the S3 bucket policy to allow public write access. Allow any user to upload videos by removing the need to handle user authentication within the client- side application.",
      "2": "Create a new IAM policy and a corresponding IAM user with permissions to write to the S3 bucket. Store the key and the secret for the user in the application code. Use the key to authenticate the video uploads.",
      "3": "Configure the API layer of the application to have a new endpoint that creates signed URLs that allow an object to be put into the S3 bucket. Generate a presigned URL through this API call in the client application. Upload the video by using the signed URL.",
      "4": "Generate a new IAM key and a corresponding secret by using the AWS account root user credentials. Store the key and the secret for the user in the application code. Use the key to authenticate the video uploads.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "115",
      "question": "A company is building an application for stock trading. The application needs sub-millisecond latency for processing trade requests. The company uses Amazon DynamoDB to store all the trading data that is used to process each trading request.\n\nA development team performs load testing on the application and finds that the data retrieval time is higher than expected. The development team needs a solution that reduces the data retrieval time with the least possible effort.\n\nWhich solution meets these requirements?",
      "1": "Add local secondary indexes (LSIs) for the trading data.",
      "2": "Store the trading data in Amazon S3, and use S3 Transfer Acceleration.",
      "3": "Add retries with exponential backoff for DynamoDB queries.",
      "4": "Use DynamoDB Accelerator (DAX) to cache the trading data.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "116",
      "question": "A developer needs to build and deploy a serverless application that has an API that mobile clients will use. The API will use Amazon DynamoDB and Amazon OpenSearch Service (Amazon Elasticsearch Service) as data sources. Responses that are sent to the clients will contain aggregated data from both data sources.\n\nThe developer must minimize the number of API endpoints and must minimize the number of API calls that are required to retrieve the necessary data.\n\nWhich solution should the developer use to meet these requirements?",
      "1": "GraphQL API on AWS AppSync",
      "2": "REST API on Amazon API Gateway",
      "3": "GraphQL API on an Amazon EC2 instance",
      "4": "REST API on AWS Elastic Beanstalk",
      "5":1,
      "answer": "D"
    },
    {
      "no": "117",
      "question": "A Lambda function processes data before sending it to a downstream service. Each piece of data is approximately 1MB in size. After a security audit, the function is now required to encrypt the data before sending it downstream.\n\nWhich API call is required to perform the encryption?",
      "1": "Pass the data to the KMS ReEncrypt API for encryption.",
      "2": "Use the KMS GenerateDataKey API to get an encryption key.",
      "3": "Use the KMS GenerateDataKeyWithoutPlainText API to get an encryption key.",
      "4": "Pass the data to KMS as part of the Encrypt API for encryption.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "118",
      "question": "A company has a web application that runs on Amazon EC2 instances with a custom Amazon Machine Image (AMI). The company uses AWS CloudFormation to provision the application. The application runs in the us-east-1 Region, and the company needs to deploy the application to the us-west-1 Region.\n\nAn attempt to create the AWS CloudFormation stack in us-west-1 fails. An error message states that the AMI ID does not exist. A developer must resolve this error with a solution that uses the least amount of operational overhead.\n\nWhich solution meets these requirements?",
      "1": "Change the AWS CloudFormation templates for us-east-1 and us-west-1 to use an AWS AMI. Relaunch the stack for both Regions.",
      "2": "Copy the custom AMI from us-east-1 to us-west-1. Update the AWS CloudFormation template for us-west-1 to refer to AMI ID for the copied AMI. Relaunch the stack.",
      "3": "Build the custom AMI in us-west-1. Create a new AWS CloudFormation template to launch the stack in us-west-1 with the new AMI ID.",
      "4": "Manually deploy the application outside AWS CloudFormation in us-west-1.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "119",
      "question": "A developer wants to run a PHP website with an NGINX proxy and package them as Docker containers in one environment. The developer wants a managed environment with automated provisioning and load balancing. The developer cannot change the configuration and must minimize operational overhead.\n\nHow should the developer build the website to meet these requirements?",
      "1": "Create a new application in AWS Elastic Beanstalk that is preconfigured for a multicontainer Docker environment. Upload the code, and deploy it to a web server environment.",
      "2": "Deploy the code on Amazon EC2 instances in an Auto Scaling group behind an Application Load Balancer.",
      "3": "Construct an AWS CloudFormation template that launches Amazon EC2 instances. Install and configure the PHP code by using cfn helper scripts.",
      "4": "Upload the code for the PHP website into an Amazon S3 bucket. Host the website from the S3 bucket.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "120",
      "question": "A company has a website that displays a daily newsletter. When a user visits the website, an AWS Lambda function processes the browser's request and queries the company's on-premises database to obtain the current newsletter. The newsletters are stored in English. The Lambda function uses the Amazon TranslateTranslateText API operation to translate the newsletters, and the translation is displayed to the user.\n\nDue to an increase in popularity, the website's response time has slowed. The database is overloaded. The company cannot change the database and needs a solution that improves the response time of the Lambda function.\n\nWhich solution meets these requirements?",
      "1": "Change to asynchronous Lambda function invocation.",
      "2": "Cache the translated newsletters in the Lambda /tmp directory.",
      "3": "Enable TranslateText API caching.",
      "4": "Change the Lambda function to use parallel processing.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "121",
      "question": "A developer is creating an AWS CloudFormation template for an application. The application includes an Amazon RDS database. The password to be set for the resource's MasterUserPassword property is already stored in AWS Secrets Manager.\n\nHow can the developer reference the value of the password in the CloudFormation template?",
      "1": "Use a parameter in the CloudFormation template with the same name of the secret.",
      "2": "Use the ssm dynamic reference by specifying the name of the secret and its version.",
      "3": "Use the secretsmanager dynamic reference by specifying the appropriate reference-key segment.",
      "4": "Use the ssm-secure dynamic reference by specifying the name of the secret and its version.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "122",
      "question": "An application is using Amazon DynamoDB as its data store, and should be able to read 100 items per second as strongly consistent reads. Each item is 5 KB in size. To what value should the table's provisioned read throughput be set?",
      "1": "50 read capacity units",
      "2": "100 read capacity units",
      "3": "200 read capacity units",
      "4": "500 read capacity units",
      "5":1,
      "answer": "C"
    },
    {
      "no": "123",
      "question": "A developer created a Lambda function for a web application backend. When testing the Lambda function from the AWS Lambda console, the developer can see that the function is being run, but there is no log data being generated in Amazon CloudWatch Logs, even after several minutes. What could cause this situation?",
      "1": "The Lambda function does not have any explicit log statements for the log data to send it to CloudWatch Logs.",
      "2": "The Lambda function is missing CloudWatch Logs as a source trigger to send log data.",
      "3": "The execution role for the Lambda function is missing permissions to write log data to the CloudWatch Logs.",
      "4": "The Lambda function is missing a target CloudWatch Log group.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "124",
      "question": "A developer has written code for an application and wants to share it with other developers on the team to receive feedback. The shared application code needs to be stored long-term with multiple versions and batch change tracking.\n\nWhich AWS service should the developer use?",
      "1": "AWS CodeBuild",
      "2": "Amazon S3",
      "3": "AWS CodeCommit",
      "4": "AWS Cloud9",
      "5":1,
      "answer": "C"
    },
    {
      "no": "125",
      "question": "A developer has created a new IAM user that has the s3:PutObject permission to write to a specific Amazon S3 bucket. The S3 bucket uses server-side encryption with AWS KMS managed keys (SSE-KMS) as the default encryption. When an application uses the access key and secret key of the IAM user to call the PutObject API operation, the application receives an access denied error.\n\nWhat should the developer do to resolve this error?",
      "1": "Update the policy of the IAM user to allow the s3:EncryptionConfiguration action.",
      "2": "Update the bucket policy of the S3 bucket to allow the IAM user to upload objects.",
      "3": "Update the policy of the IAM user to allow the kms:GenerateDataKey action.",
      "4": "Update the ACL of the S3 bucket to allow the IAM user to upload objects.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "126",
      "question": "A static website is hosted in an Amazon S3 bucket. Several HTML pages on the site use JavaScript to download images from another Amazon S3 bucket. These images are not displayed when users browse the site.\n\nWhat is the possible cause for the issue?",
      "1": "The referenced Amazon S3 bucket is in another region.",
      "2": "The images must be stored in the same Amazon S3 bucket.",
      "3": "Port 80 must be opened on the security group in which the Amazon S3 bucket is located.",
      "4": "Cross Origin Resource Sharing must be enabled on the Amazon S3 bucket.",
      "5":1,
      "answer": "D"
    },

    {
      "no": "127",
      "question": "An application needs to encrypt data that is written to Amazon S3 where the keys are managed in an on-premises data center, and the encryption is handled by S3.\n\n Which type of encryption should be used?",
      "1": "Use server-side encryption with Amazon S3-managed keys.",
      "2": "Use server-side encryption with AWS KMS-managed keys.",
      "3": "Use client-side encryption with AWS KMS-managed keys.",
      "4": "Use server-side encryption with customer-provided keys.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "128",
      "question": "A developer is building a serverless application that is based on AWS Lambda. The developer initializes the AWS software development kit (SDK) outside of the Lambda handler function.\n\nWhat is the PRIMARY benefit of this action?",
      "1": "Improves legibility and stylistic convention",
      "2": "Takes advantage of runtime environment reuse",
      "3": "Provides better error handling",
      "4": "Creates a new SDK instance for each invocation",
      "5":1,
      "answer": "B"
    },
    {
      "no": "129",
      "question": "A developer is testing a Docker-based application that uses the AWS SDK to interact with Amazon DynamoDB. In the local development environment, the application has used IAM access keys. The application is now ready for deployment onto an ECS cluster.\n\nHow should the application authenticate with AWS services in production?",
      "1": "Configure an ECS task IAM role for the application to use.",
      "2": "Refactor the application to call AWS STS AssumeRole based on an instance role.",
      "3": "Configure AWS access key/secret access key environment variables with new credentials.",
      "4": "Configure the credentials file with a new access key/secret access key.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "130",
      "question": "A developer has created an AWS Lambda function to provide notification through Amazon Simple Notification Service (Amazon SNS) whenever a file is uploaded to Amazon S3 that is larger than 50 MB. The developer has deployed and tested the Lambda function by using the CLI. However, when the event notification is added to the S3 bucket and a 3,000 MB file is uploaded, the Lambda function does not launch.\n\nWhich of the following is a possible reason for the Lambda function's inability to launch?",
      "1": "The S3 event notification does not activate for files that are larger than 1,000 MB.",
      "2": "The resource-based policy for the Lambda function does not have the required permissions to be invoked by Amazon S3.",
      "3": "Lambda functions cannot be invoked directly from an S3 event.",
      "4": "The S3 bucket needs to be made public.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "131",
      "question": "A company stores documents in Amazon S3 with default settings. A new regulation requires the company to encrypt the documents at rest, rotate the encryption keys annually, and keep a record of when the encryption keys were rotated. The company does not want to manage the encryption keys outside of AWS.\n\nWhich solution will meet these requirements?",
      "1": "Use server-side encryption with Amazon S3 managed encryption keys (SSE-S3).",
      "2": "Use server-side encryption with AWS KMS managed encryption keys (SSE-KMS).",
      "3": "Use server-side encryption with customer-provided encryption keys (SSE-C).",
      "4": "Use client-side encryption before sending the data to Amazon S3.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "132",
      "question": "A  developer has discovered that an application responsible for processing messages in an Amazon SQS queue is routinely falling behind. The application is capable of processing multiple messages in one invocation, but is only receiving one message at a time. What should the developer do to increase the number of messages the application receives?",
      "1": "Call the ChangeMessageVisibility API for the queue and set MaxNumberOfMessages to a value greater than the default of 1.",
      "2": "Call the AddPermission API to set MaxNumberOfMessages for the ReceiveMessage action to a value greater than the default of 1.",
      "3": "Call the ReceiveMessage API to set MaxNumberOfMessages to a value greater than the default of 1.",
      "4": "Call the SetQueueAttributes API for the queue and set MaxNumberOfMessages to a value greater than the default of 1.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "133",
      "question": "A developer is using an Amazon Kinesis Data Firehose delivery stream to store data in Amazon S3. Before storing the data in Amazon S3, the developer wants to enrich the data by combining the data with data from an Amazon DynamoDB table.\n\nHow can the developer implement the data enrichment?",
      "1": "Create a Kinesis Data Firehose data transformation by using an Amazon EC2 instance.",
      "2": "Configure the Kinesis Data Firehose delivery stream to send data to a Kinesis data stream. Enrich the data by using an AWS Lambda function.",
      "3": "Configure the Kinesis Data Firehose delivery stream to store data in the DynamoDB table. Export the table to Amazon S3.",
      "4": "Create a Kinesis Data Firehose data transformation by using an AWS Lambda function.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "134",
      "question": "A company created an application to consume and process data. The application uses Amazon Simple Queue Service (Amazon SQS) and AWS Lambda functions. The application is currently working as expected, but it occasionally receives several messages that it cannot process properly. The company needs to clear these messages to prevent the queue from becoming blocked.\n\nA developer must implement a solution that makes queue processing always operational. The solution must give the company the ability to defer the messages with errors and save these messages for further analysis.\n\nWhat is the MOST operationally efficient solution that meets these requirements?",
      "1": "Configure Amazon CloudWatch Logs to save the error messages to a separate log stream.",
      "2": "Create a new SQS queue. Set the new queue as a dead-letter queue for the application queue. Configure the Maximum Receives setting.",
      "3": "Change the SQS queue to a FIFO queue. Configure the message retention period to 0 seconds.",
      "4": "Configure an Amazon CloudWatch alarm for Lambda function errors. Publish messages to an Amazon Simple Notification Service (Amazon SNS) topic to notify administrator users.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "135",
      "question": "A company has a website that is developed in PHP and is launched using AWS Elastic Beanstalk. There is a new version of the website that needs to be deployed in the Elastic Beanstalk environment. The company cannot tolerate having the website offline if an update fails. Deployments must have minimal impact and rollback as soon as possible.\n\nWhat deployment method should be used?",
      "1": "All at once",
      "2": "Rolling",
      "3": "Snapshots",
      "4": "Immutable",
      "5":1,
      "answer": "D"
    },
    {
      "no": "136",
      "question": "An application running on multiple Amazon EC2 instances pulls messages from a standard Amazon SQS queue. A requirement for the application is that all messages must be encrypted at rest. Developers are instructed to use methods that allow for centralized key management and minimize possible support requirements whenever possible.\n\nWhich of the following solutions supports these requirements?",
      "1": "Encrypt individual messages by using client-side encryption with customer managed keys, then write to the SQS queue.",
      "2": "Encrypt individual messages by using SQS Extended Client and the Amazon S3 encryption client.",
      "3": "Create an SQS queue, and encrypt the queue by using server-side encryption with AWS KMS.",
      "4": "Create an SQS queue, and encrypt the queue by using client-side encryption.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "137",
      "question": "A company is running a web application that is using Amazon Cognito for authentication. The company does not want to use multi-factor authentication (MFA) for all the visitors every time, but the company's security team has concerns about compromised credentials. The development team needs to configure mandatory MFA only when suspicious sign-in attempts are detected. \n\nWhich Amazon Cognito feature will meet these requirements?",
      "1": "Short message service (SMS) text message MFA",
      "2": "Advanced security metrics",
      "3": "Time-based one-time password (TOTP) software token MFA",
      "4": "Adaptive authentication",
      "5":1,
      "answer": "D"
    },
    {
      "no": "138",
      "question": "A company has a two-tier application running on an Amazon EC2 server that handles all of its AWS based e-commerce activity. During peak times, the backend servers that process orders are overloaded with requests. This results in some orders failing to process. A developer needs to create a solution that will re-factor the application.\n\nWhich steps will allow for more flexibility during peak times, while still remaining cost-effective? (Choose two.)",
      "1": "Increase the backend T2 EC2 instance sizes to x1 to handle the largest possible load throughout the year.",
      "2": "Implement an Amazon SQS queue to decouple the front-end and backend servers.",
      "3": "Use an Amazon SNS queue to decouple the front-end and backend servers.",
      "4": "Migrate the backend servers to on-premises and pull from an Amazon SNS queue.",
      "5":"Modify the backend servers to pull from an Amazon SQS queue.",
      "answer": "BE"
    },

    {
      "no": "139",
      "question": "A developer is creating an AWS Lambda function that generates a new file each time it runs. Each new file must be checked into an AWS CodeCommit repository hosted in the same AWS account.\n\nHow should the developer accomplish this?",
      "1":"When the Lambda function starts, use the Git CLI to clone the repository. Check the new file into the cloned repository and push the change.",
      "2": "After the new file is created in Lambda, use cURL to invoke the CodeCommit API. Send the file to the repository.",
      "3": "Use an AWS SDK to instantiate a CodeCommit client. Invoke the putjile method to add the file to the repository.",
      "4": "Upload the new file to an Amazon S3 bucket. Create an AWS Step Function to accept S3 events. In the Step Function, add the new file to the repository.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "140",
      "question": "A developer is designing an Amazon DynamoDB table for an application. The application will store user information that includes a unique identifier and an email address for each user. The application must be able to query the table by using either the unique identifier or the email address.\n\nHow should the developer design the DynamoDB table to meet these requirements?",
      "1":"For the primary key of the table, specify the unique identifier as the partition key and specify the email address as the sort key.",
      "2": "For the primary key of the table, specify the unique identifier as the partition key. Create a local secondary index (LSI) based on the email address.",
      "3": "For the primary key of the table, specify the email address as the partition key and specify the unique identifier as the sort key.",
      "4": "For the primary key of the table, specify the unique identifier as the partition key. Create a global secondary index (GSI) based on the email address.",
      "5":1,
      "answer": "D"
    },

    {
      "no": "141",
      "question": "A developer has an application that asynchronously invokes an AWS Lambda function. The developer wants to store messages that resulted in failed invocations of the Lambda function so that the application can retry the call later.\n\nWhat should the developer do to accomplish this goal with the LEAST operational overhead?",
      "1":"Set up Amazon CloudWatch Logs log groups to filter and store the messages in an Amazon S3 bucket. Import the messages in Lambda. Run the Lambda function again.",
      "2": "Configure Amazon EventBridge (Amazon CloudWatch Events) to send the messages to Amazon Simple Notification Service (Amazon SNS) to initiate the Lambda function again.",
      "3": "Implement a dead-letter queue for discarded messages. Set the dead-letter queue as an event source for the Lambda function.",
      "4": "Send Amazon EventBridge (Amazon CloudWatch Events) events to an Amazon Simple Queue Service (Amazon SQS) queue. Configure the Lambda function to pull messages from the SQS queue. Run the Lambda function again.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "142",
      "question": "A developer is writing an application in Python. The application runs on AWS Lambda. The application generates a file and needs to upload this file to Amazon S3.\n\nThe developer must implement this upload functionality with the least possible change to the application code.\n\nWhich solution meets these requirements?",
      "1":"Make an HTTP request directly to the S3 API to upload the file.",
      "2": "Include the AWS SDK for Python in the Lambda function. Use the SDK to upload the file.",
      "3": "Use the AWS SDK for Python that is installed in the Lambda environment to upload the file.",
      "4": "Use the AWS CLI that is installed in the Lambda environment to upload the file.",
      "5":1,
      "answer": "C"
    },
    {
      "no": "143",
      "question": "An application that is hosted on an Amazon EC2 instance needs access to files that are stored in an Amazon S3 bucket. The application lists the objects that are stored in the S3 bucket and displays a table to the user. During testing, a developer discovers that the application does not show any objects in the list.\n\nWhat is the MOST secure way to resolve this issue?",
      "1":"Update the IAM instance profile that is attached to the EC2 instance to include the S3:' permission for the S3 bucket.",
      "2": "Update the IAM instance profile that is attached to the EC2 instance to include the S3:ListBucket permission for the S3 bucket.",
      "3": "Update the developer's user permissions to include the S3:ListBucket permission for the S3 bucket.",
      "4": "Update the S3 bucket policy by including the S3:ListBucket permission and by setting the Principal element to specify the account number of the EC2 instance.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "144",
      "question": "A developer needs to implement a cache to store data that an application frequently queries from an Amazon RDS for MySQL database. The data structures that will be cached include sets and sorted sets. How should the developer implement the cache to achieve the LOWEST latency?",
      "1":"Create an Amazon ElastiCache for Memcached instance. Serialize the data as JSON before caching the data.",
      "2": "Create an Amazon ElastiCache for Redis instance. Use a Redis client library to cache the data.",
      "3": "Create an Amazon DynamoDB table. Serialize the data as JSON before caching the data.",
      "4": "Create an Amazon ElastiCache for Memcached instance. Use a Memcached client library to cache the data.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "145",
      "question": "A developer creates an AWS Lambda function to publish a message to an Amazon Simple Notification Service (Amazon SNS) topic. All message content must be encrypted in transit and at rest between Lambda and Amazon SNS.\n\nA part of the Lambda execution role is as follows:\n\"Effect\" : \"Allow\",\n \"Action\" : \"SNS:Publish\" \n \"Resource\" : \"arn:aws:sns:us-east-1:1234567890:secure-topic\"\n\nWhich combination of steps should the developer take to meet these requirements? (Choose two.)",
      "1":"Enable server-side encryption on the SNS topic.",
      "2": "Add a Deny statement to the Lambda execution role. Specify the SNS topic ARN as the resource. Specify \"aws:SecureTransport\": \"true\" as the condition",
      "3": "Create a VPC endpoint for Amazon SNS.",
      "4": "Add a StringEquals condition of \"sns:Protocol\": \"https\" to the Lambda execution role.",
      "5":"Add a Deny statement to the Lambda execution role. Specify the SNS topic ARN as the resource. Specify \"aws:SecureTransport\": \"false\" as the condition.",
      "answer": "AB"
    },
    {
      "no": "146",
      "question": "A developer is using an AWS Key Management Service (AWS KMS) customer master key (CMK) with imported key material to encrypt data in Amazon S3. The developer accidentally deletes the key material of the CMK and is unable to decrypt the data.\n\nHow can the developer decrypt the data that was encrypted by the CMK?",
      "1":"Request support from AWS to recover the deleted key material.",
      "2": "Create a new CMK. Use the new CMK to decrypt the data.",
      "3": "Use the CMK without the key material.",
      "4": "Add a StringEquals condition of \"sns:Protocol\": \"https\" to the Lambda execution role.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "147",
      "question": "A developer needs to launch a new Amazon EC2 instance by using the AWS CLI.\n\nWhich AWS CLI command should the developer use to meet this requirement?",
      "1":"aws ec2 bundle-instance",
      "2": "aws ec2 start-instances",
      "3": "aws ec2 confirm-product-instance",
      "4": "aws ec2 run instances",
      "5":1,
      "answer": "D"
    },
    {
      "no": "148",
      "question": "A development team uses AWS Elastic Beanstalk for application deployment. The development team has configured the application version lifecycle policy to limit the number of application versions to 25. However, even with the application version lifecycle policy, the source bundle is deleted from the Amazon S3 source bucket.\n\nWhat should the development team do in the Elastic Beanstalk application version lifecycle settings to retain the source code in the S3 bucket?",
      "1":"Enable versioning on the source bundle S3 bucket.",
      "2": "Disable the S3 bucket lifecycle policy to avoid the archiving of the source bundle.",
      "3": "Update the Elastic Beanstalk application version lifecycle policy to increase the version quota to 50.",
      "4": "Update the Elastic Beanstalk application version lifecycle policy to retain the source bundle in Amazon S3.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "149",
      "question": "A development team is building a new application that will run on Amazon EC2 and use Amazon DynamoDB as a storage layer. The developers all have assigned IAM user accounts in the same IAM group. The developers currently can launch EC2 instances, and they need to be able to launch EC2 instances with an instance role allowing access to Amazon DynamoDB.\n\nWhich AWS IAM changes are needed when creating an instance role to provide this functionality?",
      "1":"Create an IAM permission policy attached to the role that allows access to DynamoDB. Add a trust policy to the role that allows DynamoDB to assume the role. Attach a permissions policy to the development group in AWS IAM that allows developers to use the iam:GetRole and iam:PassRole permissions for the role.",
      "2": "Create an IAM permissions policy attached to the role that allows access to DynamoDAdd a trust policy to the role that allows Amazon EC2 to assume the role. Attach a permissions policy to the development group in AWS IAM that allows developers to use the iam:PassRole permission for the role.",
      "3": "Create an IAM permission policy attached to the role that allows access to Amazon EC2. Add a trust policy to the role that allows DynamoDB to assume the role. Attach a permissions policy to the development group in AWS IAM that allows developers to use the iam:PassRole permission for the role.",
      "4": "Create an IAM permissions policy attached to the role that allows access to DynamoDB. Add a trust policy to the role that allows Amazon EC2 to assume the role. Attach a permissions policy to the development group in AWS IAM that allows developers to use the iam:GetRole permission for the role.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "150",
      "question": "A banking application processes thousands of transactions each second. Each transaction payload must have end-to-end encryption. The application encrypts each transaction locally by using the AWS Key Management Service (AWS KMS) GenerateDataKey operation. A developer is testing the application and receives a ThrottlingException error.\n\nWhich actions are best practices to resolve this error? (Choose two.)",
      "1":"Use the LocalCryptoMatenalsCache feature of the AWS Encryption SDK encryption library.",
      "2": "Call the AWS KMS Encrypt operation directly to allow AWS KMS to encrypt the data.",
      "3": "Create a case in the AWS Support Center to increase the quota for the account.",
      "4": "Use Amazon Simple Queue Service (Amazon SQS) to queue the requests to AWS KMS.",
      "5":"Switch to an AWS KMS custom key store.",
      "answer": "AC"
    },

    {
      "no": "151",
      "question": "A developer has code that is stored in an Amazon S3 bucket. The code must be deployed as an AWS Lambda function across multiple accounts in the same AWS Region as the S3 bucket. An AWS CloudFormation template that runs for each account will deploy the Lambda function.\n\nWhat is the MOST secure way to allow CloudFormation to access the Lambda code in the S3 bucket?",
      "1":"Grant the CloudFormation service role the S3 ListBucket and GetObject permissions. Add a bucket policy to Amazon S3 with the pnncipal of \"AWS\": [account numbers].",
      "2": "Grant the CloudFormation service role the S3 GetObject permission. Add a bucket policy to Amazon S3 with the principal of "*".",
      "3": "Use a service-based link to grant the Lambda function the S3 ListBucket and GetObject permissions by explicitly adding the S3 bucket’s account number in the resource.",
      "4": "Use a service-based link to grant the Lambda function the S3 GetObject permission. Add a resource of "*" to allow access to the S3 bucket.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "152",
      "question": "A company is migrating a legacy application to a serverless application on AWS. The legacy application consists of a set of web services that are exposed by an Amazon API Gateway API. A developer needs to replace the existing implementation of web services with AWS Lambda functions. The developer needs to test a new version of the API that uses the functions in production. The developer must minimize the impact of the testing on the application's users. Which solution will meet these requirements?",
      "1":"Create a beta stage for the new version of the API. Send the updated endpoint to the users.",
      "2": "Create a development stage for the new version of the API. Use a canary deployment.",
      "3": "Create a development stage for the new version of the API. Promote a canary release.",
      "4": "Create a deployment stage. Enable mutual TLS for the new version of the API.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "153",
      "question": "A developer needs to modify an application architecture to meet new functional requirements. Application data is stored in Amazon DynamoDB and processed for analysis in a nightly batch. The system analysts do not want to wait until the next day to view the processed data and have asked to have it available in near-real time.\n\nWhich application architecture pattern would enable the data to be processed as it is received?",
      "1":"Event driven",
      "2": "Client-server driven",
      "3": "Fan-out driven",
      "4": "Schedule driven",
      "5":1,
      "answer": "A"
    },
    {
      "no": "154",
      "question": "A company is migrating a web application from on premises to AWS. The company needs to move session storage from the application code to a shared service as part of the migration. The session storage data must be encrypted at rest.\n\nWhich AWS services meet these requirements? (Choose two.)",
      "1":"Amazon ElastiCache for Redis",
      "2": "Amazon ElastiCache for Memcached",
      "3": "Amazon CloudWatch",
      "4": "AWS CloudTrail",
      "5":"Amazon DynamoDB",
      "answer": "AE"
    },
    {
      "no": "155",
      "question": "A gaming application stores scores for players in an Amazon DynamoDB table that has four attributes user_id, user_name, user_score and user_rank. The users are allowed to update their names only. A user is authenticated by web identity federation.\n\nWhich set of conditions should be added in the policy attached to the role for the dynamodb:PutItem API call?",
      "1":"NA",
      "2": "NA",
      "3": "NA",
      "4": "NA",
      "5":1,
      "answer": "B"
    },
    {
      "no": "156",
      "question": "A company is hosting a workshop for external users and wants to share the reference documents with the external users for 7 days. The company stores the reference documents in an Amazon S3 bucket that the company owns.\n\nWhat is the MOST secure way to share the documents with the external users?",
      "1":"Use S3 presigned URLs to share the documents with the external users. Set an expiration time of 7 days.",
      "2": "Move the documents to an Amazon WorkDocs folder Share the links of the WorkDocs folder with the external users.",
      "3": "Create temporary IAM users that have read-only access to the S3 bucket. Share the access keys with the external users. Expire the credentials after 7 days.",
      "4": "Create a role that has read-only access to the S3 bucket. Share the Amazon Resource Name (ARN) of this role with the external users.",
      "5":1,
      "answer": "A"
    },
    {
      "no": "157",
      "question": "A developer is storing many objects in a single Amazon S3 bucket. The developer needs to optimize the S3 bucket for high request rates.\n\nHow should the developer store the objects to meet this requirement?",
      "1":"Store the objects by using S3 Intelligent-Tiering.",
      "2": "Store the objects at the root of the S3 bucket.",
      "3": "Store the objects by using object key names distributed across multiple prefixes.",
      "4": "Store each object with an object tag named \"prefix\" that contains a unique value.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "158",
      "question": "A company has a serverless application that uses AWS Lambda functions and AWS Systems Manager parameters to store configuration data. The company moves the Lambda functions inside the VPC and into private subnets. The Lambda functions are now producing errors in their attempts to access Systems Manager parameters.\n\nWhich solution will allow the Lambda functions to access Systems Manager parameters inside the VPC?",
      "1":"Configure security groups to allow access to Systems Manager.",
      "2": "Create an interface VPC endpoint for Systems Manager.",
      "3": "Use an Internet gateway from inside the VPC.",
      "4": "Create a gateway VPC endpoint for Systems Manager.",
      "5":1,
      "answer": "B"
    },
    {
      "no": "159",
      "question": "A research company has a website that is used once each day to perform scientific calculations based on inputs that are submitted through a webpage. The calculations are CPU intensive. An AWS Lambda function performs the calculations once each day. Users occasionally receive errors because of Lambda function timeouts.\n\nWhich change will reduce the Lambda function's runtime duration?",
      "1":"Configure Lambda to run the function on an Amazon EC2 burstable instance type.",
      "2": "Configure Lambda to run the function on an Amazon EC2 instance type that is recommended for high performance computing (HPC) workloads.",
      "3": "Configure Lambda to run the function with a larger reserved concurrency value.",
      "4": "Configure Lambda to run the function with a larger memory value.",
      "5":1,
      "answer": "D"
    },
    {
      "no": "160",
      "question": "A developer is creating an application. New users of the application must be able to create an account and register by using their own social media accounts. Which AWS service or resource should the developer use to meet these requirements?",
      "1":"IAM role",
      "2": "Amazon Cognito identity pools",
      "3": "Amazon Cognito user pools",
      "4": "AWS Directory Service",
      "5":1,
      "answer": "C"
    },

      {
        "1": "All at once",
        "2": "Rolling with additional batch",
        "3": "Blue/green",
        "4": "Immutable",
        "5": 1,
        "no": "161",
        "question": "A developer wants to deploy a new version of an AWS Elastic Beanstalk application. During deployment, the application must maintain full capacity and avoid service interruption. Additionally, the developer must minimize the cost of additional resources that support the deployment.Which deployment method should the developer use to meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Create sample events based on the Lambda documentation. Create automated test scripts that use the cdk local invoke command to invoke the Lambda functions. Check the response. Document the test scripts for the other developers on the team. Update the CI/CD pipeline to run the test scripts.",
        "2": "Install a unit testing framework that reproduces the Lambda execution environment. Create sample events based on the Lambda documentation. Invoke the handler function by using a unit testing framework. Check the response. Document how to run the unit testing framework for the other developers on the team. Update the CI/CD pipeline to run the unit testing framework.",
        "3": "Install the AWS Serverless Application Model (AWS SAM) CLI tool. Use the sam local generate-event command to generate sample events for the automated tests. Create automated test scripts that use the sam local invoke command to invoke the Lambda functions. Check the response. Document the test scripts for the other developers on the team. Update the CI/CD pipeline to run the test scripts.",
        "4": "Create sample events based on the Lambda documentation. Create a Docker container from the Node.js base image to invoke the Lambda functions. Check the response. Document how to run the Docker container for the other developers on the team. Update the CI/CD pipeline to run the Docker container.",
        "5": 1,
        "no": "162",
        "question": "A developer has observed an increase in bugs in the AWS Lambda functions that a development team has deployed in its Node.js application. To minimize these bugs, the developer wants to implement automated testing of Lambda functions in an environment that closely simulates the Lambda environment.The developer needs to give other developers the ability to run the tests locally. The developer also needs to integrate the tests into the team’s continuous integration and continuous delivery (CI/CD) pipeline before the AWS Cloud Development Kit (AWS CDK) deployment.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Modify the IAM policy resource to be “arn:aws:dynamodb:us-west-2:account-id:table/*”.",
        "2": "Modify the IAM policy to include the dynamodb:* action.",
        "3": "Create a trust policy that specifies the EC2 service principal. Associate the role with the policy.",
        "4": "Create a trust relationship between the role and dynamodb.amazonaws.com.",
        "5": 1,
        "no": "163",
        "question": "A developer is troubleshooting an application that uses Amazon DynamoDB in the us-west-2 Region. The application is deployed to an Amazon EC2 instance. The application requires read-only permissions to a table that is named Cars. The EC2 instance has an attached IAM role that contains the following IAM policy:When the application tries to read from the Cars table, an Access Denied error occurs.How can the developer resolve this error?",
        "answer": "D"
      },
      {
        "1": "The developer must manually keep track of the data encryption keys used for each data object.",
        "2": "The SDK encrypts the data encryption key and stores it (encrypted) as part of the returned ciphertext.",
        "3": "The SDK stores the data encryption keys automatically in Amazon S3.",
        "4": "The data encryption key is stored in the Userdata for the EC2 instance.",
        "5": 1,
        "no": "164",
        "question": "When using the AWS Encryption SDK, how does the developer keep track of the data encryption keys used to encrypt data?",
        "answer": "C"
      },
      {
        "1": "Hardcode the credentials that are required to access the S3 objects in the application code. Use the credentials to access the required S3 objects.",
        "2": "Create a secret access key and access key ID with permission to access the S3 bucket. Store the key and key ID in AWS Secrets Manager. Configure the application to retrieve the Secrets Manager secret and use the credentials to access the S3 objects.",
        "3": "Create a Lambda function execution role. Attach a policy to the role that grants access to specific objects in the S3 bucket.",
        "4": "Create a secret access key and access key ID with permission to access the S3 bucket. Store the key and key ID as environment variables in Lambda. Use the environment variables to access the required S3 objects.",
        "5": 1,
        "no": "165",
        "question": "An application that runs on AWS Lambda requires access to specific highly confidential objects in an Amazon S3 bucket. In accordance with the principle of least privilege, a company grants access to the S3 bucket by using only temporary credentials.How can a developer configure access to the S3 bucket in the MOST secure way?",
        "answer": "D"
      },
      {
        "1": "Grant the CloudFormation service role the S3 ListBucket and GetObject permissions. Add a bucket policy to Amazon S3 with the principal of “AWS”: [account numbers].",
        "2": "Grant the CloudFormation service role the S3 GetObject permission. Add a bucket policy to Amazon S3 with the principal of “*”.",
        "3": "Use a service-based link to grant the Lambda function the S3 ListBucket and GetObject permissions by explicitly adding the S3 bucket’s account number in the resource.",
        "4": "Use a service-based link to grant the Lambda function the S3 GetObject permission. Add a resource of “*” to allow access to the S3 bucket.",
        "5": 1,
        "no": "166",
        "question": "A developer has code that is stored in an Amazon S3 bucket. The code must be deployed as an AWS Lambda function across multiple accounts in the same AWS Region as the S3 bucket. An AWS CloudFormation template that runs for each account will deploy the Lambda function.What is the MOST secure way to allow CloudFormation to access the Lambda code in the S3 bucket?",
        "answer": "A"
      },
      {
        "1": "Use a Kubernetes cron job that runs on Amazon Elastic Kubernetes Service (Amazon EKS).",
        "2": "Use an Amazon Linux crontab scheduled job that runs on Amazon EC2.",
        "3": "Use an AWS Lambda function that is invoked by an Amazon EventBridge scheduled event.",
        "4": "Use an AWS Batch job that is submitted to an AWS Batch job queue.",
        "5": 1,
        "no": "167",
        "question": "A developer at a company needs to create a small application that makes the same API call once each day at a designated time. The company does not have infrastructure in the AWS Cloud yet, but the company wants to implement this functionality on AWS.Which solution meets these requirements in the MOST operationally efficient manner?",
        "answer": "C"
      },
      {
        "1": "Improves legibility and stylistic convention",
        "2": "Takes advantage of runtime environment reuse",
        "3": "Provides better error handling",
        "4": "Creates a new SDK instance for each invocation",
        "5": 1,
        "no": "168",
        "question": "A developer is building a serverless application that is based on AWS Lambda. The developer initializes the AWS software development kit (SDK) outside of the Lambda handler function.What is the PRIMARY benefit of this action?",
        "answer": "B"
      },
      {
        "1": "Amazon CloudFront",
        "2": "Amazon ElastiCache for Memcached",
        "3": "Amazon ElastiCache for Redis in cluster mode",
        "4": "Amazon DynamoDB Accelerator (DAX)",
        "5": 1,
        "no": "169",
        "question": "A company is using Amazon RDS as the backend database for its application. After a recent marketing campaign, a surge of read requests to the database increased the latency of data retrieval from the database. The company has decided to implement a caching layer in front of the database. The cached content must be encrypted and must be highly available.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Change the API Gateway route to add an X-Amz-Invocation-Type header with a static value of ‘Event’ in the integration request. Deploy the API Gateway stage to apply the changes.",
        "2": "Change the configuration of the Lambda function that implements the request to process a file. Configure the maximum age of the event so that the Lambda function will run asynchronously.",
        "3": "Change the API Gateway timeout value to match the Lambda function timeout value. Deploy the API Gateway stage to apply the changes.",
        "4": "Change the API Gateway route to add an X-Amz-Target header with a static value of ‘Async’ in the integration request. Deploy the API Gateway stage to apply the changes.",
        "5": 1,
        "no": "170",
        "question": "A developer at a company recently created a serverless application to process and show data from business reports. The application’s user interface (UI) allows users to select and start processing the files. The UI displays a message when the result is available to view. The application uses AWS Step Functions with AWS Lambda functions to process the files. The developer used Amazon API Gateway and Lambda functions to create an API to support the UI.The company’s UI team reports that the request to process a file is often returning timeout errors because of the size or complexity of the files. The UI team wants the API to provide an immediate response so that the UI can display a message while the files are being processed. The backend process that is invoked by the API needs to send an email message when the report processing is complete.What should the developer do to configure the API to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Define a maintenance window for the Lambda functions to ensure that the functions get updated copies of the dependencies.",
        "2": "Upgrade the Lambda functions to the most recent runtime version.",
        "3": "Define a Lambda layer that contains all of the shared dependencies.",
        "4": "Use an AWS CodeCommit repository to host the dependencies in a centralized location.",
        "5": 1,
        "no": "171",
        "question": "A developer has an application that is composed of many different AWS Lambda functions. The Lambda functions all use some of the same dependencies. To avoid security issues, the developer is constantly updating the dependencies of all of the Lambda functions. The result is duplicated effort for each function.How can the developer keep the dependencies of the Lambda functions up to date with the LEAST additional complexity?",
        "answer": "C"
      },
      {
        "1": "For each item, add a new attribute of type String that has a timestamp that is set to the blog post creation time. Create a script to find old posts with a table scan and remove posts that are older than 48 hours by using the BatchWriteItem API operation. Schedule a cron job on an Amazon EC2 instance once an hour to start the script.",
        "2": "For each item, add a new attribute of type String that has a timestamp that is set to the blog post creation time. Create a script to find old posts with a table scan and remove posts that are older than 48 hours by using the BatchWriteItem API operation. Place the script in a container image. Schedule an Amazon Elastic Container Service (Amazon ECS) task on AWS Fargate that invokes the container every 5 minutes.",
        "3": "For each item, add a new attribute of type Date that has a timestamp that is set to 48 hours after the blog post creation time. Create a global secondary index (GSI) that uses the new attribute as a sort key. Create an AWS Lambda function that references the GSI and removes expired items by using the BatchWriteItem API operation. Schedule the function with an Amazon CloudWatch event every minute.",
        "4": "For each item, add a new attribute of type Number that has a timestamp that is set to 48 hours after the blog post creation time. Configure the DynamoDB table with a TTL that references the new attribute.",
        "5": 1,
        "no": "172",
        "question": "A mobile app stores blog posts in an Amazon DynamoDB table. Millions of posts are added every day, and each post represents a single item in the table. The mobile app requires only recent posts. Any post that is older than 48 hours can be removed.What is the MOST cost-effective way to delete posts that are older than 48 hours?",
        "answer": "B"
      },
      {
        "1": "Create an RDS database secret in AWS Secrets Manager. Set the user name, password, database, host, and port. Turn on secret rotation. Create encrypted Lambda environment variables for the DynamoDB table, S3 bucket, and SNS topic.",
        "2": "Create an RDS database secret in AWS Secrets Manager. Set the user name, password, database, host, and port. Turn on secret rotation. Create SecureString parameters in AWS Systems Manager Parameter Store for the DynamoDB table, S3 bucket, and SNS topic.",
        "3": "Create RDS database parameters in AWS Systems Manager Parameter Store for the user name, password, database, host, and port. Create encrypted Lambda environment variables for the DynamoDB table, S3 bucket, and SNS topic. Create a Lambda function and set the logic for the credentials rotation task. Schedule the credentials rotation task in Amazon EventBridge.",
        "4": "Create RDS database parameters in AWS Systems Manager Parameter Store for the user name, password, database, host, and port. Store the DynamoDB table, S3 bucket, and SNS topic in Amazon S3. Create a Lambda function and set the logic for the credentials rotation. Invoke the Lambda function on a schedule.",
        "5": 1,
        "no": "173",
        "question": "A developer is modifying an existing AWS Lambda function. While checking the code, the developer notices hardcoded parameter values for an Amazon RDS for SQL Server user name, password, database, host, and port. There are also hardcoded parameter values for an Amazon DynamoDB table, an Amazon S3 bucket, and an Amazon Simple Notification Service (Amazon SNS) topic.The developer wants to securely store the parameter values outside the code in an encrypted format and wants to turn on rotation for the credentials. The developer also wants to be able to reuse the parameter values from other applications and to update the parameter values without modifying code.Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "B"
      },
      {
        "1": "\"codecommit:CreateBranch\"\"codecommit:DeleteBranch\"",
        "2": "\"codecommit:Put*\"",
        "3": "\"codecommit:Update*\"",
        "4": "\"codecommit:*\"",
        "5": 1,
        "no": "174",
        "question": "A developer accesses AWS CodeCommit over SSH. The SSH keys configured to access AWS CodeCommit are tied to a user with the following permissions:The developer needs to create/delete branches.Which specific IAM permissions need to be added, based on the principle of least privilege?",
        "answer": "B"
      },
      {
        "1": "Modify the application code to perform exponential backoff when the error is received.",
        "2": "Modify the application to use the AWS SDKs for DynamoDB.",
        "3": "Increase the read and write throughput of the DynamoDB table.",
        "4": "Create a DynamoDB Accelerator (DAX) cluster for the DynamoDB table.",
        "5": "Create a second DynamoDB table. Distribute the reads and writes between the two tables.",
        "no": "175",
        "question": "An application that is deployed to Amazon EC2 is using Amazon DynamoDB. The application calls the DynamoDB REST API. Periodically, the application receives a ProvisionedThroughputExceededException error when the application writes to a DynamoDB table.Which solutions will mitigate this error MOST cost-effectively? (Choose two.)",
        "answer": "AB"
      },
      {
        "1": "Add the export LC_ALL=\"en_US.utf8\" command to the pre_build section to ensure POSIX localization.",
        "2": "Use Amazon Cognito to store key-value pairs for large numbers of environment variables.",
        "3": "Update the settings for the build project to use an Amazon S3 bucket for large numbers of environment variables.",
        "4": "Use AWS Systems Manager Parameter Store to store large numbers of environment variables.",
        "5": 1,
        "no": "176",
        "question": "When a developer tries to run an AWS CodeBuild project, it raises an error because the length of all environment variables exceeds the limit for the combined maximum of characters.What is the recommended solution?",
        "answer": "D"
      },
      {
        "1": "Use S3 Batch Operations to invoke an AWS Lambda function to create new variants of the photos with the required dimensions and resolutions. Create a dynamic CloudFront origin that automatically maps the request of each device to the corresponding photo variant.",
        "2": "Use S3 Batch Operations to invoke an AWS Lambda function to create new variants of the photos with the required dimensions and resolutions. Create a Lambda@Edge function to route requests to the corresponding photo variant by using request headers.",
        "3": "Create a Lambda@Edge function that optimizes the photos upon request and returns the photos as a response. Change the CloudFront TTL cache policy to the maximum value possible.",
        "4": "Create a Lambda@Edge function that optimizes the photos upon request and returns the photos as a response. In the same function, store a copy of the processed photos on Amazon S3 for subsequent requests.",
        "5": 1,
        "no": "177",
        "question": "A company is expanding the compatibility of its photo-sharing mobile app to hundreds of additional devices with unique screen dimensions and resolutions. Photos are stored in Amazon S3 in their original format and resolution. The company uses an Amazon CloudFront distribution to serve the photos. The app includes the dimension and resolution of the display as GET parameters with every request.A developer needs to implement a solution that optimizes the photos that are served to each device to reduce load time and increase photo quality.Which solution will meet these requirements MOST cost-effectively?",
        "answer": "D"
      },
      {
        "1": "Add local secondary indexes (LSIs) for the trading data.",
        "2": "Store the trading data in Amazon S3, and use S3 Transfer Acceleration.",
        "3": "Add retries with exponential backoff for DynamoDB queries.",
        "4": "Use DynamoDB Accelerator (DAX) to cache the trading data.",
        "5": 1,
        "no": "178",
        "question": "A company is building an application for stock trading. The application needs sub-millisecond latency for processing trade requests. The company uses Amazon DynamoDB to store all the trading data that is used to process each trading request.A development team performs load testing on the application and finds that the data retrieval time is higher than expected. The development team needs a solution that reduces the data retrieval time with the least possible effort.Which solution meets these requirements?",
        "answer": "D"
      },
      {
        "1": "Install the Amazon CloudWatch agent on the EC2 instances.",
        "2": "Install the AWS X-Ray daemon on the EC2 instances.",
        "3": "Configure the application to write JSON-formatted logs to /var/log/cloudwatch.",
        "4": "Configure the application to write trace data to /var/log/xray.",
        "5": "Install and configure the AWS X-Ray SDK for Python in the application.",
        "no": "179",
        "question": "A developer is working on a Python application that runs on Amazon EC2 instances. The developer wants to enable tracing of application requests to debug performance issues in the code.Which combination of actions should the developer take to achieve this goal? (Choose two.)",
        "answer": "CE"
      },
      {
        "1": "Use IAM database authentication for Aurora to enable secure database connections for all the Lambda functions.",
        "2": "Store the credentials and read the credentials from an encrypted Amazon RDS DB instance.",
        "3": "Store the credentials in AWS Systems Manager Parameter Store as a secure string parameter.",
        "4": "Use Lambda environment variables with a shared AWS Key Management Service (AWS KMS) key for encryption.",
        "5": 1,
        "no": "180",
        "question": "A company has an application that runs as a series of AWS Lambda functions. Each Lambda function receives data from an Amazon Simple Notification Service (Amazon SNS) topic and writes the data to an Amazon Aurora DB instance.To comply with an information security policy, the company must ensure that the Lambda functions all use a single securely encrypted database connection string to access Aurora.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Create an Amazon Kinesis Data Firehose delivery stream to receive API call logs from API Gateway. Configure Amazon CloudWatch Logs as the delivery stream’s destination.",
        "2": "Turn on AWS CloudTrail Insights and create a trail. Specify the Amazon Resource Name (ARN) of the trail for the stage of the API.",
        "3": "Turn on AWS X-Ray for the API stage. Create an Amazon CloudWatch Logs log group. Specify the Amazon Resource Name (ARN) of the log group for the API stage.",
        "4": "Turn on execution logging and access logging in Amazon CloudWatch Logs for the API stage. Create a CloudWatch Logs log group. Specify the Amazon Resource Name (ARN) of the log group for the API stage.",
        "5": 1,
        "no": "181",
        "question": "A developer is troubleshooting an Amazon API Gateway API. Clients are receiving HTTP 400 response errors when the clients try to access an endpoint of the API.How can the developer determine the cause of these errors?",
        "answer": "A"
      },
      {
        "1": "Configure the CloudFront cache. Update the application to return cached content based upon the default request headers.",
        "2": "Override the cache method in the selected stage of API Gateway. Select the POST method.",
        "3": "Save the latest request response in Lambda /tmp directory. Update the Lambda function to check the /tmp directory.",
        "4": "Save the latest request in AWS Systems Manager Parameter Store. Modify the Lambda function to take the latest request response from Parameter Store.",
        "5": 1,
        "no": "182",
        "question": "A company developed an API application on AWS by using Amazon CloudFront, Amazon API Gateway, and AWS Lambda. The API has a minimum of four requests every second. A developer notices that many API users run the same query by using the POST method. The developer wants to cache the POST request to optimize the API resources.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Use AWS SAM CLI commands in AWS CodeDeploy to invoke the Lambda functions to test the deployment.",
        "2": "Declare the EventInvokeConfig on the Lambda functions in the AWS SAM templates with OnSuccess and OnFailure configurations.",
        "3": "Enable gradual deployments through AWS SAM templates.",
        "4": "Set the deployment preference type to Canary10Percent30Minutes. Use hooks to test the deployment.",
        "5": "Set the deployment preference type to Linear10PercentEvery10Minutes. Use hooks to test the deployment.",
        "no": "183",
        "question": "A company is building a microservices application that consists of many AWS Lambda functions. The development team wants to use AWS Serverless Application Model (AWS SAM) templates to automatically test the Lambda functions. The development team plans to test a small percentage of traffic that is directed to new updates before the team commits to a full deployment of the application.Which combination of steps will meet these requirements in the MOST operationally efficient way? (Choose two.)",
        "answer": "BD"
      },
      {
        "1": "Use an AWS Lambda function as a CloudFormation custom resource to generate and rotate the password.",
        "2": "Use an AWS Systems Manager Parameter Store resource with the SecureString data type to generate and rotate the password.",
        "3": "Use a cron daemon on the application’s host to generate and rotate the password.",
        "4": "Use an AWS Secrets Manager resource to generate and rotate the password.",
        "5": 1,
        "no": "184",
        "question": "A company is using AWS CloudFormation to deploy a two-tier application. The application will use Amazon RDS as its backend database. The company wants a solution that will randomly generate the database password during deployment. The solution also must automatically rotate the database password without requiring changes to the application.What is the MOST operationally efficient solution that meets these requirements?",
        "answer": "D"
      },
      {
        "1": "Change the StreamViewType parameter value to NEW_AND_OLD_IMAGES for the DynamoDB table.",
        "2": "Configure event source mapping for the Lambda function.",
        "3": "Map an Amazon Simple Notification Service (Amazon SNS) topic to the DynamoDB streams.",
        "4": "Increase the maximum runtime (timeout) setting of the Lambda function.",
        "5": 1,
        "no": "185",
        "question": "A developer has been asked to create an AWS Lambda function that is invoked any time updates are made to items in an Amazon DynamoDB table. The function has been created, and appropriate permissions have been added to the Lambda execution role. Amazon DynamoDB streams have been enabled for the table, but the function is still not being invoked.Which option would enable DynamoDB table updates to invoke the Lambda function?",
        "answer": "B"
      },
      {
        "1": "Define an array that includes the environment variables under the environment parameter within the service definition.",
        "2": "Define an array that includes the environment variables under the environment parameter within the task definition.",
        "3": "Define an array that includes the environment variables under the entryPoint parameter within the task definition.",
        "4": "Define an array that includes the environment variables under the entryPoint parameter within the service definition.",
        "5": 1,
        "no": "186",
        "question": "A developer needs to deploy an application running on AWS Fargate using Amazon ECS. The application has environment variables that must be passed to a container for the application to initialize.How should the environment variables be passed to the container?",
        "answer": "A"
      },
      {
        "1": "Add a CloudFormation DeletionPolicy attribute with the Retain value to the database resource.",
        "2": "Update the CloudFormation stack policy to prevent updates to the database.",
        "3": "Modify the database to use a Multi-AZ deployment.",
        "4": "Create a CloudFormation stack set for the web application and database deployments.",
        "5": "Add a CloudFormation DeletionPolicy attribute with the Retain value to the stack.",
        "no": "187",
        "question": "A development team maintains a web application by using a single AWS RDS, template. The template defines web servers and an Amazon RDS database. The team uses the CloudFormation template to deploy the CloudFormation stack to different environments.During a recent application deployment, a developer caused the primary development database to be dropped and recreated. The result of this incident was a loss of data. The team needs to avoid accidental database deletion in the future.Which solutions will meet these requirements? (Choose two.)",
        "answer": "AB"
      },
      {
        "1": "Server-side encryption with Amazon S3 managed keys (SSE-S3)",
        "2": "Server-side encryption with AWS KMS managed keys (SSE-KMS)",
        "3": "Server-side encryption with customer-provided keys (SSE-C)",
        "4": "Server-side encryption with self-managed keys",
        "5": 1,
        "no": "188",
        "question": "A developer is storing sensitive data generated by an application in Amazon S3. The developer wants to encrypt the data at rest. A company policy requires an audit trail of when the AWS Key Management Service (AWS KMS) key was used and by whom.Which encryption option will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "A global secondary index (GSI) with Product ID as the partition key and Product Rating as the sort key",
        "2": "A global secondary index (GSI) with Product ID as the partition key and Review ID as the sort key",
        "3": "A local secondary index (LSI) with Product ID as the partition key and Product Rating as the sort key",
        "4": "A local secondary index (LSI) with Review ID as the partition key and Product ID as the sort key",
        "5": 1,
        "no": "189",
        "question": "A company has an ecommerce application. To track product reviews, the company’s development team uses an Amazon DynamoDB table.Every record includes the following:•\tA Review ID, a 16-digit universally unique identifier (UUID)•\tA Product ID and User ID, 16-digit UUIDs that reference other tables•\tA Product Rating on a scale of 1-5•\tAn optional comment from the userThe table partition key is the Review ID. The most performed query against the table is to find the 10 reviews with the highest rating for a given product.Which index will provide the FASTEST response for this query?",
        "answer": "B"
      },
      {
        "1": "Use Amazon CloudFront with signed URLs for Amazon S3.",
        "2": "Create a dedicated Amazon CloudFront Distribution for each customer.",
        "3": "Use Amazon CloudFront with AWS Lambda@Edge.",
        "4": "Use Amazon API Gateway and AWS Lambda to control access to an S3 bucket.",
        "5": 1,
        "no": "190",
        "question": "A company needs to distribute firmware updates to its customers around the world.Which service will allow easy and secure control of the access to the downloads at the lowest cost?",
        "answer": "A"
      },
      {
        "1": "Configure AWS CloudTrail logging to investigate the invocation failures.",
        "2": "Configure Dead Letter Queues by sending events to Amazon SQS for investigation.",
        "3": "Configure Amazon Simple Workflow Service to process any direct unprocessed events.",
        "4": "Configure AWS Config to process any direct unprocessed events.",
        "5": 1,
        "no": "191",
        "question": "A developer is testing an application that invokes an AWS Lambda function asynchronously. During the testing phase, the Lambda function fails to process after two retries.How can the developer troubleshoot the failure?",
        "answer": "B"
      },
      {
        "1": "Use Amazon Aurora PostgreSQL for the database. Store the database credentials in AWS Systems Manager Parameter Store. Turn on rotation.",
        "2": "Use Amazon Aurora PostgreSQL for the database. Store the database credentials in AWS Secrets Manager. Turn on rotation.",
        "3": "Use Amazon DynamoDB for the database. Store the database credentials in AWS Systems Manager Parameter Store. Turn on rotation.",
        "4": "Use Amazon DynamoDB for the database. Store the database credentials in AWS Secrets Manager. Turn on rotation.",
        "5": 1,
        "no": "192",
        "question": "A company is migrating its PostgreSQL database into the AWS Cloud. The company wants to use a database that will secure and regularly rotate database credentials. The company wants a solution that does not require additional programming overhead.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Use an identity provider to securely authenticate with the application.",
        "2": "Create an AWS Lambda function to create an IAM user when a user accesses the application.",
        "3": "Create credentials using AWS KMS and apply these credentials to users when using the application.",
        "4": "Use Amazon Cognito to associate unauthenticated users with an IAM role that has limited access to resources.",
        "5": 1,
        "no": "193",
        "question": "A developer is creating a mobile application that will not require users to log in.What is the MOST efficient method to grant users access to AWS resources?",
        "answer": "D"
      },
      {
        "1": "Compress the application to a .zip file and upload it into AWS Lambda.",
        "2": "Test the new AWS Lambda function by first tracing it in AWS X-Ray.",
        "3": "Bundle the serverless application using a SAM package.",
        "4": "Create the application environment using the eb create my-env command.",
        "5": 1,
        "no": "194",
        "question": "A company has developed a new serverless application using AWS Lambda functions that will be deployed using the AWS Serverless Application Model (AWS SAM) CLI.Which step should the developer complete prior to deploying the application?",
        "answer": "B"
      },
      {
        "1": "In the central AWS CDK application, write a handler function in the code that uses AWS SDK calls to check for and delete unused resources. Create an AWS CloudFormation template from a JSON file. Use the template to attach the function code to an AWS Lambda function and to invoke the Lambda function when the deployment stack runs.",
        "2": "In the central AWS CDK application, write a handler function in the code that uses AWS SDK calls to check for and delete unused resources. Create an AWS CDK custom resource. Use the custom resource to attach the function code to an AWS Lambda function and to invoke the Lambda function when the deployment stack runs.",
        "3": "In the central AWS CDK, write a handler function in the code that uses AWS SDK calls to check for and delete unused resources. Create an API in AWS Amplify. Use the API to attach the function code to an AWS Lambda function and to invoke the Lambda function when the deployment stack runs.",
        "4": "In the AWS Lambda console, write a handler function in the code that uses AWS SDK calls to check for and delete unused resources. Create an AWS CDK custom resource. Use the custom resource to import the Lambda function into the stack and to invoke the Lambda function when the deployment stack runs.",
        "5": 1,
        "no": "195",
        "question": "A company wants to automate part of its deployment process. A developer needs to automate the process of checking for and deleting unused resources that supported previously deployed stacks but that are no longer used.The company has a central application that uses the AWS Cloud Development Kit (AWS CDK) to manage all deployment stacks. The stacks are spread out across multiple accounts. The developer’s solution must integrate as seamlessly as possible within the current deployment process.Which solution will meet these requirements with the LEAST amount of configuration?",
        "answer": "B"
      },
      {
        "1": "Put the sensitive data into a CloudFormation parameter. Encrypt the CloudFormation templates by using an AWS Key Management Service (AWS KMS) key.",
        "2": "Put the sensitive data into an Amazon S3 bucket. Update the CloudFormation templates to download the object from Amazon S3 during bootstrap.",
        "3": "Put the sensitive data into AWS Systems Manager Parameter Store as a secure string parameter. Update the CloudFormation templates to use dynamic references to specify template values.",
        "4": "Put the sensitive data into Amazon Elastic File System (Amazon EFS). Enforce EFS encryption after file system creation. Update the CloudFormation templates to retrieve data from Amazon EFS.",
        "5": 1,
        "no": "196",
        "question": "A company built a new application in the AWS Cloud. The company automated the bootstrapping of new resources with an Auto Scaling group by using AWS CloudFormation templates. The bootstrap scripts contain sensitive data.The company needs a solution that is integrated with CloudFormation to manage the sensitive data in the bootstrap scripts.Which solution will meet these requirements in the MOST secure way?",
        "answer": "D"
      },
      {
        "1": "Set up IAM database authentication for token-based access. Generate user tokens to provide centralized access to RDS DB instances, Amazon DocumentDB clusters, and Aurora DB instances.",
        "2": "Create parameters for the database credentials in AWS Systems Manager Parameter Store. Set the Type parameter to SecureString. Set up automatic rotation on the parameters.",
        "3": "Store the database access credentials as an encrypted Amazon S3 object in an S3 bucket. Block all public access on the S3 bucket. Use S3 server-side encryption to set up automatic rotation on the encryption key.",
        "4": "Create an AWS Lambda function by using the SecretsManagerRotationTemplate template in the AWS Secrets Manager console. Create secrets for the database credentials in Secrets Manager. Set up secrets rotation on a schedule.",
        "5": 1,
        "no": "197",
        "question": "A company needs to set up secure database credentials for all its AWS Cloud resources. The company’s resources include Amazon RDS DB instances, Amazon DocumentDB clusters, and Amazon Aurora DB instances. The company’s security policy mandates that database credentials be encrypted at rest and rotated at a regular interval.Which solution will meet these requirements MOST securely?",
        "answer": "C"
      },
      {
        "1": "Create a read replica for the DB instance. Query the replica DB instance instead of the primary DB instance.",
        "2": "Migrate the data to an Amazon DynamoDB database.",
        "3": "Configure the Amazon Aurora MySQL DB instance for Multi-AZ deployment.",
        "4": "Create a proxy in Amazon RDS Proxy. Query the proxy instead of the DB instance.",
        "5": 1,
        "no": "198",
        "question": "A developer has created an AWS Lambda function that makes queries to an Amazon Aurora MySQL DB instance. When the developer performs a test, the DB instance shows an error for too many connections.Which solution will meet these requirements with the LEAST operational effort?",
        "answer": "D"
      },
      {
        "1": "Sam local invoke",
        "2": "Sam local generate-event",
        "3": "Sam local start-lambda",
        "4": "Sam local start-api",
        "5": 1,
        "no": "199",
        "question": "A developer is creating a new REST API by using Amazon API Gateway and AWS Lambda. The development team tests the API and validates responses for the known use cases before deploying the API to the production environment.The developer wants to make the REST API available for testing by using API Gateway locally.Which AWS Serverless Application Model Command Line Interface (AWS SAM CLI) subcommand will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Configure routing on the alias of the new function by using a weighted alias.",
        "2": "Configure a canary deployment type for Lambda.",
        "3": "Configure routing on the new versions by using environment variables.",
        "4": "Configure a linear deployment type for Lambda.",
        "5": 1,
        "no": "200",
        "question": "A company has a serverless application on AWS that uses a fleet of AWS Lambda functions that have aliases. The company regularly publishes new Lambda function by using an in-house deployment solution. The company wants to improve the release process and to use traffic shifting. A newly published function version should initially make available only to a fixed percentage of production users.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Use Amazon ElastiCache for Memcached to offload read requests from the main database.",
        "2": "Replicate the data to Amazon DynamoDSet up a DynamoDB Accelerator (DAX) cluster.",
        "3": "Configure the Amazon RDS instances to use Multi-AZ deployment with one standby instance. Offload read requests from the main database to the standby instance.",
        "4": "Use Amazon ElastiCache for Redis to offload read requests from the main database.",
        "5": 1,
        "no": "201",
        "question": "A company has an application that stores data in Amazon RDS instances. The application periodically experiences surges of high traffic that cause performance problems. During periods of peak traffic, a developer notices a reduction in query speed in all database queries.The team’s technical lead determines that a multi-threaded and scalable caching solution should be used to offload the heavy read traffic. The solution needs to improve performance.Which solution will meet these requirements with the LEAST complexity?",
        "answer": "A"
      },
      {
        "1": "Store the API key as a Lambda environment variable by using an AWS Key Management Service (AWS KMS) customer managed key.",
        "2": "Configure the application to prompt the user to provide the password to the Lambda function on the first run.",
        "3": "Store the API key as a value in the application code.",
        "4": "Use Lambda@Edge and only communicate over the HTTPS protocol.",
        "5": 1,
        "no": "202",
        "question": "A developer must provide an API key to an AWS Lambda function to authenticate with a third-party system. The Lambda function will run on a schedule. The developer needs to ensure that the API key remains encrypted at rest.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Use S3 Standard-Infrequent Access (S3 Standard-IA) to store the images. Use S3 Glacier Deep Archive with standard retrieval to store and retrieve archived images.",
        "2": "Use S3 Standard-Infrequent Access (S3 Standard-IA) to store the images. Use S3 Glacier Deep Archive with bulk retrieval to store and retrieve archived images.",
        "3": "Use S3 Intelligent-Tiering to store the images. Use S3 Glacier Deep Archive with standard retrieval to store and retrieve archived images.",
        "4": "Use S3 One Zone-Infrequent Access (S3 One Zone-IA) to store the images. Use S3 Glacier Deep Archive with bulk retrieval to store and retrieve archived images.",
        "5": 1,
        "no": "203",
        "question": "An IT department uses Amazon S3 to store sensitive images. After more than 1 year, the company moves the images into archival storage. The company rarely accesses the images, but the company wants a storage solution that maximizes resiliency. The IT department needs access to the images that have been moved to archival storage within 24 hours.Which solution will meet these requirements MOST cost-effectively?",
        "answer": "D"
      },
      {
        "1": "Add a configuration file in TOML format to group configuration entries to every environment. Add a table for each testing and staging environment. Deploy updates to the environments by using the sam deploy command and the --config-env flag that corresponds to each environment.",
        "2": "Create additional AWS SAM templates for each testing and staging environment. Write a custom shell script that uses the sam deploy command and the --template-file flag to deploy updates to the environments.",
        "3": "Create one AWS SAM configuration file that has default parameters. Perform updates to the testing and staging environments by using the --parameter-overrides flag in the AWS SAM CLI and the parameters that the updates will override.",
        "4": "Use the existing AWS SAM template. Add additional parameters to configure specific attributes for the serverless function and database table resources that are in each environment. Deploy updates to the testing and staging environments by using the sam deploy command.",
        "5": 1,
        "no": "204",
        "question": "A developer is building a serverless application by using the AWS Serverless Application Model (AWS SAM). The developer is currently testing the application in a development environment. When the application is nearly finished, the developer will need to set up additional testing and staging environments for a quality assurance team.The developer wants to use a feature of the AWS SAM to set up deployments to multiple environments.Which solution will meet these requirements with the LEAST development effort?",
        "answer": "B"
      },
      {
        "1": "Add an asynchronous invocation to the Lambda function. Select the S3 bucket as the source.",
        "2": "Add an Amazon EventBridge event to the Lambda function. Select the S3 bucket as the source.",
        "3": "Add a trigger to the Lambda function. Select the S3 bucket as the source.",
        "4": "Add a layer to the Lambda function. Select the S3 bucket as the source.",
        "5": 1,
        "no": "205",
        "question": "A developer is working on an application that processes operating data from IoT devices. Each IoT device uploads a data file once every hour to an Amazon S3 bucket. The developer wants to immediately process each data file when the data file is uploaded to Amazon S3.The developer will use an AWS Lambda function to process the data files from Amazon S3. The Lambda function is configured with the S3 bucket information where the files are uploaded. The developer wants to configure the Lambda function to immediately invoke after each data file is uploaded.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Add an --enable-termination-protection command line option to the create-stack command and the update-stack command.",
        "2": "Add a --disable-rollback command line option to the create-stack command and the update-stack command.",
        "3": "Add a --parameters ParameterKey=PreserveResources,ParameterValue=True command line option to the create-stack command and the update-stack command.",
        "4": "Add a --tags Key=PreserveResources,Value=True command line option to the create-stack command and the update-stack command.",
        "5": 1,
        "no": "206",
        "question": "A developer is setting up infrastructure by using AWS CloudFormation. If an error occurs when the resources described in the Cloud Formation template are provisioned, successfully provisioned resources must be preserved. The developer must provision and update the CloudFormation stack by using the AWS CLI.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Configure provisioned concurrency for each Lambda function by setting the ProvisionedConcurrentExecutions parameter to 10.",
        "2": "Enable cluster cache management for Aurora PostgreSQL. Change the connection string of each Lambda function to point to cluster cache management.",
        "3": "Use Amazon RDS Proxy to create a connection pool to manage the database connections. Change the connection string of each Lambda function to reference the proxy.",
        "4": "Configure reserved concurrency for each Lambda function by setting the ReservedConcurrentExecutions parameter to 10.",
        "5": 1,
        "no": "207",
        "question": "A developer is building a serverless application that connects to an Amazon Aurora PostgreSQL database. The serverless application consists of hundreds of AWS Lambda functions. During every Lambda function scale out, a new database connection is made that increases database resource consumption.The developer needs to decrease the number of connections made to the database. The solution must not impact the scalability of the Lambda functions.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "From the main branch, create a feature branch for production bug fixes. Create a second feature branch from the main branch for development of the new version.",
        "2": "Create a Git tag of the code that is currently deployed in production. Create a Git tag for the development of the new version. Push the two tags to the CodeCommit repository.",
        "3": "From the main branch, create a branch of the code that is currently deployed in production. Apply an IAM policy that ensures no other users can push or merge to the branch.",
        "4": "Create a new CodeCommit repository for development of the new version of the application. Create a Git tag for the development of the new version.",
        "5": 1,
        "no": "208",
        "question": "A developer is preparing to begin development of a new version of an application. The previous version of the application is deployed in a production environment. The developer needs to deploy fixes and updates to the current version during the development of the new version of the application. The code for the new version of the application is stored in AWS CodeCommit.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Specify the CAPABILITY_AUTO_EXPAND capability in the CloudFormation stack.",
        "2": "Use an administrators role to deploy IAM resources with CloudFormation.",
        "3": "Specify the CAPABILITY_IAM capability in the CloudFormation stack.",
        "4": "Specify the CAPABILITY_NAMED_IAM capability in the CloudFormation stack.",
        "5": 1,
        "no": "209",
        "question": "A developer is creating an AWS CloudFormation stack. The stack contains IAM resources with custom names. When the developer tries to deploy the stack, they receive an InsufficientCapabilities error.What should the developer do to resolve this issue?",
        "answer": "B"
      },
      {
        "1": "Ask the customers to use AWS credentials to call the InvalidateCache API operation.",
        "2": "Attach an InvalidateCache policy to the IAM execution role that the customers use to invoke the API. Ask the customers to send a request that contains the Cache-Control:max-age=0 HTTP header when they make an API call.",
        "3": "Ask the customers to use the AWS SDK API Gateway class to invoke the InvalidateCache API operation.",
        "4": "Attach an InvalidateCache policy to the IAM execution role that the customers use to invoke the API. Ask the customers to add the INVALIDATE_CACHE query string parameter when they make an API call.",
        "5": 1,
        "no": "210",
        "question": "A company uses Amazon API Gateway to expose a set of APIs to customers. The APIs have caching enabled in API Gateway. Customers need a way to invalidate the cache for each API when they test the API.What should a developer do to give customers the ability to invalidate the API cache?",
        "answer": "D"
      },
      {
        "1": "Store the files in Amazon Elastic Block Store (Amazon EBS) and delete the files at the end of the Lambda function.",
        "2": "Copy the files to Amazon Elastic File System (Amazon EFS) and delete the files at the end of the Lambda function.",
        "3": "Store the files in the /tmp directory and delete the files at the end of the Lambda function.",
        "4": "Copy the files to an Amazon S3 bucket with a lifecycle policy to delete the files.",
        "5": 1,
        "no": "211",
        "question": "A developer is creating an AWS Lambda function that will generate and export a file. The function requires 100 MB of temporary storage for temporary files while running. These files will not be needed after the function is complete.How can the developer MOST efficiently handle the temporary files?",
        "answer": "A"
      },
      {
        "1": "Grant the Lambda function’s execution role permissions to upload logs to Amazon CloudWatch Logs. Implement a CloudWatch Logs Insights query that selects the number of unique customers for orders with order quantity equal to 0 and groups the results in 1-day periods. Add the CloudWatch Logs Insights query to a CloudWatch dashboard.",
        "2": "Use Amazon Athena to query AWS CloudTrail API logs for API calls. Implement an Athena query that selects the number of unique customers for orders with order quantity equal to 0 and groups the results in 1-day periods. Add the Athena query to an Amazon CloudWatch dashboard.",
        "3": "Configure the Lambda function to send events to Amazon EventBridge. Create an EventBridge rule that groups the number of unique customers for orders with order quantity equal to 0 in 1-day periods. Add a CloudWatch dashboard as the target of the rule.",
        "4": "Turn on custom Amazon CloudWatch metrics for the DynamoDB stream of the DynamoDB table. Create a CloudWatch alarm that groups the number of unique customers for orders with order quantity equal to 0 in 1-day periods. Add the CloudWatch alarm to a CloudWatch dashboard.",
        "5": 1,
        "no": "212",
        "question": "A company uses Amazon DynamoDB as a data store for its order management system. The company frontend application stores orders in a DynamoDB table. The DynamoDB table is configured to send change events to a DynamoDB stream. The company uses an AWS Lambda function to log and process the incoming orders based on data from the DynamoDB stream.An operational review reveals that the order quantity of incoming orders is sometimes set to 0. A developer needs to create a dashboard that will show how many unique customers this problem affects each day.What should the developer do to implement the dashboard?",
        "answer": "D"
      },
      {
        "1": "Check that the function’s security group has outbound access on port 1433 to the DB instance’s security group. Check that the DB instance’s security group has inbound access on port 1433 from the function’s security group.",
        "2": "Check that the function’s security group has inbound access on port 1433 from the DB instance’s security group. Check that the DB instance’s security group has outbound access on port 1433 to the function’s security group.",
        "3": "Check that the VPC is set up for a NAT gateway. Check that the DB instance has the public access option turned on.",
        "4": "Check that the function’s execution role permissions include rds:DescribeDBInstances, rds:ModifyDBInstance. and rds:DescribeDBSecurityGroups for the DB instance.",
        "5": "Check that the function’s execution role permissions include ec2:CreateNetworkInterface, ec2:DescribeNetworkInterfaces, and ec2:DeleteNetworkInterface.",
        "no": "213",
        "question": "A developer needs to troubleshoot an AWS Lambda function in a development environment. The Lambda function is configured in VPC mode and needs to connect to an existing Amazon RDS for SQL Server DB instance. The DB instance is deployed in a private subnet and accepts connections by using port 1433.When the developer tests the function, the function reports an error when it tries to connect to the database.Which combination of steps should the developer take to diagnose this issue? (Choose two.)",
        "answer": "AC"
      },
      {
        "1": "aws ec2 bundle-instance",
        "2": "aws ec2 start-instances",
        "3": "aws ec2 confirm-product-instance",
        "4": "aws ec2 run-instances",
        "5": 1,
        "no": "214",
        "question": "A developer needs to launch a new Amazon EC2 instance by using the AWS CLI.Which AWS CLI command should the developer use to meet this requirement?",
        "answer": "D"
      },
      {
        "1": "Use cost allocation reports and AWS OpsWorks to deploy and manage the infrastructure.",
        "2": "Use Amazon CloudWatch metrics and alerts along with resource tagging to deploy and manage the infrastructure.",
        "3": "Use AWS Elastic Beanstalk and AWS CodeCommit to deploy and manage the infrastructure.",
        "4": "Use AWS CloudFormation and AWS CodeCommit to deploy and manage the infrastructure.",
        "5": 1,
        "no": "215",
        "question": "A developer needs to manage AWS infrastructure as code and must be able to deploy multiple identical copies of the infrastructure, stage changes, and revert to previous versions.Which approach addresses these requirements?",
        "answer": "D"
      },
      {
        "1": "The cache is not being invalidated when the price of the item is changed.",
        "2": "The price of the item is being retrieved using a write-through ElastiCache cluster.",
        "3": "The DynamoDB table was provisioned with insufficient read capacity.",
        "4": "The DynamoDB table was provisioned with insufficient write capacity.",
        "5": 1,
        "no": "217",
        "question": "A developer has built a market application that stores pricing data in Amazon DynamoDB with Amazon ElastiCache in front. The prices of items in the market change frequently. Sellers have begun complaining that, after they update the price of an item, the price does not actually change in the product listing.What could be causing this issue?",
        "answer": "A"
      },
      {
        "1": "IAM policies might take a few minutes to propagate to resources.",
        "2": "Disabled environment variable credentials are still being used by the application.",
        "3": "The AWS SDK does not support credentials obtained using an instance role.",
        "4": "The instance’s security group does not allow access to http://169.254.169.254.",
        "5": 1,
        "no": "218",
        "question": "A company requires that all applications running on Amazon EC2 use IAM roles to gain access to AWS services. A developer is modifying an application that currently relies on IAM user access keys stored in environment variables to access Amazon DynamoDB tables using boto, the AWS SDK for Python.The developer associated a role with the same permissions as the IAM user to the EC2 instance, then deleted the IAM user. When the application was restarted, the AWS AccessDeniedException messages started appearing in the application logs. The developer was able to use their personal account on the server to run DynamoDB API commands using the AWS CLI.What is the MOST likely cause of the exception?",
        "answer": "B"
      },
      {
        "1": "Store the credentials in AWS Secrets Manager in the primary Region. Enable secret replication to the secondary Region. Update the application to use the Amazon Resource Name (ARN) based on the Region.",
        "2": "Store credentials in AWS Systems Manager Parameter Store in the primary Region. Enable parameter replication to the secondary Region. Update the application to use the Amazon Resource Name (ARN) based on the Region.",
        "3": "Store credentials in a config file. Upload the config file to an S3 bucket in the primary Region. Enable Cross-Region Replication (CRR) to an S3 bucket in the secondary region. Update the application to access the config file from the S3 bucket, based on the Region.",
        "4": "Store credentials in a config file. Upload the config file to an Amazon Elastic File System (Amazon EFS) file system. Update the application to use the Amazon EFS file system Regional endpoints to access the config file in the primary and secondary Regions.",
        "5": 1,
        "no": "219",
        "question": "A company has an existing application that has hardcoded database credentials. A developer needs to modify the existing application. The application is deployed in two AWS Regions with an active-passive failover configuration to meet company’s disaster recovery strategy.The developer needs a solution to store the credentials outside the code. The solution must comply with the company’s disaster recovery strategy.Which solution will meet these requirements in the MOST secure way?",
        "answer": "A"
      },
      {
        "1": "Contact AWS Support for a limit increase.",
        "2": "Use the AWS CLI to get the metrics.",
        "3": "Analyze the applications and remove the API call.",
        "4": "Retry the call with exponential backoff.",
        "5": 1,
        "no": "220",
        "question": "A developer is receiving HTTP 400: ThrottlingException errors intermittently when calling the Amazon CloudWatch API. When a call fails, no data is retrieved.What best practice should first be applied to address this issue?",
        "answer": "D"
      },
      {
        "1": "Remove the application from the ALB. Delete the ALB and change Amazon Route 53 to direct traffic to the instance running the application.",
        "2": "Remove the application from the ALCreate a Classic Load Balancer in its place. Direct traffic to the application using the HTTP protocol.",
        "3": "Alter the application code to inspect the X-Forwarded-For header. Ensure that the code can work properly if a list of IP addresses is passed in the header.",
        "4": "Alter the application code to inspect a custom header. Alter the client code to pass the IP address in the custom header.",
        "5": 1,
        "no": "221",
        "question": "An application needs to use the IP address of the client in its processing. The application has been moved into AWS and has been placed behind an Application Load Balancer (ALB). However, all the client IP addresses now appear to be the same. The application must maintain the ability to scale horizontally.Based on this scenario, what is the MOST cost-effective solution to this problem?",
        "answer": "C"
      },
      {
        "1": "Send the order ID to an Amazon Simple Notification Service (Amazon SNS) FIFO topic that fans out to one Amazon Simple Queue Service (Amazon SQS) FIFO queue for inventory management and another SQS FIFO queue for payment processing.",
        "2": "Change the Lambda function that generates the order ID to initiate the Lambda function for inventory management. Then initiate the Lambda function for payment processing.",
        "3": "Send the order ID to an Amazon Simple Notification Service (Amazon SNS) topic. Subscribe the Lambda functions for inventory management and payment processing to the topic.",
        "4": "Deliver the order ID to an Amazon Simple Queue Service (Amazon SQS) queue. Configure the Lambda functions for inventory management and payment processing to poll the queue.",
        "5": 1,
        "no": "222",
        "question": "A developer is designing a serverless application that customers use to select seats for a concert venue. Customers send the ticket requests to an Amazon API Gateway API with an AWS Lambda function that acknowledges the order and generates an order ID. The application includes two additional Lambda functions: one for inventory management and one for payment processing. These two Lambda functions run in parallel and write the order to an Amazon Dynamo DB table.The application must provide seats to customers according to the following requirements. If a seat is accidently sold more than once, the first order that the application received must get the seat. In these cases, the application must process the payment for only the first order. However, if the first order is rejected during payment processing, the second order must get the seat. In these cases, the application must process the payment for the second order.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Add custom attributes as annotations in the segment document.",
        "2": "Add custom attributes as metadata in the segment document.",
        "3": "Add custom attributes as new segment fields in the segment document.",
        "4": "Create new sampling rules that are based on custom attributes.",
        "5": 1,
        "no": "223",
        "question": "An application uses AWS X-Ray to generate a large amount of trace data on an hourly basis. A developer wants to use filter expressions to limit the returned results through user-specified custom attributes.How should the developer use filter expressions to filter the results in X-Ray?",
        "answer": "A"
      },
      {
        "1": "Enable SSL connections to Kinesis.",
        "2": "Use Amazon Kinesis Consumer Library.",
        "3": "Encrypt the data once it is at rest with a Lambda function.",
        "4": "Enable server-side encryption in Kinesis Data Streams.",
        "5": 1,
        "no": "224",
        "question": "A web application is using Amazon Kinesis Data Streams for clickstream data that may not be consumed for up to 12 hours.How can the developer implement encryption at rest for data within the Kinesis Data Streams?",
        "answer": "D"
      },
      {
        "1": "Amazon SNS with fanout to an SQS queue for each application",
        "2": "Amazon SNS with fanout to an SQS FIFO (first-in, first-out) queue for each application",
        "3": "Amazon Kinesis Firehose",
        "4": "Amazon Kinesis Data Streams",
        "5": 1,
        "no": "225",
        "question": "An application is real-time processing millions of events that are received through an API.What service could be used to allow multiple consumers to process the data concurrently and MOST cost-effectively?",
        "answer": "D"
      },
      {
        "1": "Add an Export declaration to the Outputs section of the original template and use ImportValue in other templates.",
        "2": "Add Exported: true to the Content.Bucket in the original template and use ImportResource in other templates.",
        "3": "Create a custom AWS CloudFormation resource that gets the bucket name from the ContentBucket resource of the first stack.",
        "4": "Use Fn::Include to include the existing template in other templates and use the ContentBucket resource directly.",
        "5": 1,
        "no": "226",
        "question": "Given the following AWS CloudFormation template:What is the MOST efficient way to reference the new Amazon S3 bucket from another AWS CloudFormation template?",
        "answer": "A"
      },
      {
        "1": "Move the application to a larger EC2 instance.",
        "2": "Increase the number of read capacity units (RCUs) that are provisioned for the DynamoDB table.",
        "3": "Reduce the frequency of requests to DynamoDB by implementing exponential backoff.",
        "4": "Increase the frequency of requests to DynamoDB by decreasing the retry delay.",
        "5": "Change the capacity mode of the DynamoDB table from provisioned to on-demand.",
        "no": "227",
        "question": "A developer has built an application that inserts data into an Amazon DynamoDB table. The table is configured to use provisioned capacity. The application is deployed on a burstable nano Amazon EC2 instance. The application logs show that the application has been failing because of a ProvisionedThroughputExceededException error.Which actions should the developer take to resolve this issue? (Choose two.)",
        "answer": "CE"
      },
      {
        "1": "Use S3 presigned URLs to share the documents with the external users. Set an expiration time of 7 days.",
        "2": "Move the documents to an Amazon WorkDocs folder. Share the links of the WorkDocs folder with the external users.",
        "3": "Create temporary IAM users that have read-only access to the S3 bucket. Share the access keys with the external users. Expire the credentials after 7 days.",
        "4": "Create a role that has read-only access to the S3 bucket. Share the Amazon Resource Name (ARN) of this role with the external users.",
        "5": 1,
        "no": "228",
        "question": "A company is hosting a workshop for external users and wants to share the reference documents with the external users for 7 days. The company stores the reference documents in an Amazon S3 bucket that the company owns.What is the MOST secure way to share the documents with the external users?",
        "answer": "A"
      },
      {
        "1": "Create a separate API Gateway and separate Lambda function for each environment in the same Region.",
        "2": "Assign a Region for each environment and deploy API Gateway and Lambda to each Region.",
        "3": "Create one API Gateway with multiple stages with one Lambda function with multiple aliases.",
        "4": "Create one API Gateway and one Lambda function, and use a REST parameter to identify the environment.",
        "5": 1,
        "no": "229",
        "question": "A developer is planning to use an Amazon API Gateway and AWS Lambda to provide a REST API. The developer will have three distinct environments to manage: development, test, and production.How should the application be deployed while minimizing the number of resources to manage?",
        "answer": "C"
      },
      {
        "1": "A Lambda function cannot be registered as a target for an ALB.",
        "2": "A Lambda function can be registered with an ALB using AWS Management Console only.",
        "3": "The permissions to invoke the Lambda function are missing.",
        "4": "Cross-zone is not enabled on the ALB.",
        "5": 1,
        "no": "230",
        "question": "A developer registered an AWS Lambda function as a target for an Application Load Balancer (ALB) using a CLI command. However, the Lambda function is not being invoked when the client sends requests through the ALB.Why is the Lambda function not being invoked?",
        "answer": "C"
      },
      {
        "1": "Store the database credentials as environment variables for the Lambda function. Set the environment variables to rotate automatically.",
        "2": "Store the database credentials in AWS Secrets Manager. Set up managed rotation on the database credentials.",
        "3": "Store the database credentials in AWS Systems Manager Parameter Store as secure string parameters. Set up managed rotation on the parameters.",
        "4": "Store the database credentials in the X-Amz-Security-Token parameter. Set up managed rotation on the parameter.",
        "5": 1,
        "no": "231",
        "question": "A developer is creating an AWS Lambda function that will connect to an Amazon RDS for MySQL instance. The developer wants to store the database credentials. The database credentials need to be encrypted and the database password needs to be automatically rotated.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Configure a weighted routing policy in Amazon Route 53. Associate the versions of the Lambda function with the weighted routing policy.",
        "2": "Create a function alias. Configure the alias to split the traffic between the two versions of the Lambda function.",
        "3": "Create an Application Load Balancer (ALB) that uses the Lambda function as a target. Configure the ALB to split the traffic between the two versions of the Lambda function.",
        "4": "Create the new version of the Lambda function as a Lambda layer on the existing version. Configure the function to split the traffic between the two layers.",
        "5": 1,
        "no": "232",
        "question": "A developer wants to reduce risk when deploying a new version of an existing AWS Lambda function. To test the Lambda function, the developer needs to split the traffic between the existing version and the new version of the Lambda function.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Submit a quota increase request to AWS Support to increase the function to the required size.",
        "2": "Use a compression algorithm that is more efficient than ZIP.",
        "3": "Break up the function into multiple smaller functions.",
        "4": "Zip the .zip file twice to compress the file more.",
        "5": "Move common libraries, function dependencies, and custom runtimes into Lambda layers.",
        "no": "233",
        "question": "A developer has created a large AWS Lambda function. Deployment of the function is failing because of an InvalidParameterValueException error. The error message indicates that the unzipped size of the function exceeds the maximum supported value.Which actions can the developer take to resolve this error? (Choose two.)",
        "answer": "CE"
      },
      {
        "1": "Increase the SQS event source’s batch size setting.",
        "2": "Configure provisioned concurrency for the Lambda function based on the third-party API’s documented rate limits.",
        "3": "Increase the retry attempts and maximum event age in the Lambda function’s asynchronous configuration.",
        "4": "Configure maximum concurrency on the SQS event source based on the third-party service’s documented rate limits.",
        "5": 1,
        "no": "234",
        "question": "A developer is troubleshooting an application in an integration environment. In the application, an Amazon Simple Queue Service (Amazon SQS) queue consumes messages and then an AWS Lambda function processes the messages. The Lambda function transforms the messages and makes an API call to a third-party service.There has been an increase in application usage. The third-party API frequently returns an HTTP 429 Too Many Requests error message. The error message prevents a significant number of messages from being processed successfully.How can the developer resolve this issue?",
        "answer": "A"
      },
      {
        "1": "Use Amazon ElastiCache to cache query results.",
        "2": "Scale the ECS cluster to contain more ECS instances.",
        "3": "Add read capacity units (RCUs) to the DB instance.",
        "4": "Modify the ECS task definition to increase the task memory.",
        "5": 1,
        "no": "235",
        "question": "A company has a three-tier application that is deployed in Amazon Elastic Container Service (Amazon ECS). The application is using an Amazon RDS for MySQL DB instance. The application performs more database reads than writes.During times of peak usage, the application’s performance degrades. When this performance degradation occurs, the DB instance’s ReadLatency metric in Amazon CloudWatch increases suddenly.How should a developer modify the application to improve performance?",
        "answer": "A"
      },
      {
        "1": 1,
        "2": 1,
        "3": 1,
        "4": 1,
        "5": 1,
        "no": "236",
        "question": "A company has an online web application that includes a product catalog. The catalog is stored in an Amazon S3 bucket that is named DOC-EXAMPLE-BUCKET. The application must be able to list the objects in the S3 bucket and must be able to download objects through an IAM policy.Which policy allows MINIMUM access to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Create a data key in AWS Key Management Service (AWS KMS). Use the AWS Encryption SDK to encrypt the files.",
        "2": "Create a Hash-Based Message Authentication Code (HMAC) key in AWS Key Management Service (AWS KMS). Use the AWS Encryption SDK to encrypt the files.",
        "3": "Create a data key pair in AWS Key Management Service (AWS KMS). Use the AWS CLI to encrypt the files.",
        "4": "Create a data key in AWS Key Management Service (AWS KMS). Use the AWS CLI to encrypt the files.",
        "5": 1,
        "no": "237",
        "question": "A developer is writing an application to encrypt files outside of AWS before uploading the files to an Amazon S3 bucket. The encryption must be symmetric and must be performed inside the application.How can the developer implement the encryption in the application to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Create an IAM user. Create an access key for the IAM user. Store the access key in the application’s environment variables.",
        "2": "Create an IAM role. Create an access key for the IAM role. Store the access key in the application’s environment variables.",
        "3": "Create an IAM role. Configure the IAM role to access the specific Amazon S3 API calls the application requires. Associate the IAM role with the EC2 instance.",
        "4": "Configure an S3 bucket policy for the S3 bucket. Configure the S3 bucket policy to allow access for the EC2 instance ID.",
        "5": 1,
        "no": "238",
        "question": "A developer is working on an application that is deployed on an Amazon EC2 instance. The developer needs a solution that will securely transfer files from the application to an Amazon S3 bucket.What should the developer do to meet these requirements in the MOST secure way?",
        "answer": "B"
      },
      {
        "1": "Add a listener rule to the listener to return a fixed response if the Authorization header is missing. Set the fixed response to 401 Unauthorized.",
        "2": "Create an authentication action for the listener rules of the ALSet the rule action type to authenticate-cognito. Set the OnUnauthenticatedRequest field to “deny.”",
        "3": "Create an Amazon API Gateway API. Configure all API methods to be forwarded to the ALB endpoint. Create an authorizer of the COGNITO_USER_POOLS type. Configure every API method to use that authorizer.",
        "4": "Create a new target group that includes an AWS Lambda function target that validates the Authorization header by using Amazon Cognito. Associate the target group with the listener.",
        "5": 1,
        "no": "239",
        "question": "A developer created a web API that receives requests by using an internet-facing Application Load Balancer (ALB) with an HTTPS listener. The developer configures an Amazon Cognito user pool and wants to ensure that every request to the API is authenticated through Amazon Cognito.What should the developer do to meet this requirement?",
        "answer": "B"
      },
      {
        "1": "Migrate the function to Amazon Elastic Kubernetes Service (Amazon EKS).",
        "2": "Increase the maximum age of events in Lambda.",
        "3": "Increase the function’s reserved concurrency.",
        "4": "Add the lambda:GetFunctionConcurrency action to the execution role.",
        "5": "Request a service quota change for increased concurrency.",
        "no": "240",
        "question": "A company recently deployed an AWS Lambda function. A developer notices an increase in the function throttle metrics in Amazon CloudWatch.What are the MOST operationally efficient solutions to reduce the function throttling? (Choose two.)",
        "answer": "CE"
      },
      {
        "1": "Use an X-Version header to denote which version is being called and pass that header to the Lambda function(s).",
        "2": "Create an API Gateway Lambda authorizer to route API clients to the correct API version.",
        "3": "Create an API Gateway resource policy to isolate versions and provide context to the Lambda function(s).",
        "4": "Deploy the API versions as unique stages with unique endpoints and use stage variables to provide further context.",
        "5": 1,
        "no": "241",
        "question": "A company is creating a REST service using an Amazon API Gateway with AWS Lambda integration. The service must run different versions for testing purposes.What would be the BEST way to accomplish this?",
        "answer": "D"
      },
      {
        "1": "The change was not made in the main branch of the AWS CodeCommit repository.",
        "2": "One of the earlier stages in the pipeline failed and the pipeline has terminated.",
        "3": "One of the Amazon EC2 instances in the company’s AWS CodePipeline cluster is inactive.",
        "4": "The AWS CodePipeline is incorrectly configured and is not invoking AWS CodeDeploy.",
        "5": "AWS CodePipeline does not have permissions to access AWS CodeCommit.",
        "no": "242",
        "question": "A company is using AWS CodePipeline to deliver one of its applications. The delivery pipeline is triggered by changes to the main branch of an AWS CodeCommit repository and uses AWS CodeBuild to implement the test and build stages of the process and AWS CodeDeploy to deploy the application.The pipeline has been operating successfully for several months and there have been no modifications. Following a recent change to the application’s source code, AWS CodeDeploy has not deployed the updated application as expected.What are the possible causes? (Choose two.)",
        "answer": "AB"
      },
      {
        "1": "Set the Deployment Preference Type to Canary10Percent10Minutes. Set the AutoPublishAlias property to the Lambda alias.",
        "2": "Set the Deployment Preference Type to Linear10PercentEvery10Minutes. Set AutoPublishAlias property to the Lambda alias.",
        "3": "Set the Deployment Preference Type to Canary10Percent10Minutes. Set the PreTraffic and PostTraffic properties to the Lambda alias.",
        "4": "Set the Deployment Preference Type to Linear10PercentEvery10Minutes. Set PreTraffic and PostTraffic properties to the Lambda alias.",
        "5": 1,
        "no": "243",
        "question": "A developer is building a serverless application by using AWS Serverless Application Model (AWS SAM) on multiple AWS Lambda functions. When the application is deployed, the developer wants to shift 10% of the traffic to the new deployment of the application for the first 10 minutes after deployment. If there are no issues, all traffic must switch over to the new version.Which change to the AWS SAM template will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Create an IAM role in the shared account. Add the ec2:DescribeInstances permission to the role. Establish a trust relationship between the development accounts for this role. Update the Lambda function IAM role in the shared account by adding the ec2:DescribeInstances permission to the role.",
        "2": "Create an IAM role in the development accounts. Add the ec2:DescribeInstances permission to the role. Establish a trust relationship with the shared account for this role. Update the Lambda function IAM role in the shared account by adding the iam:AssumeRole permissions.",
        "3": "Create an IAM role in the shared account. Add the ec2:DescribeInstances permission to the role. Establish a trust relationship between the development accounts for this role. Update the Lambda function IAM role in the shared account by adding the iam:AssumeRole permissions.",
        "4": "Create an IAM role in the development accounts. Add the ec2:DescribeInstances permission to the role. Establish a trust relationship with the shared account for this role. Update the Lambda function IAM role in the shared account by adding the ec2:DescribeInstances permission to the role.",
        "5": 1,
        "no": "244",
        "question": "An AWS Lambda function is running in a company’s shared AWS account. The function needs to perform an additional ec2:DescribeInstances action that is directed at the company’s development accounts. A developer must configure the required permissions across the accounts.How should the developer configure the permissions to adhere to the principle of least privilege?",
        "answer": "B"
      },
      {
        "1": "Use a unit testing framework to write custom unit tests against the cdk.out file that the AWS CDK generates. Run the unit tests in a continuous integration and continuous delivery (CI/CD) pipeline that is invoked after any commit to the repository.",
        "2": "Use the CDK assertions module to integrate unit tests with the application. Run the unit tests in a continuous integration and continuous delivery (CI/CD) pipeline that is invoked after any commit to the repository.",
        "3": "Use the CDK runtime context to set key-value pairs that must be present in the cdk.out file that the AWS CDK generates. Fail the stack synthesis if any violations are present.",
        "4": "Write a script that searches the application for specific key configuration strings. Configure the script to produce a report of any security violations.",
        "5": "Use the CDK Aspects class to create custom rules to apply to the CDK application. Fall the stack synthesis if any violations are present.",
        "no": "245",
        "question": "A developer is building a new application that will be deployed on AWS. The developer has created an AWS CodeCommit repository for the application. The developer has initialized a new project for the application by invoking the AWS Cloud Development Kit (AWS CDK) cdk init command.The developer must write unit tests for the infrastructure as code (IaC) templates that the AWS CDK generates. The developer also must run a validation tool across all constructs in the CDK application to ensure that critical security configurations are activated.Which combination of actions will meet these requirements with the LEAST development overhead? (Choose two.)",
        "answer": "BE"
      },
      {
        "1": "Create an Amazon EventBridge rule that has a rate expression that will run the rule every 15 minutes. Add the Lambda function as the target of the EventBridge rule.",
        "2": "Create an AWS Systems Manager document that has a script that will invoke the Lambda function on Amazon EC2. Use a Systems Manager Run Command task to run the shell script every 15 minutes.",
        "3": "Create an AWS Step Functions state machine. Configure the state machine to invoke the Lambda function execution role at a specified interval by using a Wait state. Set the interval to 15 minutes.",
        "4": "Provision a small Amazon EC2 instance. Set up a cron job that invokes the Lambda function every 15 minutes.",
        "5": 1,
        "no": "246",
        "question": "An online sales company is developing a serverless application that runs on AWS. The application uses an AWS Lambda function that calculates order success rates and stores the data in an Amazon DynamoDB table. A developer wants an efficient way to invoke the Lambda function every 15 minutes.Which solution will meet this requirement with the LEAST development effort?",
        "answer": "B"
      },
      {
        "1": "Create an Amazon CloudWatch custom metric. Each time a photo is processed, publish the processing time as a metric value. Create a CloudWatch alarm that is based on a static threshold of 5 seconds. Notify the development team by using an Amazon Simple Notification Service (Amazon SNS) topic.",
        "2": "Create an Amazon Simple Queue Service (Amazon SQS) queue. Each time a photo is processed, publish the processing time to the queue. Create an application to consume from the queue and to determine whether any values are more than 5 seconds. Notify the development team by using an Amazon Simple Notification Service (Amazon SNS) topic.",
        "3": "Create an Amazon CloudWatch custom metric. Each time a photo is processed, publish the processing time as a metric value. Create a CloudWatch alarm that enters ALARM state if the average of values is greater than 5 seconds. Notify the development team by sending an Amazon Simple Email Service (Amazon SES) message.",
        "4": "Create an Amazon Kinesis data stream. Each time a photo is processed, publish the processing time to the data stream. Create an Amazon CloudWatch alarm that enters ALARM state if any values are more than 5 seconds. Notify the development team by using an Amazon Simple Notification Service (Amazon SNS) topic.",
        "5": 1,
        "no": "247",
        "question": "A company deploys a photo-processing application to an Amazon EC2 instance. The application needs to process each photo in less than 5 seconds. If processing takes longer than 5 seconds, the company’s development team must receive a notification.How can a developer implement the required time measurement and notification with the LEAST operational overhead?",
        "answer": "A"
      },
      {
        "1": "All at once",
        "2": "Immutable",
        "3": "Rolling",
        "4": "Blue/green",
        "5": "Rolling with additional batch",
        "no": "248",
        "question": "A company is using AWS Elastic Beanstalk to manage web applications that are running on Amazon EC2 instances. A developer needs to make configuration changes. The developer must deploy the changes to new instances only.Which types of deployment can the developer use to meet this requirement? (Choose two.)",
        "answer": "BD"
      },
      {
        "1": "Create the DynamoDB table with encryption set to None. Code the application to use the key to decrypt the data when the application reads from the table. Code the application to use the key to encrypt the data when the application writes to the table.",
        "2": "Store the key by using AWS Key Management Service (AWS KMS). Choose an AWS KMS customer managed key during creation of the DynamoDB table. Provide the Amazon Resource Name (ARN) of the AWS KMS key.",
        "3": "Store the key by using AWS Key Management Service (AWS KMS). Create the DynamoDB table with default encryption. Include the kms:Encrypt parameter with the Amazon Resource Name (ARN) of the AWS KMS key when using the DynamoDB software development kit (SDK).",
        "4": "Store the key by using AWS Key Management Service (AWS KMS). Choose an AWS KMS AWS managed key during creation of the DynamoDB table. Provide the Amazon Resource Name (ARN) of the AWS KMS key.",
        "5": 1,
        "no": "249",
        "question": "A developer needs to use Amazon DynamoDB to store customer orders. The developer’s company requires all customer data to be encrypted at rest with a key that the company generates.What should the developer do to meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Update 20% of the planned changes to the production stage. Deploy the new production stage. Monitor the results. Repeat this process five times to test all planned changes.",
        "2": "Update the Amazon Route 53 DNS record entry for the production stage API to use a weighted routing policy. Set the weight to a value of 80. Add a second record for the production domain name. Change the second routing policy to a weighted routing policy. Set the weight of the second policy to a value of 20. Change the alias of the second policy to use the testing stage API.",
        "3": "Deploy an Application Load Balancer (ALB) in front of the REST API. Change the production API Amazon Route 53 record to point traffic to the ALB. Register the production and testing stages as targets of the ALB with weights of 80% and 20%, respectively.",
        "4": "Configure canary settings for the production stage API. Change the percentage of traffic directed to canary deployment to 20%. Make the planned updates to the production stage. Deploy the changes",
        "5": 1,
        "no": "250",
        "question": "A company uses AWS CloudFormation to deploy an application that uses an Amazon API Gateway REST API with AWS Lambda function integration. The application uses Amazon DynamoDB for data persistence. The application has three stages: development, testing, and production. Each stage uses its own DynamoDB table.The company has encountered unexpected issues when promoting changes to the production stage. The changes were successful in the development and testing stages. A developer needs to route 20% of the traffic to the new production stage API with the next production release. The developer needs to route the remaining 80% of the traffic to the existing production stage. The solution must minimize the number of errors that any single customer experiences.Which approach should the developer take to meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Integrate the client with an API Gateway WebSocket API. Save the user-uploaded files with the WebSocket connection ID. Push the validation status to the connection ID when the processing is complete to initiate an update of the user interface.",
        "2": "Launch an Amazon EC2 micro instance, and set up a WebSocket server. Send the user-uploaded file and user detail to the EC2 instance after the user uploads the file. Use the WebSocket server to send updates to the user interface when the uploaded file is processed.",
        "3": "Save the user’s email address along with the user-uploaded file. When the validation process is complete, send an email notification through Amazon Simple Notification Service (Amazon SNS) to the user who uploaded the file.",
        "4": "Save the user-uploaded file and user detail to Amazon DynamoDB. Use Amazon DynamoDB Streams with Amazon Simple Notification Service (Amazon SNS) push notifications to send updates to the browser to update the user interface.",
        "5": 1,
        "no": "251",
        "question": "A developer has created a data collection application that uses Amazon API Gateway, AWS Lambda, and Amazon S3. The application’s users periodically upload data files and wait for the validation status to be reflected on a processing dashboard. The validation process is complex and time-consuming for large files.Some users are uploading dozens of large files and have to wait and refresh the processing dashboard to see if the files have been validated. The developer must refactor the application to immediately update the validation result on the user’s dashboard without reloading the full dashboard.What is the MOST operationally efficient solution that meets these requirements?",
        "answer": "A"
      },
      {
        "1": "Authorization caching is enabled in the custom Lambda authorizer.",
        "2": "Authorization caching is enabled on the Amazon Cognito user pool.",
        "3": "The IAM role for the custom Lambda authorizer does not have a Department tag.",
        "4": "The IAM role for the Amazon Cognito user pool does not have a Department tag.",
        "5": 1,
        "no": "252",
        "question": "A company’s developer is creating an application that uses Amazon API Gateway. The company wants to ensure that only users in the Sales department can use the application. The users authenticate to the application by using federated credentials from a third-party identity provider (IdP) through Amazon Cognito. The developer has set up an attribute mapping to map an attribute that is named Department and to pass the attribute to a custom AWS Lambda authorizer.To test the access limitation, the developer sets their department to Engineering in the IdP and attempts to log in to the application. The developer is denied access. The developer then updates their department to Sales in the IdP and attempts to log in. Again, the developer is denied access. The developer checks the logs and discovers that access is being denied because the developer’s access token has a department value of Engineering.Which of the following is a possible reason that the developer’s department is still being reported as Engineering instead of Sales?",
        "answer": "A"
      },
      {
        "1": "Create an Amazon Kinesis Data Firehose delivery stream to process the requests. Create an Amazon Kinesis data stream. Modify the application to write the requests to the Kinesis data stream.",
        "2": "Create an AWS Lambda function to process the requests. Create an Amazon Simple Notification Service (Amazon SNS) topic. Subscribe the Lambda function to the SNS topic. Modify the application to write the requests to the SNS topic.",
        "3": "Create an AWS Lambda function to process the requests. Create an Amazon Simple Queue Service (Amazon SQS) standard queue. Set the SQS queue as an event source for the Lambda function. Modify the application to write the requests to the SQS queue.",
        "4": "Create an AWS Lambda function to process the requests. Create an Amazon Simple Queue Service (Amazon SQS) FIFO queue. Set the SQS queue as an event source for the Lambda function. Modify the application to write the requests to the SQS queue.",
        "5": 1,
        "no": "253",
        "question": "A company has migrated an application to Amazon EC2 instances. Automatic scaling is working well for the application user interface. However, the process to deliver shipping requests to the company’s warehouse staff is encountering issues. Duplicate shipping requests are arriving, and some requests are lost or arrive out of order.The company must avoid duplicate shipping requests and must process the requests in the order that the requests arrive. Requests are never more than 250 KB in size and take 5-10 minutes to process. A developer needs to rearchitect the application to improve the reliability of the delivery and processing of the requests.What should the developer do to meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Use the Amazon CodeGuru Profiler to analyze the Lambda functions used in the AWS Step Functions pipeline.",
        "2": "Use the AWS Step Functions Local Docker Image to run and locally test the Lambda functions.",
        "3": "Use the AWS Serverless Application Model (AWS SAM) CLI to run and locally test the Lambda functions.",
        "4": "Use AWS Step Functions Local with mocked service integrations.",
        "5": 1,
        "no": "254",
        "question": "A developer is creating a machine learning (ML) pipeline in AWS Step Functions that contains AWS Lambda functions. The developer has configured an Amazon Simple Queue Service (Amazon SQS) queue to deliver ML model parameters to the ML pipeline to train ML models. The developer uploads the trained models are uploaded to an Amazon S3 bucket.The developer needs a solution that can locally test the ML pipeline without making service integration calls to Amazon SQS and Amazon S3.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Store the third-party service endpoints in Lambda layers that correspond to the stage.",
        "2": "Store the third-party service endpoints in API Gateway stage variables that correspond to the stage.",
        "3": "Encode the third-party service endpoints as query parameters in the API Gateway request URL.",
        "4": "Store the third-party service endpoint for each environment in AWS AppConfig.",
        "5": 1,
        "no": "255",
        "question": "A company runs a batch processing application by using AWS Lambda functions and Amazon API Gateway APIs with deployment stages for development, user acceptance testing, and production. A development team needs to configure the APIs in the deployment stages to connect to third-party service endpoints.Which solution will meet this requirement?",
        "answer": "B"
      },
      {
        "1": "Use the AWS Serverless Application Model (AWS SAM) to build the application. Use the sam sync command to deploy the incremental changes.",
        "2": "Use the AWS Serverless Application Model (AWS SAM) to build the application. Use the sam init command to deploy the incremental changes.",
        "3": "Use the AWS Cloud Development Kit (AWS CDK) to build the application. Use the cdk synth command to deploy the incremental changes.",
        "4": "Use the AWS Cloud Development Kit (AWS CDK) to build the application. Use the cdk bootstrap command to deploy the incremental changes.",
        "5": 1,
        "no": "256",
        "question": "A developer is building a serverless application that runs on AWS. The developer wants to create an accelerated development workflow that deploys incremental changes to AWS for testing. The developer wants to deploy the incremental changes but does not want to fully deploy the entire application to AWS for every code commit.What should the developer do to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Set the integration type to AWS_PROXY. Provision Lambda functions to return hardcoded JSON data.",
        "2": "Set the integration type to MOCK. Configure the method's integration request and integration response to associate a JSON responses with specific HTTP status codes.",
        "3": "Set the integration type to HTTP_PROXY. Configure API Gateway to pass all requests to an external placeholder API. which the team will build.",
        "4": "Set the integration type to MOCK. Use a method request to define HTTP status codes. Use an integration request to define JSON responses.",
        "5": 1,
        "no": "257",
        "question": "A developer is building an application that will use an Amazon API Gateway API with an AWS Lambda backend. The team that will develop the frontend requires immediate access to the API endpoints to build the UI. To prepare the backend application for integration, the developer needs to set up endpoints. The endpoints need to return predefined HTTP status codes and JSON responses for the frontend team. The developer creates a method for an API resource.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "The developer did not successfully create the new AWS account.",
        "2": "The developer added a new tag to the Docker image.",
        "3": "The developer did not update the Docker image tag to a new version.",
        "4": "The developer pushed the changes to a new Docker image tag.",
        "5": 1,
        "no": "258",
        "question": "A developer is migrating an application to Amazon Elastic Kubernetes Service (Amazon EKS). The developer migrates the application to Amazon Elastic Container Registry (Amazon ECR) with an EKS cluster. As part of the application migration to a new backend, the developer creates a new AWS account. The developer makes configuration changes to the application to point the application to the new AWS account and to use new backend resources. The developer successfully tests the changes within the application by deploying the pipeline.The Docker image build and the pipeline deployment are successful, but the application is still connecting to the old backend. The developer finds that the application's configuration is still referencing the original EKS cluster and not referencing the new backend resources.Which reason can explain why the application is not connecting to the new resources?",
        "answer": "C"
      },
      {
        "1": "The developer did not successfully create the new AWS account.",
        "2": "The developer added a new tag to the Docker image.",
        "3": "The developer did not update the Docker image tag to a new version.",
        "4": "The developer pushed the changes to a new Docker image tag.",
        "5": 1,
        "no": "258",
        "question": "A developer is migrating an application to Amazon Elastic Kubernetes Service (Amazon EKS). The developer migrates the application to Amazon Elastic Container Registry (Amazon ECR) with an EKS cluster. As part of the application migration to a new backend, the developer creates a new AWS account. The developer makes configuration changes to the application to point the application to the new AWS account and to use new backend resources. The developer successfully tests the changes within the application by deploying the pipeline.The Docker image build and the pipeline deployment are successful, but the application is still connecting to the old backend. The developer finds that the application's configuration is still referencing the original EKS cluster and not referencing the new backend resources.Which reason can explain why the application is not connecting to the new resources?",
        "answer": "C"
      },
      {
        "1": "Write the encrypted key from the GenerateDataKey API to disk for later use. Use the plaintext key from the GenerateDataKey API and a symmetric encryption algorithm to encrypt the file.",
        "2": "Write the plain text key from the GenerateDataKey API to disk for later use. Use the encrypted key from the GenerateDataKey API and a symmetric encryption algorithm to encrypt the file.",
        "3": "Write the encrypted key from the GenerateDataKey API to disk for later use. Use the plaintext key from the GenerateDataKey API to encrypt the file by using the KMS Encrypt API.",
        "4": "Write the plain text key from the GenerateDataKey API to disk for later use. Use the encrypted key from the GenerateDataKey API to encrypt the file by using the KMS Encrypt API.",
        "5": 1,
        "no": "260",
        "question": "A developer is writing an application that will retrieve sensitive data from a third-party system. The application will format the data into a PDF file. The PDF file could be more than 1 MB. The application will encrypt the data to disk by using AWS Key Management Service (AWS KMS). The application will decrypt the file when a user requests to download it. The retrieval and formatting portions of the application are complete.The developer needs to use the GenerateDataKey API to encrypt the PDF file so that the PDF file can be decrypted later. The developer needs to use an AWS KMS symmetric customer managed key for encryption.Which solutions will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Create an IAM role that has permissions to access the database. Attach the IAM role to the EC2 instances.",
        "2": "Store the credentials as secrets in AWS Secrets Manager. Create an AWS Lambda function to update the secrets and the database. Retrieve the credentials from Secrets Manager as needed.",
        "3": "Store the credentials in an encrypted text file in an Amazon S3 bucket. Configure the EC2 instance launch template to download the credentials from Amazon S3 as the instance launches. Create an AWS Lambda function to update the secrets and the database.",
        "4": "Store the credentials in an Amazon DynamoDB table. Configure an Amazon CloudWatch Events rule to invoke an AWS Lambda function to periodically update the secrets and database.",
        "5": 1,
        "no": "261",
        "question": "A company runs an application on Amazon EC2 instances. The EC2 instances open connections to an Amazon RDS for SQL Server database. A developer needs to store and access the credentials and wants to automatically rotate the credentials. The developer does not want to store the credentials for the database in the code.Which solution will meet these requirements in the MOST secure way?",
        "answer": "B"
      },
      {
        "1": "Create an Amazon Simple Notification Service (Amazon SNS) topic. Subscribe the QA team to the Amazon SNS topic. Update the CloudFormation stack options to point to the SNS topic in the pre-production environment.",
        "2": "Create an AWS Lambda function that notifies the QA team. Create an Amazon EventBridge rule to invoke the Lambda function on the default event bus. Filter the events on the CloudFormation service and on the CloudFormation stack Amazon Resource Name (ARN).",
        "3": "Create an Amazon CloudWatch alarm that monitors the metrics from CloudFormation. Filter the metrics on the stack name and the stack status. Configure the CloudWatch alarm to notify the QA team.",
        "4": "Create an AWS Lambda function that notifies the QA team. Configure the event source mapping to receive events from CloudFormation. Specify the filtering values to limit invocations to the desired CloudFormation stack.",
        "5": 1,
        "no": "262",
        "question": "A company wants to test its web application more frequently. The company deploys the application by using a separate AWS CloudFormation stack for each environment. The company deploys the same CloudFormation template to each stack as the application progresses through the development lifecycle.A developer needs to build in notifications for the quality assurance (QA) team. The developer wants the notifications to occur for new deployments in the final preproduction environment.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Create an AWS CloudFormation template. Declare the users in the template. Attach the users to the database. Deploy the template in each account.",
        "2": "Create an AWS CloudFormation template that contains a custom resource to create the users in the database. Deploy the template in each account.",
        "3": "Write a script that creates the users. Deploy an Amazon EC2 instance in each account to run the script on the databases. Run the script in each account.",
        "4": "Implement an AWS Lambda function that creates the users in the database. Provide the function with the details of all three accounts.",
        "5": 1,
        "no": "263",
        "question": "A developer manages three AWS accounts. Each account contains an Amazon RDS DB instance in a private subnet. The developer needs to define users in each database in a consistent way. The developer must ensure that the same users are created and updated later in all three accounts.Which solution will meet these requirements with the MOST operational efficiency?",
        "answer": "B"
      },
      {
        "1": "Create API Gateway resources and set the integration type value to MOCK. Configure the method integration request and integration response to associate a response with an HTTP status code. Create an API Gateway stage and deploy the API.",
        "2": "Create an AWS Lambda function that returns mocked responses and various HTTP status codes. Create API Gateway resources and set the integration type value to AWS_PROXY. Deploy the API.",
        "3": "Create an EC2 application that returns mocked HTTP responses. Create API Gateway resources and set the integration type value to AWS. Create an API Gateway stage and deploy the API.",
        "4": "Create API Gateway resources and set the integration type value set to HTTP_PROXY. Add mapping templates and deploy the API. Create an AWS Lambda layer that returns various HTTP status codes. Associate the Lambda layer with the API deployment.",
        "5": 1,
        "no": "264",
        "question": "A company is building a new application that runs on AWS and uses Amazon API Gateway to expose APIs. Teams of developers are working on separate components of the application in parallel. The company wants to publish an API without an integrated backend so that teams that depend on the application backend can continue the development work before the API backend development is complete.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Use an SQS FIFO queue. Configure the visibility timeout value.",
        "2": "Use an SQS standard queue with a SendMessageBatchRequestEntry data type. Configure the DelaySeconds values.",
        "3": "Use an SQS standard queue with a SendMessageBatchRequestEntry data type. Configure the visibility timeout value.",
        "4": "Use an SQS FIFO queue. Configure the DelaySeconds value.",
        "5": 1,
        "no": "265",
        "question": "An application that runs on AWS receives messages from an Amazon Simple Queue Service (Amazon SQS) queue and processes the messages in batches. The application sends the data to another SQS queue to be consumed by another legacy application. The legacy system can take up to 5 minutes to process some transaction data.A developer wants to ensure that there are no out-of-order updates in the legacy system. The developer cannot alter the behavior of the legacy system.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Configure the fleet of EC2 instances to use encrypted EBS volumes to store data.",
        "2": "Configure the application to write all data to an encrypted Amazon S3 bucket.",
        "3": "Configure a custom encryption algorithm for the application that will encrypt and decrypt all data.",
        "4": "Configure an Amazon Machine Image (AMI) that has an encrypted root volume and store the data to ephemeral disks.",
        "5": 1,
        "no": "266",
        "question": "A company is building a compute-intensive application that will run on a fleet of Amazon EC2 instances. The application uses attached Amazon Elastic Block Store (Amazon EBS) volumes for storing data. The Amazon EBS volumes will be created at time of initial deployment. The application will process sensitive information. All of the data must be encrypted. The solution should not impact the application's performance.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Update the Lambda code and create a new version of the Lambda function. Create a Lambda function trigger. Configure the traffic weights in the trigger between the two Lambda function versions. Send 90% of the traffic to the production version, and send 10% of the traffic to the new version.",
        "2": "Create a new Lambda function that uses the updated code. Create a Lambda alias for the production Lambda function. Configure the Lambda alias to send 90% of the traffic to the production Lambda function, and send 10% of the traffic to the test Lambda function.",
        "3": "Update the Lambda code and create a new version of the Lambda function. Create a Lambda proxy integration. Configure the Lambda proxy to split traffic between the two Lambda function versions. Send 90% of the traffic to the production version, and send 10% of the traffic to the new version.",
        "4": "Update the Lambda code and create a new version of the Lambda function. Create a Lambda function alias. Configure the traffic weights in the Lambda alias between the two Lambda function versions. Send 90% of the traffic to the production version, and send 10% of the traffic to the new version.",
        "5": 1,
        "no": "267",
        "question": "A developer is updating the production version of an AWS Lambda function to fix a defect. The developer has tested the updated code in a test environment. The developer wants to slowly roll out the updates to a small subset of production users before rolling out the changes to all users. Only 10% of the users should be initially exposed to the new code in production.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Change the Amazon SQS standard queue to an Amazon SQS FIFO queue by using the Amazon SQS message deduplication ID.",
        "2": "Set up a dead-letter queue.",
        "3": "Set the maximum concurrency limit of the AWS Lambda function to 1.",
        "4": "Change the message processing to use Amazon Kinesis Data Streams instead of Amazon SQS.",
        "5": 1,
        "no": "268",
        "question": "A developer is creating an AWS Lambda function that consumes messages from an Amazon Simple Queue Service (Amazon SQS) standard queue. The developer notices that the Lambda function processes some messages multiple times.How should developer resolve this issue MOST cost-effectively?",
        "answer": "A"
      },
      {
        "1": "Define a function version for the currently deployed production Lambda function. Update the API Gateway endpoint to reference the new Lambda function version. Upload and publish the optimized Lambda function code. On the production API Gateway stage, define a canary release and set the percentage of traffic to direct to the canary release. Update the API Gateway endpoint to use the $LATEST version of the Lambda function. Publish the API to the canary stage.",
        "2": "Define a function version for the currently deployed production Lambda function. Update the API Gateway endpoint to reference the new Lambda function version. Upload and publish the optimized Lambda function code. Update the API Gateway endpoint to use the $LATEST version of the Lambda function. Deploy a new API Gateway stage.",
        "3": "Define an alias on the $LATEST version of the Lambda function. Update the API Gateway endpoint to reference the new Lambda function alias. Upload and publish the optimized Lambda function code. On the production API Gateway stage, define a canary release and set the percentage of traffic to direct to the canary release. Update the API Gateway endpoint to use the $LATEST version of the Lambda function. Publish to the canary stage.",
        "4": "Define a function version for the currently deployed production Lambda function. Update the API Gateway endpoint to reference the new Lambda function version. Upload and publish the optimized Lambda function code. Update the API Gateway endpoint to use the $LATEST version of the Lambda function. Deploy the API to the production API Gateway stage.",
        "5": 1,
        "no": "269",
        "question": "A developer is optimizing an AWS Lambda function and wants to test the changes in production on a small percentage of all traffic. The Lambda function serves requests to a RE ST API in Amazon API Gateway. The developer needs to deploy their changes and perform a test in production without changing the API Gateway URL.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Use AWS Key Management Service (AWS KMS) to encrypt the configuration file. Decrypt the configuration file when users make API calls to the SaaS vendor. Enable rotation.",
        "2": "Retrieve temporary credentials from AWS Security Token Service (AWS STS) every 15 minutes. Use the temporary credentials when users make API calls to the SaaS vendor.",
        "3": "Store the credentials in AWS Secrets Manager and enable rotation. Configure the API to have Secrets Manager access.",
        "4": "Store the credentials in AWS Systems Manager Parameter Store and enable rotation. Retrieve the credentials when users make API calls to the SaaS vendor.",
        "5": 1,
        "no": "270",
        "question": "A company notices that credentials that the company uses to connect to an external software as a service (SaaS) vendor are stored in a configuration file as plaintext.The developer needs to secure the API credentials and enforce automatic credentials rotation on a quarterly basis.Which solution will meet these requirements MOST securely?",
        "answer": "C"
      },
      {
        "1": "Create an EC2 instance profile and role with an appropriate policy. Associate the role with the EC2 instances.",
        "2": "Create an IAM user with an appropriate policy. Store the access key ID and secret access key on the EC2 instances.",
        "3": "Modify the application to use the S3 GeneratePresignedUrl API call.",
        "4": "Modify the application to use the S3 GetObject API call and to return the object handle to the user.",
        "5": "Modify the application to delegate requests to the S3 bucket.",
        "no": "271",
        "question": "A company has an application that is hosted on Amazon EC2 instances. The application stores objects in an Amazon S3 bucket and allows users to download objects from the S3 bucket. A developer turns on S3 Block Public Access for the S3 bucket. After this change, users report errors when they attempt to download objects. The developer needs to implement a solution so that only users who are signed in to the application can access objects in the S3 bucket.Which combination of steps will meet these requirements in the MOST secure way? (Choose two.)",
        "answer": "A E"
      },
      {
        "1": "Increase the memory configuration of the Lambda function.",
        "2": "Increase the visibility timeout on the SQS queue.",
        "3": "Increase the instance size of the host that runs the Lambda function.",
        "4": "Use multi-threading for the conversion.",
        "5": 1,
        "no": "272",
        "question": "An Amazon Simple Queue Service (Amazon SQS) queue serves as an event source for an AWS Lambda function. In the SQS queue, each item corresponds to a video file that the Lambda function must convert to a smaller resolution. The Lambda function is timing out on longer video files, but the Lambda function's timeout is already configured to its maximum value.What should a developer do to avoid the timeouts without additional code changes?",
        "answer": "D"
      },
      {
        "1": "Configure mock integrations for API Gateway API methods.",
        "2": "Integrate a Lambda function with API Gateway and return a mocked response.",
        "3": "Add new API endpoints to the API Gateway stage and returns a mocked response.",
        "4": "Configure a proxy resource for API Gateway API methods.",
        "5": 1,
        "no": "273",
        "question": "A company is building an application on AWS. The application's backend includes an Amazon API Gateway REST API. The company's frontend application developers cannot continue work until the backend API is ready for integration. The company needs a solution that will allow the frontend application developers to continue their work.Which solution will meet these requirements in the MOST operationally efficient way?",
        "answer": "D"
      },
      {
        "1": "Package the application into a .zip file by using a command line tool. Upload the package to Amazon S3.",
        "2": "Package the application into a container image by using the Docker CLI. Upload the image to Amazon Elastic Container Registry (Amazon ECR).",
        "3": "Deploy the application to an Amazon EC2 instance by using AWS CodeDeploy.",
        "4": "Deploy the application to Amazon Elastic Kubernetes Service (Amazon EKS) on AWS Fargate.",
        "5": "Deploy the application to Amazon Elastic Container Service (Amazon ECS) on AWS Fargate.",
        "no": "274",
        "question": "A company is preparing to migrate an application to the company's first AWS environment. Before this migration, a developer is creating a proof-of-concept application to validate a model for building and deploying container-based applications on AWS.Which combination of steps should the developer take to deploy the containerized proof-of-concept application with the LEAST operational effort? (Choose two.)",
        "answer": "BD"
      },
      {
        "1": "Enable TTL on the expirationDate attribute in the table. Create a DynamoDB stream. Create an AWS Lambda function to process the deleted items. Create a DynamoDB trigger for the Lambda function.",
        "2": "Create two AWS Lambda functions: one to delete the items and one to process the items. Create a DynamoDB stream. Use the DeleteItem API operation to delete the items based on the expirationDate attribute. Use the GetRecords API operation to get the items from the DynamoDB stream and process them.",
        "3": "Create two AWS Lambda functions: one to delete the items and one to process the items. Create an Amazon EventBridge scheduled rule to invoke the Lambda functions. Use the DeleteItem API operation to delete the items based on the expirationDate attribute. Use the GetRecords API operation to get the items from the DynamoDB table and process them.",
        "4": "Enable TTL on the expirationDate attribute in the table. Specify an Amazon Simple Queue Service (Amazon SQS) dead-letter queue as the target to delete the items. Create an AWS Lambda function to process the items.",
        "5": 1,
        "no": "275",
        "question": "A developer supports an application that accesses data in an Amazon DynamoDB table. One of the item attributes is expirationDate in the timestamp format. The application uses this attribute to find items, archive them, and remove them from the table based on the timestamp value.The application will be decommissioned soon, and the developer must find another way to implement this functionality. The developer needs a solution that will require the least amount of code to write.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Save the library in Lambda layers. Attach the layers to all Lambda functions.",
        "2": "Save the library in Amazon S3. Download the library from Amazon S3 inside the Lambda function.",
        "3": "Save the library as a Lambda container image. Redeploy the Lambda functions with the new image.",
        "4": "Save the library in an Amazon Elastic File System (Amazon EFS) file system. Mount the EFS file system in all the Lambda functions.",
        "5": 1,
        "no": "276",
        "question": "A developer needs to implement a custom machine learning (ML) library in an application. The size of the library is 15 GB. The size of the library is increasing. The application uses AWS Lambda functions. All the Lambda functions must have access to the library.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Create Amazon Cognito user pools for external social identity providers. Configure IAM roles for the identity pools.",
        "2": "Program the sign-in page to create users' IAM groups with the IAM roles attached to the groups.",
        "3": "Create an Amazon RDS for SQL Server DB instance to store the users and manage the permissions to the backend resources in AWS.",
        "4": "Configure the sign-in page to register and store the users and their passwords in an Amazon DynamoDB table with an attached IAM policy.",
        "5": 1,
        "no": "277",
        "question": "A developer is designing a serverless application for a game in which users register and log in through a web browser. The application makes requests on behalf of users to a set of AWS Lambda functions that run behind an Amazon API Gateway HTTP API.The developer needs to implement a solution to register and log in users on the application's sign-in page. The solution must minimize operational overhead and must minimize ongoing management of user identities.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Rewrite the application code to stream application logs to Amazon SNS. Configure an SNS topic to send a notification when the number of errors exceeds the defined threshold within a 5-minute period.",
        "2": "Configure a subscription filter on the CloudWatch Logs log group. Configure the filter to send an SNS notification when the number of errors exceeds the defined threshold within a 5-minute period.",
        "3": "Install and configure the Amazon Inspector agent on the EC2 instances to monitor for errors. Configure Amazon Inspector to send an SNS notification when the number of errors exceeds the defined threshold within a 5-minute period.",
        "4": "Create a CloudWatch metric filter to match the application error pattern in the log data. Set up a CloudWatch alarm based on the new custom metric. Configure the alarm to send an SNS notification when the number of errors exceeds the defined threshold within a 5-minute period.",
        "5": 1,
        "no": "278",
        "question": "A company has a web application that is hosted on Amazon EC2 instances. The EC2 instances are configured to stream logs to Amazon CloudWatch Logs. The company needs to receive an Amazon Simple Notification Service (Amazon SNS) notification when the number of application error messages exceeds a defined threshold within a 5-minute period.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Create an AWS Lambda function to run in response to the s3:ObjectCreated event type. Write the S3 key to an Amazon Simple Queue Service (Amazon SQS) queue with a visibility timeout of 24 hours. Create and configure a second Lambda function to read items from the queue. Retrieve the results for each item from the DynamoDB table. Tag each S3 object accordingly.",
        "2": "Create an AWS Lambda function to run in response to the s3:ObjectCreated event type. Integrate the function into an AWS Step Functions standard workflow. Define an AWS Step Functions Wait state and set the value to 24 hours. Create and configure a second Lambda function to retrieve the audit results and tag the S3 objects accordingly after the Wait state is over.",
        "3": "Create an AWS Lambda function to load all untagged S3 objects. Retrieve the results for each item from the REST API and tag each S3 object accordingly. Create and configure an Amazon EventBridge rule to run at regular intervals. Set the Lambda function as a target for the EventBridge rule.",
        "4": "Launch an Amazon EC2 instance. Deploy a script to the EC2 instance to use the external database results to tag the S3 objects accordingly. Configure a crontab file to run the script at regular intervals.",
        "5": 1,
        "no": "279",
        "question": "A photo sharing application uses Amazon S3 to store image files. All user images are manually audited for inappropriate content by a third-party company. The audits are completed 1-24 hours after user upload and the results are written to an Amazon DynamoDB table, which uses the S3 object key as a primary key. The database items can be queried by using a REST API created by the third-party company.An application developer needs to implement an automated process to tag all S3 objects with the results of the content audit.What should the developer do to meet these requirements in the MOST operationally efficient way?",
        "answer": "A"
      },
      {
        "1": "Use AWS CodeDeploy to deploy the function code.",
        "2": "Use Lambda layers to package and load dependencies.",
        "3": "Increase the memory size of the function.",
        "4": "Use Amazon S3 to host the function dependencies.",
        "5": 1,
        "no": "280",
        "question": "A company has built an AWS Lambda function to convert large image files into output files that can be used in a third-party viewer application. The company recently added a new module to the function to improve the output of the generated files. However, the new module has increased the bundle size and has increased the time that is needed to deploy changes to the function code.How can a developer increase the speed of the Lambda function deployment?",
        "answer": "D"
      },
      {
        "1": "Update the CloudFront distribution's settings to index.html as the default root object is set.",
        "2": "Update the Amazon S3 bucket settings and enable static website hosting. Specify index.html as the Index document. Update the S3 bucket policy to enable access. Update the CloudFront distribution's origin to use the S3 website endpoint.",
        "3": "Create a CloudFront function that examines the request URL and appends index.html when directories are being accessed. Add the function as a viewer request CloudFront function to the CloudFront distribution's behavior.",
        "4": "Create a custom error response on the CloudFront distribution with the HTTP error code set to the HTTP 404 Not Found response code and the response page path to /index.html. Set the HTTP response code to the HTTP 200 OK response code.",
        "5": 1,
        "no": "281",
        "question": "A developer creates a static website for their department. The developer deploys the static assets for the website to an Amazon S3 bucket and serves the assets with Amazon CloudFront. The developer uses origin access control (OAC) on the CloudFront distribution to access the S3 bucket.The developer notices users can access the root URL and specific pages but cannot access directories without specifying a file name. For example, /products/index.html works, but /products/ returns an error. The developer needs to enable accessing directories without specifying a file name without exposing the S3 bucket publicly.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "HTTP 401",
        "2": "HTTP 404",
        "3": "HTTP 503",
        "4": "HTTP 505",
        "5": 1,
        "no": "282",
        "question": "A developer is testing a RESTful application that is deployed by using Amazon API Gateway and AWS Lambda. When the developer tests the user login by using credentials that are not valid, the developer receives an HTTP 405: METHOD_NOT_ALLOWED error. The developer has verified that the test is sending the correct request for the resource.Which HTTP error should the application return in response to the request?",
        "answer": "A"
      },
      {
        "1": "AssumeRoleWithWebIdentity",
        "2": "GetFederationToken",
        "3": "AssumeRoleWithSAML",
        "4": "AssumeRole",
        "5": 1,
        "no": "283",
        "question": "A developer must use multi-factor authentication (MFA) to access data in an Amazon S3 bucket that is in another AWS account.Which AWS Security Token Service (AWS STS) API operation should the developer use with the MFA information to meet this requirement?",
        "answer": "B"
      },
      {
        "1": "Create an IAM user that has permissions to the S3 bucket. Add the user to an IAM group.",
        "2": "Create an IAM role that has permissions to the S3 bucket.",
        "3": "Add the IAM role to an instance profile. Attach the instance profile to the EC2 instance.",
        "4": "Create an IAM role that has permissions to the S3 bucket. Assign the role to an IAM group.",
        "5": "Store the credentials of the IAM user in the environment variables on the EC2 instance.",
        "no": "284",
        "question": "A developer designed an application on an Amazon EC2 instance. The application makes API requests to objects in an Amazon S3 bucket.Which combination of steps will ensure that the application makes the API requests in the MOST secure manner? (Choose two.)",
        "answer": "BC"
      },
      {
        "1": "Attach the existing IAM policy to the Lambda function.",
        "2": "Create an IAM role for the Lambda function. Attach the existing IAM policy to the role. Attach the role to the Lambda function.",
        "3": "Create an IAM user with programmatic access. Attach the existing IAM policy to the user. Add the user access key ID and secret access key as environment variables in the Lambda function.",
        "4": "Add the AWS account root user access key ID and secret access key as encrypted environment variables in the Lambda function.",
        "5": 1,
        "no": "285",
        "question": "An AWS Lambda function requires read access to an Amazon S3 bucket and requires read/write access to an Amazon DynamoDB table. The correct IAM policy already exists.What is the MOST secure way to grant the Lambda function access to the S3 bucket and the DynamoDB table?",
        "answer": "B"
      },
      {
        "1": "Add a Delay task after the GetResource task. Add a catcher to the GetResource task. Configure the catcher with an error type of TooManyRequestsException. Configure the next step to be the Delay task. Configure the Delay task to wait for an interval of 10 seconds. Configure the next step to be the GetResource task.",
        "2": "Add a catcher to the GetResource task. Configure the catcher with an error type of TooManyRequestsException, an interval of 10 seconds, and a maximum attempts value of 1. Configure the next step to be the GetResource task.",
        "3": "Add a retrier to the GetResource task. Configure the retrier with an error type of TooManyRequestsException, an interval of 10 seconds, and a maximum attempts value of 1.",
        "4": "Duplicate the GetResource task. Rename the new GetResource task to TryAgain. Add a catcher to the original GetResource task. Configure the catcher with an error type of TooManyRequestsException. Configure the next step to be TryAgain.",
        "5": 1,
        "no": "286",
        "question": "A developer is using AWS Step Functions to automate a workflow. The workflow defines each step as an AWS Lambda function task. The developer notices that runs of the Step Functions state machine fail in the GetResource task with either an IllegalArgumentException error or a TooManyRequestsException error.The developer wants the state machine to stop running when the state machine encounters an IllegalArgumentException error. The state machine needs to retry the GetResource task one additional time after 10 seconds if the state machine encounters a TooManyRequestsException error. If the second attempt fails, the developer wants the state machine to stop running.How can the developer implement the Lambda retry functionality without adding unnecessary complexity to the state machine?",
        "answer": "A"
      },
      {
        "1": "Use the AWS::Include transform in CloudFormation to provide the log group's name to the application.",
        "2": "Pass the log group's name to the application in the user data section of the CloudFormation template.",
        "3": "Use the CloudFormation template's Mappings section to specify the log group's name for the application.",
        "4": "Pass the log group's Amazon Resource Name (ARN) as an environment variable to the Lambda function.",
        "5": 1,
        "no": "287",
        "question": "A developer is creating a serverless application that uses an AWS Lambda function. The developer will use AWS CloudFormation to deploy the application. The application will write logs to Amazon CloudWatch Logs. The developer has created a log group in a CloudFormation template for the application to use. The developer needs to modify the CloudFormation template to make the name of the log group available to the application at runtime.Which solution will meet this requirement?",
        "answer": "C"
      },
      {
        "1": "Create an AWS Key Management Service (AWS KMS) customer managed key. Provide the key's Amazon Resource Name (ARN) in the KMSMasterKeyId parameter during creation of the DynamoDB table.",
        "2": "Create an AWS Key Management Service (AWS KMS) AWS managed key. Provide the key's Amazon Resource Name (ARN) in the KMSMasterKeyId parameter during creation of the DynamoDB table.",
        "3": "Create an AWS owned key. Provide the key's Amazon Resource Name (ARN) in the KMSMasterKeyId parameter during creation of the DynamoDB table.",
        "4": "Create the DynamoDB table with the default encryption options.",
        "5": 1,
        "no": "288",
        "question": "A developer is creating an Amazon DynamoDB table by using the AWS CLI. The DynamoDB table must use server-side encryption with an AWS owned encryption key.How should the developer create the DynamoDB table to meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Use the X-Ray console to add annotations for AWS services and user-defined services.",
        "2": "Use Region annotation that X-Ray adds automatically for AWS services. Add Region annotation for user-defined services.",
        "3": "Use the X-Ray daemon to add annotations for AWS services and user-defined services.",
        "4": "Use Region annotation that X-Ray adds automatically for user-defined services. Configure X-Ray to add Region annotation for AWS services.",
        "5": 1,
        "no": "289",
        "question": "A company has an application that runs across multiple AWS Regions. The application is experiencing performance issues at irregular intervals. A developer must use AWS X-Ray to implement distributed tracing for the application to troubleshoot the root cause of the performance issues.What should the developer do to meet this requirement?",
        "answer": "B"
      },
      {
        "1": "Set the event source mapping batch size to 10 for the high priority queue and to 90 for the low priority queue.",
        "2": "Set the delivery delay to 0 seconds for the high priority queue and to 10 seconds for the low priority queue.",
        "3": "Set the event source mapping maximum concurrency to 10 for the high priority queue and to 90 for the low priority queue.",
        "4": "Set the event source mapping batch window to 10 for the high priority queue and to 90 for the low priority queue.",
        "5": 1,
        "no": "290",
        "question": "A company runs an application on AWS. The application uses an AWS Lambda function that is configured with an Amazon Simple Queue Service (Amazon SQS) queue called high priority queue as the event source. A developer is updating the Lambda function with another SQS queue called low priority queue as the event source. The Lambda function must always read up to 10 simultaneous messages from the high priority queue before processing messages from low priority queue. The Lambda function must be limited to 100 simultaneous invocations.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Configure AWS Secrets Manager versions to store different copies of the same credentials across multiple environments.",
        "2": "Create a new parameter version in AWS Systems Manager Parameter Store for each environment. Store the environment-specific credentials in the parameter version.",
        "3": "Configure the environment variables in the application code. Use different names for each environment type.",
        "4": "Configure AWS Secrets Manager to create a new secret for each environment type. Store the environment-specific credentials in the secret.",
        "5": 1,
        "no": "291",
        "question": "A data visualization company wants to strengthen the security of its core applications. The applications are deployed on AWS across its development, staging, pre-production, and production environments. The company needs to encrypt all of its stored sensitive credentials. The sensitive credentials need to be automatically rotated. A version of the sensitive credentials need to be stored for each environment.Which solution will meet these requirements in the MOST operationally efficient way?",
        "answer": "C"
      },
      {
        "1": "Standard SQS queues support at-least-once message delivery.",
        "2": "Standard SQS queues support exactly-once processing, so the duplicate email messages are because of user error.",
        "3": "Amazon SES has the DomainKeys Identified Mail (DKIM) authentication incorrectly configured.",
        "4": "The SQS queue's visibility timeout is lower than or the same as the Lambda function's timeout.",
        "5": "The Amazon SES bounce rate metric is too high.",
        "no": "292",
        "question": "A developer is investigating an issue in part of a company's application. In the application, messages are sent to an Amazon Simple Queue Service (Amazon SQS) queue. The AWS Lambda function polls messages from the SQS queue and sends email messages by using Amazon Simple Email Service (Amazon SES). Users have been receiving duplicate email messages during periods of high traffic.Which reasons could explain the duplicate email messages? (Choose two.)",
        "answer": "AD"
      },
      {
        "1": "Store the files in an Amazon S3 bucket. Use the S3 Glacier Instant Retrieval storage class. Create an S3 Lifecycle policy to transition the files to the S3 Glacier Deep Archive storage class after 1 year.",
        "2": "Store the files in an Amazon S3 bucket. Use the S3 Standard storage class. Create an S3 Lifecycle policy to transition the files to the S3 Glacier Flexible Retrieval storage class after 1 year.",
        "3": "Store the files on an Amazon Elastic Block Store (Amazon EBS) volume. Use Amazon Data Lifecycle Manager (Amazon DLM) to create snapshots of the EBS volumes and to store those snapshots in Amazon S3.",
        "4": "Store the files on an Amazon Elastic File System (Amazon EFS) mount. Configure EFS lifecycle management to transition the files to the EFS Standard- Infrequent Access (Standard-IA) storage class after 1 year.",
        "5": 1,
        "no": "293",
        "question": "A developer is deploying a company's application to Amazon EC2 instances. The application generates gigabytes of data files each day. The files are rarely accessed, but the files must be available to the application's users within minutes of a request during the first year of storage. The company must retain the files for 7 years.How can the developer implement the application to meet these requirements MOST cost-effectively?",
        "answer": "B"
      },
      {
        "1": "Modify the CloudFormation stack to set the deletion policy to Retain for the Parameter Store parameters.",
        "2": "Create an Amazon DynamoDB table as a resource in the CloudFormation stack to hold configuration data for the application. Migrate the parameters that the application is modifying from Parameter Store to the DynamoDB table.",
        "3": "Create an Amazon RDS DB instance as a resource in the CloudFormation stack. Create a table in the database for parameter configuration. Migrate the parameters that the application is modifying from Parameter Store to the configuration table.",
        "4": "Modify the CloudFormation stack policy to deny updates on Parameter Store parameters.",
        "5": 1,
        "no": "294",
        "question": "A company's developer has deployed an application in AWS by using AWS CloudFormation. The CloudFormation stack includes parameters in AWS Systems Manager Parameter Store that the application uses as configuration settings. The application can modify the parameter values.When the developer updated the stack to create additional resources with tags, the developer noted that the parameter values were reset and that the values ignored the latest changes made by the application. The developer needs to change the way the company deploys the CloudFormation stack. The developer also needs to avoid resetting the parameter values outside the stack.Which solution will meet these requirements with the LEAST development effort?",
        "answer": "C"
      },
      {
        "1": "Use Amazon DynamoDB Accelerator (DAX) in front of the RDS database to provide a caching layer for the high volume of rapidly changing data.",
        "2": "Set up Amazon S3 Transfer Acceleration on the RDS database to enhance the speed of data transfer from the databases to the application.",
        "3": "Add an Amazon CloudFront distribution in front of the RDS database to provide a caching layer for the high volume of rapidly changing data.",
        "4": "Create an Amazon ElastiCache for Redis cluster. Update the application code to use a write-through caching strategy and read the data from Redis.",
        "5": 1,
        "no": "295",
        "question": "A company has a social media application that receives large amounts of traffic. User posts and interactions are continuously updated in an Amazon RDS database. The data changes frequently, and the data types can be complex. The application must serve read requests with minimal latency.The application's current architecture struggles to deliver these rapid data updates efficiently. The company needs a solution to improve the application's performance.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Enable AWS X-Ray active tracing in the Lambda function. Review the logs in X-Ray.",
        "2": "Configure AWS CloudTrail. View the trail logs that are associated with the Lambda function.",
        "3": "Review the AWS Config logs in Amazon CloudWatch.",
        "4": "Review the Amazon CloudWatch logs that are associated with the Lambda function.",
        "5": 1,
        "no": "296",
        "question": "A developer created an AWS Lambda function that performs a series of operations that involve multiple AWS services. The function's duration time is higher than normal. To determine the cause of the issue, the developer must investigate traffic between the services without changing the function code.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Transfer the information that is in the NFS share to an Amazon Elastic Block Store (Amazon EBS) volume. Upload the container images to Amazon Elastic Container Registry (Amazon ECR).",
        "2": "Transfer the information that is in the NFS share to an Amazon Elastic File System (Amazon EFS) volume. Upload the container images to Amazon Elastic Container Registry (Amazon ECR).",
        "3": "Create an Amazon Elastic Container Service (Amazon ECS) cluster to run the applications. Configure each node of the cluster to mount the Amazon Elastic Block Store (Amazon EBS) volume at the required path for the container images.",
        "4": "Create an Amazon Elastic Kubernetes Service (Amazon EKS) cluster to run the applications. Configure each node of the cluster to mount the Amazon Elastic Block Store (Amazon EBS) volume at the required path for the container images.",
        "5": "Create an Amazon Elastic Kubernetes Service (Amazon EKS) cluster to run the applications. Configure each node of the cluster to mount the Amazon Elastic File System (Amazon EFS) volume at the required path for the container images.",
        "no": "297",
        "question": "A company has on-premises data centers that run an image processing service. The service consists of containerized applications that run on Kubernetes clusters. All the applications have access to the same NFS share for files and data storage.The company is running out of NFS capacity in the data centers and needs to migrate to AWS as soon as possible. The Kubernetes clusters must be highly available on AWS.Which combination of actions will meet these requirements? (Choose two.)",
        "answer": "A E"
      },
      {
        "1": "Configure a Lambda function destination with a failure condition. Specify Lambda function as the destination type. Specify the error-handling Lambda function's Amazon Resource Name (ARN) as the resource.",
        "2": "Enable AWS X-Ray active tracing on the initial Lambda function. Configure X-Ray to capture stack traces of the failed invocations. Invoke the error-handling Lambda function by including the stack traces in the event object.",
        "3": "Configure a Lambda function trigger with a failure condition. Specify Lambda function as the destination type. Specify the error-handling Lambda function's Amazon Resource Name (ARN) as the resource.",
        "4": "Create a status check alarm on the initial Lambda function. Configure the alarm to invoke the error-handling Lambda function when the alarm is initiated. Ensure that the alarm passes the stack trace in the event object.",
        "5": 1,
        "no": "298",
        "question": "A company has an analytics application that uses an AWS Lambda function to process transaction data asynchronously. A developer notices that asynchronous invocations of the Lambda function sometimes fail. When failed Lambda function invocations occur, the developer wants to invoke a second Lambda function to handle errors and log details.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Add a new stage to CodePipeline before the production deployment. Add a manual approval action to the new stage. Add a new notification rule in the pipeline settings. Specify manual approval as the event that initiates the notification. Specify the SNS topic's Amazon Resource Name (ARN) to notify the product owner.",
        "2": "Develop an AWS Step Functions state machine that sends a notification to the product owner and accepts an approval. Add a new stage to CodePipeline before the production deployment. Add the state machine as a Step Functions action to the new stage.",
        "3": "Add a manual approval action to the existing production deployment stage in CodePipeline. Specify the SNS topic's Amazon Resource Name (ARN) while configuring the new manual approval action.",
        "4": "Edit the settings in CodePipeline. Create a new notification rule. Specify manual approval as the event that initiates the notification. Create a new notification target. Specify the SNS topic to notify the product owner. Save the notification rule.",
        "5": 1,
        "no": "300",
        "question": "A developer needs approval from a product owner before the developer can deploy code for an application to production. The developer uses AWS CodePipeline to deploy the application. The developer configures an Amazon Simple Notification Service (Amazon SNS) topic to send notifications to the product owner.Which solution is the MOST operationally efficient way for the developer to receive approval from the product owner?",
        "answer": "B"
      },
      {
        "1": "Add a Retry field in the Step Functions state machine definition. Configure the state machine with the maximum number of retry attempts and the timeout error type to retry on.",
        "2": "Add a Timeout field in the Step Functions state machine definition. Configure the state machine with the maximum number of retry attempts.",
        "3": "Add a Fail state to the Step Functions state machine definition. Configure the state machine with the maximum number of retry attempts.",
        "4": "Update the Step Functions state machine to pass the invocation request to an Amazon Simple Notification Service (Amazon SNS) topic. Subscribe a Lambda function to the SNS topic. Configure the Lambda function with the maximum number of retry attempts for a timeout error type.",
        "5": 1,
        "no": "301",
        "question": "A developer is building a serverless application on AWS for a workflow that processes high volumes of data. In the workflow, an AWS Step Functions state machine invokes several AWS Lambda functions.One of the Lambda functions occasionally fails because of timeout errors during periods of high demand. The developer must ensure that the workflow automatically retries the failed function invocation if a timeout error occurs.Which solution will meet this requirement?",
        "answer": "A"
      },
      {
        "1": "Configure managed rotation with the single user rotation strategy.",
        "2": "Configure managed rotation with the alternating users rotation strategy.",
        "3": "Configure automatic rotation with the single user rotation strategy.",
        "4": "Configure automatic rotation with the alternating users rotation strategy.",
        "5": 1,
        "no": "302",
        "question": "A company runs a serverless application on AWS. The application includes an AWS Lambda function. The Lambda function processes data and stores the data in an Amazon RDS for PostgreSQL database. A developer created a user credentials in the database for the application.The developer needs to use AWS Secrets Manager to manage the user credentials. The password must to be rotated on a regular basis. The solution needs to ensure that there is high availability and no downtime for the application during secret rotation.What should the developer do to meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Perform a query across all the Lambda function log groups by using Amazon CloudWatch Logs Insights. Filter on type of report and sort descending by Lambda function execution duration.",
        "2": "Enable AWS CloudTrail Insights on the account where the Lambda functions are running. After CloudTrail Insights has finished processing, review CloudTrail Insights to find the anomalous functions.",
        "3": "Enable AWS X-Ray for all the Lambda functions. Configure an X-Ray insight on a new group that includes all the Lambda functions. After the X-Ray insight has finished processing, review the X-Ray logs.",
        "4": "Set up AWS Glue to crawl through the logs in Amazon CloudWatch Logs for the Lambda functions. Configure an AWS Glue job to transform the logs into a structured format and to output the logs into Amazon S3. Use the Amazon CloudWatch dashboard to visualize the slowest functions based on the duration.",
        "5": 1,
        "no": "303",
        "question": "A company runs an application on AWS. The application consists of a static website that is hosted on Amazon S3. The application includes Amazon API Gateway APIs that invoke AWS Lambda functions. During a period of high traffic on the application, application users reported that the application was slow at irregular intervals. There were no failed requests.A developer needs to find the slow executions across all the Lambda functions.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Use API Gateway stage variables and create Lambda aliases to reference environment-specific resources.",
        "2": "Use Amazon Elastic Container Service (Amazon ECS) to deploy the application to the environments.",
        "3": "Duplicate the code for each environment. Deploy the code to a separate API Gateway stage.",
        "4": "Use AWS Elastic Beanstalk to deploy the application to the environments.",
        "5": 1,
        "no": "304",
        "question": "A company is building a serverless application on AWS. The application uses Amazon API Gateway and AWS Lambda. The company wants to deploy the application to its development, test, and production environments.Which solution will meet these requirements with the LEAST development effort?",
        "answer": "B"
      },
      {
        "1": "Configure the CloudFormation template to reference the API endpoint in the DefinitionSubstitutions property for the AWS::StepFunctions::StateMachine resource.",
        "2": "Configure the CloudFormation template to store the API endpoint in an environment variable for the AWS::StepFunctions::StateMachine resource. Configure the state machine to reference the environment variable.",
        "3": "Configure the CloudFormation template to store the API endpoint in a standard AWS::SecretsManager::Secret resource. Configure the state machine to reference the resource.",
        "4": "Configure the CloudFormation template to store the API endpoint in a standard AWS::AppConfig::ConfigurationProfile resource. Configure the state machine to reference the resource.",
        "5": 1,
        "no": "305",
        "question": "A developer uses AWS CloudFormation to deploy an Amazon API Gateway API and an AWS Step Functions state machine. The state machine must reference the API Gateway API after the CloudFormation template is deployed. The developer needs a solution that uses the state machine to reference the API Gateway endpoint.Which solution will meet these requirements MOST cost-effectively?",
        "answer": "C"
      },
      {
        "1": "Increase the maximum timeout of the Lambda function to 15 minutes. Check the AWS CloudTrail event history for error details.",
        "2": "Increase the visibility timeout of the SQS queue. Check logs in Amazon CloudWatch Logs for error details.",
        "3": "Create a dead-letter queue. Configure the Lambda function to send the failed messages to the dead-letter queue.",
        "4": "Create an Amazon DynamoDB table. Update the Lambda function to send the failed messages to the DynamoDB table.",
        "5": 1,
        "no": "306",
        "question": "A developer is building an application on AWS. The application includes an AWS Lambda function that processes messages from an Amazon Simple Queue Service (Amazon SQS) queue.The Lambda function sometimes fails or times out. The developer needs to figure out why the Lambda function fails to process some messages.Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "B"
      },
      {
        "1": "Create a certificate in ACM in any one of the Regions. Import the certificate into the ALB that is in each Region.",
        "2": "Create a global certificate in ACM. Update the CloudFormation template to deploy the global certificate to each ALB.",
        "3": "Create a certificate in ACM in each Region. Import the certificate into the ALB for each Region.",
        "4": "Create a certificate in ACM in the us-east-1 Region. Update the CloudFormation template to deploy the certificate to each ALB.",
        "5": 1,
        "no": "307",
        "question": "A developer needs to deploy an application in three AWS Regions by using AWS CloudFormation. Each Region will use an AWS Elastic Beanstalk environment with an Application Load Balancer (ALB). The developer wants to use AWS Certificate Manager (ACM) to deploy SSL certificates to each ALB.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Create an AWS Lambda function to filter events from CloudTrail if a role was created without CloudFormation. Configure the Lambda function to publish to the SNS topic. Create an Amazon EventBridge schedule to invoke the Lambda function every 15 minutes.",
        "2": "Create an AWS Fargate task in Amazon Elastic Container Service (Amazon ECS) to filter events from CloudTrail if a role was created without CloudFormation. Configure the Fargate task to publish to the SNS topic. Create an Amazon EventBridge schedule to run the Fargate task every 15 minutes.",
        "3": "Launch an Amazon EC2 instance that includes a script to filter events from CloudTrail if a role was created without CloudFormation. Configure the script to publish to the SNS topic. Create a cron job to run the script on tile EC2 instance every 15 minutes.",
        "4": "Create an Amazon EventBridge rule to filter events from CloudTrail if a role was created without CloudFormation. Specify the SNS topic as the target of the EventBridge rule.",
        "5": 1,
        "no": "308",
        "question": "A company needs to deploy all its cloud resources by using AWS CloudFormation templates. A developer must create an Amazon Simple Notification Service (Amazon SNS) automatic notification to help enforce this rule. The developer creates an SNS topic and subscribes the email address of the company's security team to the SNS topic.The security team must receive a notification immediately if an IAM role is created without the use of CloudFormation.Which solution will meet this requirement?",
        "answer": "D"
      },
      {
        "1": "Add a Resources section to the CloudFormation templates that contains AWS::Lambda::Function resources.",
        "2": "Add a Mappings section to the CloudFormation templates that contains AWS::Serverless::Function and AWS::Serverless::API.",
        "3": "Add a Transform section to the CloudFormation templates. Use the AWS SAM syntax to define the resources.",
        "4": "Add a Parameters section to the CloudFormation templates that specifies the relevant AWS SAM Globals section.",
        "5": 1,
        "no": "309",
        "question": "A company is adopting serverless computing for some of its new services. A development team needs to create a serverless infrastructure by using AWS Serverless Application Model (AWS SAM). All infrastructure must be deployed by using AWS CloudFormation templates.What should the development team do to meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Add an Amazon EventBridge rule for the Lambda function. Configure the EventBridge rule to react to failed events and to store the events in an Amazon DynamoDB table.",
        "2": "Configure the Lambda function with a dead-letter queue based in Amazon Kinesis. Update the Lambda function's execution role with the required permissions.",
        "3": "Configure the Lambda function with an Amazon Simple Queue Service (Amazon SQS) dead-letter queue. Update the Lambda function's execution role with the required permissions.",
        "4": "Configure the Lambda function with an Amazon Simple Queue Service (Amazon SQS) FIFO dead-letter queue. Update the Lambda function's execution role with the required permissions.",
        "5": 1,
        "no": "310",
        "question": "A developer is building an application that invokes AWS Lambda functions asynchronously to process events. The developer notices that a Lambda function fails to process some events at random times. The developer needs to investigate the failed events and capture the events that the Lambda function fails to process.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Configure the REST API in API Gateway to write the requests directly into DynamoDB. Configure a DynamoDB intrinsic function to perform the transformation. Set up a DynamoDB stream to call the third-party stock application API with each new row. Delete the Lambda function.",
        "2": "Configure the REST API in API Gateway to write the requests directly into an Amazon Simple Queue Service (Amazon SQS) queue. Configure the Lambda function with a reserved concurrency equal to the third-party stock application's threshold. Set Lambda function to process the messages from the SQS queue.",
        "3": "Configure the REST API in API Gateway to write the requests directly into an Amazon Simple Notification Service (Amazon SNS) topic. Configure the Lambda function with a provisioned concurrency equal to the third-party stock application's threshold. Set the Lambda function to process the messages from the SNS topic.",
        "4": "Configure the REST API in API Gateway to write the requests directly into Amazon Athena. Configure the transformation of the data by using SQL with multiple query result locations set up to point to the DynamoDB table and the third-party stock fulfilment application API. Delete the Lambda function.",
        "5": 1,
        "no": "311",
        "question": "A company has built a serverless application for its ecommerce website. The application includes a REST API in Amazon API Gateway that invokes an AWS Lambda function. The Lambda function processes data and stores the data in Amazon DynamoDB table. The Lambda function calls a third-party stock application API to process the order. After the ordered is processed, the Lambda function returns an HTTP 200 status code with no body to the client.During peak usage when the API calls exceeds a certain threshold, the third-party stock application sometimes fails to process the data and responds with error messages. The company needs a solution that will not overwhelm the third-party stock application.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Migrate the secret credentials to Amazon RDS parameter groups. Encrypt the parameter by using an AWS Key Management Service (AWS KMS) key. Turn on secret rotation. Use IAM policies and roles to grant AWS KMS permissions to access Amazon RDS.",
        "2": "Migrate the credentials to AWS Systems Manager Parameter Store. Encrypt the parameter by using an AWS Key Management Service (AWS KMS) key. Turn on secret rotation. Use IAM policies and roles to grant Amazon ECS Fargate permissions to access to AWS Secrets Manager.",
        "3": "Migrate the credentials to ECS Fargate environment variables. Encrypt the credentials by using an AWS Key Management Service (AWS KMS) key. Turn on secret rotation. Use IAM policies and roles to grant Amazon ECS Fargate permissions to access to AWS Secrets Manager.",
        "4": "Migrate the credentials to AWS Secrets Manager. Encrypt the credentials by using an AWS Key Management Service (AWS KMS) key. Turn on secret rotation. Use IAM policies and roles to grant Amazon ECS Fargate permissions to access to AWS Secrets Manager by using keys.",
        "5": 1,
        "no": "312",
        "question": "A company hosts its application on AWS. The application runs on an Amazon Elastic Container Service (Amazon ECS) cluster that uses AWS Fargate. The cluster runs behind an Application Load Balancer. The application stores data in an Amazon Aurora database. A developer encrypts and manages database credentials inside the application.The company wants to use a more secure credential storage method and implement periodic credential rotation.Which solution will meet these requirements with the LEAST operational overhead?",
        "answer": "C"
      },
      {
        "1": "Create a new version of each Lambda function with a weighted alias. Configure a weight value for each version of the Lambda function. Update the new weighted alias Amazon Resource Name (ARN) in the REST API.",
        "2": "Create a new REST API in API Gateway. Set up a Lambda proxy integration to connect to multiple Lambda functions. Enable canary settings on the deployment stage. Specify a smaller percentage of API traffic to go to the new version of the Lambda function.",
        "3": "Create a new version of each Lambda function. Integrate a predefined canary deployment in AWS CodeDeploy to slowly shift the traffic to the new versions automatically.",
        "4": "Create a new REST API in API Gateway. Set up a Lambda non-proxy integration to connect to multiple Lambda functions. Specify the necessary parameters and properties in API Gateway. Enable canary settings on the deployment stage. Specify a smaller percentage of API traffic to go to the new version of the Lambda function.",
        "5": 1,
        "no": "313",
        "question": "A company has a mobile app. The app includes an Amazon API Gateway REST API that invokes AWS Lambda functions. The Lambda functions process data from the app.The company needs to test updated Lambda functions that have new features. The company must conduct these tests with a subset of users before deployment. The tests must not affect other users of the app.Which solution will meet these requirements with the LEAST amount of operational effort?",
        "answer": "C"
      },
      {
        "1": "Use the AWS SAM CLI to package and deploy the SAM application to the pre-production AWS account. Specify the debug parameter.",
        "2": "Use the AWS SAM CLI to package and create a change set against the pre-production AWS account. Execute the change set in a new AWS account designated for a development environment.",
        "3": "Use the AWS SAM CLI to package and deploy the SAM application to a new AWS account designated for a development environment.",
        "4": "Update the CloudFormation stack in the pre-production account. Add a separate stage that points to a new AWS account designated for a development environment.",
        "5": 1,
        "no": "314",
        "question": "A developer works for a company that only has a single pre-production AWS account with an AWS CloudFormation AWS Serverless Application Model (AWS SAM) stack. The developer made changes to an existing AWS Lambda function specified in the AWS SAM template and additional Amazon Simple Notification service (Amazon SNS) topics.The developer wants to do a one-time deploy of the changes to test if the changes are working. The developer does not want to impact the existing pre-production application that is currently being used by other team members as part of the release pipeline.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Configure a TTL attribute for the leaderboard data.",
        "2": "Use DynamoDB Streams to schedule and delete the leaderboard data.",
        "3": "Use AWS Step Functions to schedule and delete the leaderboard data.",
        "4": "Set a higher write capacity when the scheduled delete job runs.",
        "5": 1,
        "no": "315",
        "question": "A company built an online event platform. For each event, the company organizes quizzes and generates leaderboards that are based on the quiz scores. The company stores the leaderboard data in Amazon DynamoDB and retains the data for 30 days after an event is complete. The company then uses a scheduled job to delete the old leaderboard data.The DynamoDB table is configured with a fixed write capacity. During the months when many events occur, the DynamoDB write API requests are throttled when the scheduled delete job runs.A developer must create a long-term solution that deletes the old leaderboard data and optimizes write throughput.Which solution meets these requirements?",
        "answer": "D"
      },
      {
        "1": "Configure a provisioned concurrency of two on the Lambda function.",
        "2": "Configure a batch size of two on the Amazon SQS event source mapping for the Lambda function.",
        "3": "Configure Lambda event filtering to process two messages from Amazon SQS at every invocations.",
        "4": "Configure a maximum concurrency of two on the Amazon SQS event source mapping for the Lambda function.",
        "5": 1,
        "no": "316",
        "question": "A company uses an AWS Lambda function that reads messages from an Amazon Simple Queue Service (Amazon SQS) standard queue. The Lambda function makes an HTTP call to a third-party API for each message. The company wants to ensure that the Lambda function does not overwhelm the third-party API with more than two concurrent requests.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Set up a mock integration request in API Gateway. Configure the method's integration request and integration response to associate a response with a given status code.",
        "2": "Set up the request validators in the API's OpenAPI definition file. Import the OpenAPI definitions into API Gateway to test the API.",
        "3": "Set up a gateway response for the API in API Gateway. Configure response headers with hardcoded HTTP status codes and responses.",
        "4": "Set up a request parameter-based Lambda authorizer to control access to the API. Configure the Lambda function with the necessary mapping template.",
        "5": 1,
        "no": "317",
        "question": "A company is using Amazon API Gateway to develop an API for its application on AWS. A developer needs to test and generate API responses. Other teams are required to test the API immediately.What should the developer do to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Username",
        "2": "Submission date",
        "3": "Validation status",
        "4": "Rating of the process on a scale of 1 to 5",
        "5": 1,
        "no": "318",
        "question": "A company is releasing a new feature. Users can request early access to the new feature by using an application form. The company expects a surge of requests when the application form becomes available. Each request will be stored as an item in an Amazon DynamoDB table.Each item will contain the user's username, the submission date, and a validation status of UNVALIDATED. VALID, or NOT VALID. Each item also will contain the user's rating of the process on a scale of 1 to 5.Each user can submit one request. For the DynamoDB table, the developer must choose a partition key that will give the workload well-distributed records across partitions.Which DynamoDB attribute will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Create a new origin access control (OAC) in CloudFront. Configure the CloudFront distribution's origin to use the new OAC. Update the S3 bucket policy to allow CloudFront OAC with read and write access to access Amazon S3 as the origin.",
        "2": "Update the S3 bucket settings. Enable the block all public access setting in Amazon S3. Configure the CloudFront distribution's with Amazon S3 as the origin. Update the S3 bucket policy to allow CloudFront write access.",
        "3": "Update the S3 bucket's static website settings. Enable static website hosting and specifying index and error documents. Update the CloudFront origin to use the S3 bucket's website endpoint.",
        "4": "Update the CloudFront distribution's origin to send a custom header. Update the S3 bucket policy with a condition by using the aws:RequestTag/tag-key key. Configure the tag-key as the custom header name, and the value being matched is the header's value.",
        "5": 1,
        "no": "319",
        "question": "A developer is creating a publicly accessible enterprise website consisting of only static assets. The developer is hosting the website in Amazon S3 and serving the website to users through an Amazon CloudFront distribution. The users of this application must not be able to access the application content directly from an S3 bucket. All content must be served through the Amazon CloudFront distribution.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Add a capacity provider to manage instances.",
        "2": "Add an Amazon EC2 instance that runs the application.",
        "3": "Define a task definition with an AWS Fargate launch type.",
        "4": "Create an Amazon ECS cluster and add the managed node groups feature to run the application.",
        "5": 1,
        "no": "320",
        "question": "A developer built an application that calls an external API to obtain data, processes the data, and saves the result to Amazon S3. The developer built a container image with all of the necessary dependencies to run the application as a container.The application runs locally and requires minimal CPU and RAM resources. The developer has created an Amazon ECS cluster. The developer needs to run the application hourly in Amazon Elastic Container Service (Amazon ECS).Which solution will meet these requirements with the LEAST amount of infrastructure management overhead?",
        "answer": "D"
      },
      {
        "1": "Create an AWS Lambda function to delete old user responses based on the expiration_date attribute. Create an Amazon EventBridge schedule to run the Lambda function daily.",
        "2": "Create an AWS Fargate task in Amazon Elastic Container Service (Amazon ECS) to delete old user responses based on the expiration_date attribute. Create an Amazon EventBridge schedule to run the Fargate task daily.",
        "3": "Create an AWS Glue job to delete old user responses based on the expiration_date attribute. Create an AWS Glue trigger schedule to run the job daily.",
        "4": "Enable TTL on the DynamoDB table and specify the expiration_date attribute. Expire old user responses by using DynamoDB TTL.",
        "5": 1,
        "no": "321",
        "question": "A company runs its website on AWS. The company posts daily polls on its website and publishes the poll results next day. The website stores user responses in an Amazon DynamoDB table. After the poll results are published, the company does not need to keep the user responses.A developer needs to implement a solution that will automatically remove old user responses from the DynamoDB table. The developer adds a new expiration_date attribute to the DynamoDB table. The developer plans to use the expiration_date attribute for the automation.Which solution will meet these requirements with the LEAST development effort?",
        "answer": "A"
      },
      {
        "1": "Add the function code in the CloudFormation template inline as the code property.",
        "2": "Add the function code in the CloudFormation template as the ZipFile property.",
        "3": "Find the S3 key for the Lambda function. Add the S3 key as the ZipFile property in the CloudFormation template.",
        "4": "Add the relevant key and bucket to the S3Bucket and S3Key properties in the CloudFormation template.",
        "5": 1,
        "no": "322",
        "question": "A developer is creating a simple proof-of-concept demo by using AWS CloudFormation and AWS Lambda functions. The demo will use a CloudFormation template to deploy an existing Lambda function. The Lambda function uses deployment packages and dependencies stored in Amazon S3. The developer defined an AWS::Lambda::Function resource in a CloudFormation template. The developer needs to add the S3 bucket to the CloudFormation template.What should the developer do to meet these requirements with the LEAST development effort?",
        "answer": "C"
      },
      {
        "1": "Modify the X-Ray Python agent configuration in each service to increase the sampling rate.",
        "2": "Instrument the application by using the X-Ray SDK for Python. Install the X-Ray SDK for all the services that the application uses.",
        "3": "Enable X-Ray data aggregation in Amazon CloudWatch Logs for all the services that the application uses.",
        "4": "Increase the X-Ray service map timeout value in the X-Ray console.",
        "5": 1,
        "no": "323",
        "question": "A developer is building a microservices-based application by using Python on AWS and several AWS services. The developer must use AWS X-Ray. The developer views the service map by using the console to view the service dependencies. During testing, the developer notices that some services are missing from the service map.What can the developer do to ensure that all services appear in the X-Ray service map?",
        "answer": "D"
      },
      {
        "1": "Store the API keys as a SecureString parameter in AWS Systems Manager Parameter Store. Grant the application access to retrieve the value from Parameter Store.",
        "2": "Store the API keys in AWS CloudFormation templates by using base64 encoding. Pass the API keys to the application through container definition environment variables.",
        "3": "Add a new AWS CloudFormation parameter to the CloudFormation template. Pass the API keys to the application by using the container definition environment variables.",
        "4": "Embed the API keys in the application. Build the container image on-premises. Upload the container image to Amazon Elastic Container Registry (Amazon ECR).",
        "5": "Store the API keys as a SecretString parameter in AWS Secrets Manager. Grant the application access to retrieve the value from Secrets Manager.",
        "no": "324",
        "question": "A developer is building a containerized application on AWS. The application communicates with a third-party service by using API keys. The developer needs a secure way to store the API keys and pass the API keys to the containerized application.Which solutions will meet these requirements? (Choose two.)",
        "answer": "AC"
      },
      {
        "1": "Increase the page size for each request by setting the Limit parameter to be higher than the default value. Configure the application to retry any request that exceeds the provisioned throughput.",
        "2": "Create a global secondary index (GSI). Set query attribute to be the partition key of the index.",
        "3": "Perform a parallel scan operation by issuing individual scan requests. In the parameters, specify the segment for the scan requests and the total number of segments for the parallel scan.",
        "4": "Turn on read capacity auto scaling for the DynamoDB table. Increase the maximum read capacity units (RCUs).",
        "5": 1,
        "no": "325",
        "question": "A company runs an application on AWS. The application stores data in an Amazon DynamoDB table. Some queries are taking a long time to run. These slow queries involve an attribute that is not the table's partition key or sort key.The amount of data that the application stores in the DynamoDB table is expected to increase significantly. A developer must increase the performance of the queries.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Save the secrets in a text file and store the text file in Amazon S3. Provision a customer managed key. Use the key for secret encryption in Amazon S3. Read the contents of the text file and read the export as environment variables. Configure S3 Object Lambda to rotate the text file every month.",
        "2": "Save the secrets as strings in AWS Systems Manager Parameter Store and use the default AWS Key Management Service (AWS KMS) key. Configure an Amazon EC2 user data script to retrieve the secrets during the startup and export as environment variables. Configure an AWS Lambda function to rotate the secrets in Parameter Store every month.",
        "3": "Save the secrets as base64 encoded environment variables in the application properties. Retrieve the secrets during the application startup. Reference the secrets in the application code. Write a script to rotate the secrets saved as environment variables.",
        "4": "Store the secrets in AWS Secrets Manager. Provision a new customer master key. Use the key to encrypt the secrets. Enable automatic rotation. Configure an Amazon EC2 user data script to programmatically retrieve the secrets during the startup and export as environment variables.",
        "5": 1,
        "no": "326",
        "question": "A company runs a payment application on Amazon EC2 instances behind an Application Load Balance. The EC2 instances run in an Auto Scaling group across multiple Availability Zones. The application needs to retrieve application secrets during the application startup and export the secrets as environment variables. These secrets must be encrypted at rest and need to be rotated every month.Which solution will meet these requirements with the LEAST development effort?",
        "answer": "D"
      },
      {
        "1": "Enable a Lambda authorizer for the Lambda function alias in API Gateway. Republish PROD and create a new stage for DEV. Create API Gateway stage variables for the PROD and DEV stages. Point each stage variable to the PROD Lambda authorizer to the DEV Lambda authorizer.",
        "2": "Set up a gateway response in API Gateway for the Lambda function alias. Republish PROD and create a new stage for DEV. Create gateway responses in API Gateway for PROD and DEV Lambda aliases.",
        "3": "Use an environment variable for the Lambda function alias in API Gateway. Republish PROD and create a new stage for development. Create API gateway environment variables for PROD and DEV stages. Point each stage variable to the PROD Lambda function alias to the DEV Lambda function alias.",
        "4": "Use an API Gateway stage variable to configure the Lambda function alias. Republish PROD and create a new stage for development. Create API Gateway stage variables for PROD and DEV stages. Point each stage variable to the PROD Lambda function alias and to the DEV Lambda function alias.",
        "5": 1,
        "no": "327",
        "question": "A company is using Amazon API Gateway to invoke a new AWS Lambda function. The company has Lambda function versions in its PROD and DEV environments. In each environment, there is a Lambda function alias pointing to the corresponding Lambda function version. API Gateway has one stage that is configured to point at the PROD alias.The company wants to configure API Gateway to enable the PROD and DEV Lambda function versions to be simultaneously and distinctly available.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Set up an Amazon API Gateway REST API with a gateway response configured for status code 200. Add response templates that contain sample responses captured from the real third-party API.",
        "2": "Set up an AWS AppSync GraphQL API with a data source configured for each third-party API. Specify an integration type of Mock. Configure integration responses by using sample responses captured from the real third-party API.",
        "3": "Create an AWS Lambda function for each third-party API. Embed responses captured from the real third-party API. Configure Amazon Route 53 Resolver with an inbound endpoint for each Lambda function's Amazon Resource Name (ARN).",
        "4": "Set up an Amazon API Gateway REST API for each third-party API. Specify an integration request type of Mock. Configure integration responses by using sample responses captured from the real third-party API.",
        "5": 1,
        "no": "328",
        "question": "A developer is working on an ecommerce platform that communicates with several third-party payment processing APIs. The third-party payment services do not provide a test environment.The developer needs to validate the ecommerce platform's integration with the third-party payment processing APIs. The developer must test the API integration code without invoking the third-party payment processing APIs.Which solution will meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Store the objects by using S3 Intelligent-Tiering.",
        "2": "Store the objects at the root of the S3 bucket.",
        "3": "Store the objects by using object key names distributed across multiple prefixes.",
        "4": "Store each object with an object tag named \"prefix\" that contains a unique value.",
        "5": 1,
        "no": "329",
        "question": "A developer is storing many objects in a single Amazon S3 bucket. The developer needs to optimize the S3 bucket for high request rates.How should the developer store the objects to meet this requirement?",
        "answer": "C"
      },
      {
        "1": "Select the appropriate log group. Create a CloudWatch metric filter with \"ERROR\" as the search term. Create an alarm on this metric that notifies the SNS topic when the metric is 1 or higher.",
        "2": "In CloudWatch Logs Insights, select the appropriate log group. Create a metric query to search for the term \"ERROR\" in the logs. Create an alarm on this metric that notifies the SNS topic when the metric is 1 or higher.",
        "3": "Select the appropriate log group. Create an SNS subscription filter with \"ERROR\" as the filter pattern. Select the SNS topic as the destination.",
        "4": "Create a CloudWatch alarm that includes \"ERROR\" as a filter pattern, a log group dimension that defines the appropriate log group, and a destination that notifies the SNS topic.",
        "5": 1,
        "no": "330",
        "question": "A company deploys a new application to AWS. The company is streaming application logs to Amazon CloudWatch Logs. The company's development team must receive notification by email when the word \"ERROR\" appears in any log lines. A developer sets up an Amazon Simple Notification Service (Amazon SNS) topic and subscribes the development team to the topic.What should the developer do next to meet the requirements?",
        "answer": "A"
      },
      {
        "1": "Enable server-side encryption for the SQS queue by using an SQS managed encryption key (SSE-SQS).",
        "2": "Use the aws:SecureTransport condition in the queue policy to ensure that only HTTPS (TLS) is used for all requests to the SQS queue.",
        "3": "Use AWS Certificate Manager (ACM) to generate an SSL/TLS certificate. Reference the certificate when messages are sent to the queue.",
        "4": "Set a message attribute in the SQS SendMessage request for messages that are sent to the queue. Set the Name to ENCRYPT. Set the Value to TRUE.",
        "5": 1,
        "no": "331",
        "question": "A company uses Amazon Simple Queue Service (Amazon SQS) to decouple its microservices architecture. Some messages in an SQS queue contain sensitive information. A developer must implement a solution that encrypts all the data at rest.Which solution will meet this requirement?",
        "answer": "A"
      },
      {
        "1": "Update the Lambda function by adding logging statements with high-precision timestamps before and after each external request. Deploy the updated Lambda function. After accumulating enough usage data, examine the Amazon CloudWatch logs for the Lambda function to determine the likely sources for the increased response time.",
        "2": "Instrument the Lambda function with the AWS X-Ray SDK. Add HTTP and HTTPS interceptors and SDK client handlers. Deploy the updated Lambda function. Turn on X-Ray tracing. After accumulating enough usage data, use the X-Ray service map to examine the average response times to determine the likely sources.",
        "3": "Review the Lambda function's Amazon CloudWatch metrics by using the metrics explorer. Apply anomaly detection to the Duration metric and the Throttles metric. Review the anomalies to determine the likely sources.",
        "4": "Use Amazon CloudWatch Synthetics to create a new canary. Turn on AWS X-Ray tracing on the canary. Configure the canary to scan the user portal. After accumulating enough usage data, use the CloudWatch Synthetics canary dashboard to view the metrics from the canary.",
        "5": 1,
        "no": "332",
        "question": "A company recently deployed a new serverless user portal. Users have reported that part of the portal is slow. The initial analysis found a single Amazon API Gateway endpoint that is responsible for the performance issues. The endpoint integrates with an AWS Lambda function. However, the Lambda function interacts with other APIs and AWS services.How can a developer find the source of the increased response time by using operational best practices?",
        "answer": "B"
      },
      {
        "1": "Configure a VPC peering connection between the Lambda function and EventBridge.",
        "2": "Modify their AWS credentials to include permissions for the PutEvents EventBridge action.",
        "3": "Modify the Lambda function execution role to include permissions for the PutEvents EventBridge action.",
        "4": "Add a resource-based policy to the Lambda function to include permissions for the PutEvents EventBridge action.",
        "5": 1,
        "no": "333",
        "question": "A developer is building an event-driven application by using AWS Lambda and Amazon EventBridge. The Lambda function needs to push events to an EventBridge event bus. The developer uses an SDK to run the PutEvents EventBridge action and specifies no credentials in the code. After deploying the Lambda function, the developer notices that the function is failing and there are AccessDeniedException errors in the logs.How should the developer resolve this issue?",
        "answer": "C"
      },
      {
        "1": "Use the Lambda function's ConcurrentExecutions metric in Amazon CloudWatch to measure the throughput.",
        "2": "Modify the application to log the calculated throughput to Amazon CloudWatch Logs. Use Amazon EventBridge to invoke a separate Lambda function to process the logs on a schedule.",
        "3": "Modify the application to publish custom Amazon CloudWatch metrics when the Lambda function receives and processes each message. Use the metrics to calculate the throughput.",
        "4": "Use the Lambda function's Invocations metric and Duration metric to calculate the throughput in Amazon CloudWatch.",
        "5": 1,
        "no": "334",
        "question": "A company's application has an AWS Lambda function that processes messages from IoT devices. The company wants to monitor the Lambda function to ensure that the Lambda function is meeting its required service level agreement (SLA).A developer must implement a solution to determine the application's throughput in near real time. The throughput must be based on the number of messages that the Lambda function receives and processes in a given time period. The Lambda function performs initialization and post-processing steps that must not factor into the throughput measurement.What should the developer do to meet these requirements?",
        "answer": "C"
      },
      {
        "1": "Create an Amazon S3 bucket to store the dependency .jar file. Publish the dependency .jar file to the S3 bucket. Use an Amazon Simple Notification Service (Amazon SNS) notification to start a CodePipeline pipeline build.",
        "2": "Create an Amazon Elastic Container Registry (Amazon ECR) private repository. Publish the dependency .jar file to the repository. Use an ECR source action to start a CodePipeline pipeline build.",
        "3": "Create an Amazon Elastic Container Registry (Amazon ECR) private repository. Publish the dependency .jar file to the repository. Use an Amazon Simple Notification Service (Amazon SNS) notification to start a CodePipeline pipeline build.",
        "4": "Create an AWS CodeArtifact repository. Publish the dependency .jar file to the repository. Use an Amazon EventBridge rule to start a CodePipeline pipeline build.",
        "5": 1,
        "no": "335",
        "question": "A developer is using an AWS CodePipeline pipeline to provide continuous integration and continuous delivery (CI/CD) support for a Java application. The developer needs to update the pipeline to support the introduction of a new application dependency .jar file. The pipeline must start a build when a new version of the .jar file becomes available.Which solution will meet these requirements?",
        "answer": "D"
      },
      {
        "1": "Configure an S3 event notification to invoke the Lambda function when a branch office uploads a sales report.",
        "2": "Create an AWS Step Functions state machine that invokes the Lambda function once each day at the predefined time.",
        "3": "Configure the Lambda function to run continuously and to begin analysis only at the predefined time each day.",
        "4": "Create an Amazon EventBridge scheduled rule that invokes the Lambda function once each day at the predefined time.",
        "5": 1,
        "no": "336",
        "question": "A company with multiple branch locations has an analytics and reporting application. Each branch office pushes a sales report to a shared Amazon S3 bucket at a predefined time each day. The company has developed an AWS Lambda function that analyzes the reports from all branch offices in a single pass. The Lambda function stores the results in a database.The company needs to start the analysis once each day at a specific time.Which solution will meet these requirements MOST cost-effectively?",
        "answer": "D"
      },
      {
        "1": "Set up Amazon CloudWatch Logs log groups to filter and store the messages in an Amazon S3 bucket. Import the messages in Lambda. Run the Lambda function again.",
        "2": "Configure Amazon EventBridge to send the messages to Amazon Simple Notification Service (Amazon SNS) to initiate the Lambda function again.",
        "3": "Implement a dead-letter queue for discarded messages. Set the dead-letter queue as an event source for the Lambda function.",
        "4": "Send Amazon EventBridge events to an Amazon Simple Queue Service (Amazon SQS) queue. Configure the Lambda function to pull messages from the SQS queue. Run the Lambda function again.",
        "5": 1,
        "no": "337",
        "question": "A developer has an application that asynchronously invokes an AWS Lambda function. The developer wants to store messages that resulted in failed invocations of the Lambda function so that the application can retry the call later.What should the developer do to accomplish this goal with the LEAST operational overhead?",
        "answer": "C"
      },
      {
        "1": "Investigate the change sets.",
        "2": "Investigate the stack policies.",
        "3": "Investigate the Metadata section.",
        "4": "Investigate the Resources section.",
        "5": 1,
        "no": "338",
        "question": "A company is using AWS CloudFormation templates to deploy AWS resources. The company needs to update one of its AWS CloudFormation stacks.What can the company do to find out how the changes will impact the resources that are running?",
        "answer": "A"
      },
      {
        "1": "Allow the EC2 IAM role the permission to assume the AccessPII role.",
        "2": "Allow the EC2 IAM role the permission to access the PII table.",
        "3": "Include the AWS API in the application code logic to obtain temporary credentials from the EC2 IAM role to access the PII table.",
        "4": "Include the AssumeRole API operation in the application code logic to obtain temporary credentials to access the PII table.",
        "5": "Include the GetSessionToken API operation in the application code logic to obtain temporary credentials to access the PII table.",
        "no": "339",
        "question": "A company stores all personally identifiable information (PII) in an Amazon DynamoDB table named PII in Account A. Developers are working on an application that is running on Amazon EC2 instances in Account B. The application in Account B requires access to the PII table.An administrator in Account A creates an IAM role named AccessPII that has permission to access the PII table. The administrator also creates a trust policy that specifies Account B as a principal that can assume the role.Which combination of steps should the developers take in Account B to allow their application to access the PII table? (Choose two.)",
        "answer": "AD"
      },
      {
        "1": "Amazon DynamoDB with operations made with the ConsistentRead parameter set to true",
        "2": "Amazon ElastiCache for Memcached with operations made within a transaction block",
        "3": "Amazon DynamoDB with reads and writes made by using Transact* operations",
        "4": "Amazon Aurora MySQL with operations made within a transaction block",
        "5": "Amazon Athena with operations made within a transaction block",
        "no": "340",
        "question": "A gaming website gives users the ability to trade game items with each other on the platform. The platform requires both users' records to be updated and persisted in one transaction. If any update fails, the transaction must roll back.Which AWS solutions can provide the transactional capability that is required for this feature? (Choose two.)",
        "answer": "CD"
      },
      {
        "1": "Use the ssm dynamic reference.",
        "2": "Use the Ref intrinsic function.",
        "3": "Use the Fn::ImportValue intrinsic function.",
        "4": "Use the ssm-secure dynamic reference.",
        "5": 1,
        "no": "341",
        "question": "A developer is deploying an application in the AWS Cloud by using AWS CloudFormation. The application will connect to an existing Amazon RDS database. The hostname of the RDS database is stored in AWS Systems Manager Parameter Store as a plaintext value. The developer needs to incorporate the database hostname into the CloudFormation template to initialize the application when the stack is created.How should the developer reference the parameter that contains the database hostname?",
        "answer": "A"
      },
      {
        "1": "Set the reserved concurrency on the Lambda function to match the number of concurrent requests that the third-party service allows.",
        "2": "Decrease the memory that is allocated to the Lambda function.",
        "3": "Set the provisioned concurrency on the Lambda function to match the number of concurrent requests that the third-party service allows.",
        "4": "Increase the timeout value that is specified on the Lambda function.",
        "5": 1,
        "no": "342",
        "question": "A company uses an AWS Lambda function to call a third-party service. The third-party service has a limit of requests each minute. If the number of requests exceeds the limit, the third-party service returns rate-limiting errors.A developer needs to configure the Lambda function to avoid receiving rate limiting errors from the third-party service.Which solution will meet these requirements?",
        "answer": "A"
      },
      {
        "1": "Create a buildspec file that invokes the AWS Copilot CLI commands to build and deploy the application. Use the AWS Copilot CLI to create an AWS CodePipeline that uses the CodeCommit repository in the source stage and AWS CodeBuild in the build stage.",
        "2": "Use the AWS Serverless Application Model (AWS SAM) CLI to bootstrap and initialize an AWS CodePipeline configuration. Use the CodeCommit repository as the source. Invoke the AWS Copilot CLI to build and deploy the application.",
        "3": "Use the AWS Copilot CLI to define the AWS Copilot pipeline and to deploy the AWS CodePipeline. Select CodeCommit as the source for the AWS CodePipeline.",
        "4": "Define an AWS CloudFormation template for an AWS CodePipeline with CodeCommit as the source. Configure the template as an AWS Copilot CLI add-on. Use the AWS Copilot CLI to deploy the application.",
        "5": 1,
        "no": "343",
        "question": "A developer is building a new containerized application by using AWS Copilot. The developer uses the AWS Copilot command line interface (CLI) to deploy the application during development. The developer committed the application code to a new AWS CodeCommit repository. The developer must create an automated deployment process before releasing the new application to production.What should the developer do to meet these requirements in the MOST operationally efficient way?",
        "answer": "C"
      },
      {
        "1": "A randomly generated universally unique identifier (UUID)",
        "2": "The customer's full name",
        "3": "The date when the customer signed up for the rewards program",
        "4": "The name of the customer's pet",
        "5": 1,
        "no": "344",
        "question": "A developer is creating a new application for a pet store. The application will manage customer rewards points. The developer will use Amazon DynamoDB to store the data for the application. The developer needs to optimize query performance and limit partition overload before actual performance analysis.Which option should the developer use for a partition key to meet these requirements?",
        "answer": "A"
      },
      {
        "1": "The access permissions to the developer's AWS CLI binary file have changed.",
        "2": "The permission set that is assumed by IAM Identity Center does not have the necessary permissions to complete the API call.",
        "3": "The credentials from the IAM Identity Center federated role have expired.",
        "4": "The developer is attempting to make API calls to the incorrect AWS account.",
        "5": 1,
        "no": "345",
        "question": "A developer uses AWS IAM Identity Center (AWS Single Sign-On) to interact with the AWS CLI and AWS SDKs on a local workstation. API calls to AWS services were working when the SSO access was first configured. However, the developer is now receiving Access Denied errors. The developer has not changed any configuration files or scripts that were previously working on the workstation.What is the MOST likely cause of the developer's access issue?",
        "answer": "C"
      },
      {
        "1": "Store the API key in AWS Systems Manager Parameter Store as a string parameter. Use the default AWS KMS key that AWS provides to encrypt the API key.",
        "2": "Store the API key in AWS Lambda environment variables. Create an AWS KMS customer managed key to encrypt the API key.",
        "3": "Store the API key in the code repository. Use an AWS managed key to encrypt the code repository.",
        "4": "Store the API key as an Amazon DynamoDB table record. Use an AWS managed key to encrypt the API key.",
        "5": 1,
        "no": "346",
        "question": "A company is building a serverless application. The application uses an API key to authenticate with a third-party application. The company wants to store the external API key as a part of an AWS Lambda configuration. The company needs to have full control over the AWS Key Management Service (AWS KMS) keys that will encrypt the API key and should be visible only to authorized entities.Which solution will meet these requirements?",
        "answer": "B"
      },
      {
        "1": "Add the permissions to an IAM policy. Attach the policy to a role. Attach the role to the EC2 instance profile.",
        "2": "Add the permissions inline to an IAM group. Attach the group to the EC2 instance profile.",
        "3": "Add the permissions to an IAM policy. Attach the policy to a user. Attach the user to the EC2 instance profile.",
        "4": "Add the permissions to an IAM policy. Use IAM web identity federation to access the S3 bucket with the policy.",
        "5": 1,
        "no": "348",
        "question": "A developer is creating a new batch application that will run on an Amazon EC2 instance. The application requires read access to an Amazon S3 bucket. The developer needs to follow security best practices to grant S3 read access to the application.Which solution meets these requirements?",
        "answer": "D"
      },
      {
        "1": "Use the Amazon Cognito user pools to get short-lived credentials for the second account.",
        "2": "Create a dedicated IAM access key for the second account, and send it by mail.",
        "3": "Create a cross-account access role, and use sts:AssumeRole API to get short-lived credentials.",
        "4": "Establish trust, and add an SSH key for the second account to the IAM user.",
        "5": 1,
        "no": "349",
        "question": "A developer needs temporary access to resources in a second account.What is the MOST secure way to achieve this?",
        "answer": "C"
      },
      {
        "1": "Create an IAM role that has administrative access to AWS. Attach the role to the EC2 instance.",
        "2": "Create an IAM user. Attach the AdministratorAccess policy. Copy the generated access key and secret key. Within the application code, use the access key and secret key along with the AWS SDK to communicate with Amazon S3.",
        "3": "Create an IAM role that has the necessary access to Amazon S3. Attach the role to the EC2 instance.",
        "4": "Create an IAM user. Attach a policy that provides the necessary access to Amazon S3. Copy the generated access key and secret key. Within the application code, use the access key and secret key along with the AWS SDK to communicate with Amazon S3.",
        "5": 1,
        "no": "350",
        "question": "A company wants to migrate applications from its on-premises servers to AWS. As a first step, the company is modifying and migrating a non-critical application to a single Amazon EC2 instance. The application will store information in an Amazon S3 bucket. The company needs to follow security best practices when deploying the application on AWS.Which approach should the company take to allow the application to interact with Amazon S3?",
        "answer": "C"
      }
]