import React from 'react'
import Coming from './images/comingsoon.png'

const ComingSoon = () => {
  return (
    <div className='h-full w-[100vw] max-w-full m-0 p-0 flex justify-center items-center'>
      <img src={Coming} alt='Coming Soon' className='mx-2 my-10 p-3'/>
    </div>
  )
}

export default ComingSoon
