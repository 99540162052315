import React from 'react'
import img from './images/free-bg.png'

const FreeSection = () => {
  return (
    <div className='h-[450px] md:h-[350px] sm:h-[250px] w-full max-w-full my-20 p-3 flex justify-center items-center flex-col flex-nowrap text-white text-center relative z-10'  style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-aos="fade-up">
      <div className="absolute inset-0 bg-[rgba(1,82,205,0.65)] z-0"></div>
      <h1 className='font-manrope font-bold text-5xl lg:text-4xl md:text-3xl sm:text-2xl z-10'>AceExams is 100% free</h1>
      <h1 className='font-mulish font-light text-xl lg:text-lg md:text-base sm:text-sm z-10'>We serve as the largest and most reliable source for real exam questions and answers.</h1>
    </div>
  )
}

export default FreeSection
