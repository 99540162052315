import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css'
import {BrowserRouter} from 'react-router-dom'
import {Toaster} from 'react-hot-toast';
import AppContextProvider from './Appcontext/AppContext';
import RouterRoutes from './RouterRoutes';
import ScrollToTop from './ScrollToTop'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Toaster position="top-center" reverseOrder={false} />
        <RouterRoutes />
      </BrowserRouter>
    </AppContextProvider>
  </React.StrictMode>
);