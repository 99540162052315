import React from 'react'
import CertificateHome from './images/certificate-home.png'

const Certificate = () => {
  return (
    <div className='h-max w-full max-w-full mb-12 px-5 py-3 flex justify-around items-center flex-row flex-wrap-reverse md:flex-wrap-reverse sm:flex-wrap-reverse'>

        <div className='h-max w-[30%] lg:w-full md:w-full sm:w-full max-w-full m-1 p-2 flex justify-center items-center flex-col flex-wrap'>
            <h1 className='h-max w-full max-w-full m-1 font-manrope font-bold text-center text-3xl md:text-2xl sm:text-xl'>Get <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>IT Certificate</span></h1>

            <h1 className='h-max w-full max-w-full m-1 font-mulish font-medium text-xl md:text-lg sm:text-base text-center'>At AceExams, we are dedicated to empowering the IT professionals community by providing valuable resources at no cost. Our commitment to fostering growth and collaboration ensures that individuals can enhance their skills and knowledge without any financial barriers.</h1>
        </div>

        <img src={CertificateHome} className='w-[500px] max-w-full aspect-square m-1 p-2' data-aos="zoom-in-down"/>
      
    </div>
  )
}

export default Certificate
