import React from 'react'
import Chield from './images/Chield_check.svg'
import File from './images/File_dock_add.svg'
import Mortar from './images/Mortarboard_light.svg'
import User from './images/User_add.svg'

const Detail = () => {

    const statDetails = [
        {
            stat:150,
            text:"CERT Providers",
            icon:Chield
        },
        {
            stat:30,
            text:"Exams",
            icon:Mortar

        },
        {
            stat:10,
            text:"Avg. Daily Updates",
            icon:File

        },
        {
            stat:1000,
            text:"Online Users",
            icon:User

        },
    ]
  return (
    <div className='h-max w-full max-w-full my-16 px-4 py-8 flex justify-around items-center flex-row flex-wrap bg-[rgba(244,248,255,1)]' data-aos="fade-up">

        {
            statDetails.map((ele,id)=>{
                return <>
                        <div className='h-max w-[250px] flex justify-around items-center flex-row flex-nowrap'>
                            <img src={ele.icon} className='h-16 md:h-14 sm:h-12 w-16 md:w-14 sm:w-12 m-3 bg-[rgba(1,82,205,1)] border-[5px] rounded-full border-white shadow-[0px_0px_20px_rgba(16,82,205,1)]'/>
                            <div className='h-max w-[150px] m-2 flex justify-center items-start flex-col flex-nowrap'>
                            <h1 className='h-max w-max font-bold font-manrope text-lg md:text-base sm:text-sm'>{ele.stat}+</h1>
                            <h1 className='h-max w-max font-normal font-mulish text-lg md:text-base sm:text-sm'>{ele.text}</h1>
                        </div>
        </div>
                </>
            })
        }
    </div>
  )
}

export default Detail
