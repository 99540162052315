import React, { useContext, useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";  
import { useParams,useNavigate } from 'react-router-dom';
import { AppContext } from '../Appcontext/AppContext';
import ComingSoon from '../ComingSoon';
import Coming from '../images/comingsoon.png'
import NFound from '../images/notfound.png'
import Footer from '../Footer';

const ExamMCQ = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
    }, []);

    const { data } = useContext(AppContext);
    const { category, examId, page = '1' } = useParams();
    const [soln, setSoln] = useState({});
    const navigate = useNavigate();
    const questionsPerPage = 10;

    function changeSoln(id){
        setSoln((prevSoln) => ({
            ...prevSoln,
            [id]: !prevSoln[id] 
        }));
    }

    if (!category || !examId) {
        return <ComingSoon />;
    }

    const selectedCategory = data.find(item => item.title.toLowerCase() === category.toLowerCase());
    if (!selectedCategory) {
        return <ComingSoon />;
    }

    const selectedExam = selectedCategory.links.find(link => link.url.includes(examId));

    if (!selectedExam || !selectedExam.mcq || selectedExam.mcq.length === 0) {
        return <div className='h-full w-[100vw] max-w-full m-0 p-0 flex justify-center items-center flex-col'>
            <div className='h-max w-[1000px] max-w-full my-8 mx-auto px-4 py-8 rounded-2xl bg-[rgba(205,225,255,0.30)] shadow-[0px_0px_20px_5px_rgba(232,243,255,1)] text-center flex justify-center items-center flex-col flex-nowrap'>
                <p className='h-max w-full max-w-full font-manrope font-bold text-3xl lg:text-2xl md:text-xl sm:text-lg'>{selectedExam.name} MCQs</p>
                <h2 className='h-max w-full max-w-full font-manrope font-semibold text-xl lg:text-lg md:text-base sm:text-sm'>Practice Questions</h2>
            </div>
            <img src={Coming} alt='Coming Soon' className='m-2 p-3'/>

            <Footer/>
        </div>  
    }

    const handlePageChange = (newPage) => {
        const validPage = Math.min(Math.max(1, newPage), totalPages);
        navigate(`/${category}/${examId}/${validPage}`);
    };

    const totalQuestions = selectedExam.mcq.length;
    const totalPages = Math.ceil(totalQuestions / questionsPerPage);
    const currentPage = parseInt(page);

    if (currentPage > totalPages || currentPage < 1) {
        return <div className='h-full w-[100vw] max-w-full m-0 p-0 flex justify-center items-center flex-col'>
            <div className='h-max w-[1000px] max-w-full my-8 mx-auto px-4 py-8 rounded-2xl bg-[rgba(205,225,255,0.30)] shadow-[0px_0px_20px_5px_rgba(232,243,255,1)] text-center flex justify-center items-center flex-col flex-nowrap'>
                <p className='h-max w-full max-w-full font-manrope font-bold text-3xl lg:text-2xl md:text-xl sm:text-lg'>{selectedExam.name} MCQs</p>
                <h2 className='h-max w-full max-w-full font-manrope font-semibold text-xl lg:text-lg md:text-base sm:text-sm'>Practice Questions</h2>
            </div>
            <img src={NFound} alt='Coming Soon' className='m-2 p-3'/>

            <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} className={`h-max w-max m-2 px-4 py-2 bg-[#E9F2FF] font-mulish rounded-xl hover:cursor-pointer ${currentPage === 1 ? 'hidden' : 'block'}`}>Navigate to Question</button>

            <Footer/>
        </div>;
    }

    const startQuestion = (currentPage - 1) * questionsPerPage + 1;
    const endQuestion = Math.min(currentPage * questionsPerPage, totalQuestions);

    const currentQuestions = selectedExam.mcq.slice(startQuestion - 1, endQuestion);

    

    return (
        <div className=''>
            <div className='h-max w-full max-w-full my-8 px-6 py-8 sm:py-0'>
                <div className='h-max w-[1000px] max-w-full my-2 mx-auto px-4 py-8 rounded-2xl bg-[rgba(205,225,255,0.30)] shadow-[0px_0px_20px_5px_rgba(232,243,255,1)] text-center flex justify-center items-center flex-col flex-nowrap'>
                    <h2 className='h-max w-full max-w-full font-manrope font-bold text-3xl lg:text-2xl md:text-xl sm:text-lg'>{selectedExam.name} MCQs</h2>
                    <h2 className='h-max w-full max-w-full font-manrope font-semibold text-xl lg:text-lg md:text-base sm:text-sm'>Practice Questions</h2>
                    <div className='h-max w-full max-w-full mx-2 my-4 p-2 flex justify-around items-center flex-row flex-wrap'>

                        <div className='h-max w-[250px] m-2 flex justify-center items-center flex-col flex-nowrap'>
                            <h1 className='h-max w-full max-w-full font-manrope text-lg md:text-base sm:text-sm'>Viewing page</h1>
                            <p className='h-max w-full max-w-full m-1 px-4 py-4 bg-[#FFFFFF] shadow-[0px_0px_20px_rgba(205,225,255,0.15)] rounded-2xl font-mulish text-base md:text-sm sm:text-xs'>
                                <span className='h-max w-max m-0.5 px-4 py-2 bg-[#E9F2FF] rounded-xl font-extrabold'>{currentPage}</span> out of <span className='h-max w-max m-0.5 px-4 py-2 bg-[#E9F2FF] rounded-xl font-extrabold'>{totalPages}</span>
                            </p>
                        </div>

                        <div className='h-max w-[250px] m-2 flex justify-center items-center flex-col flex-nowrap'>
                            <h1 className='h-max w-full max-w-full font-manrope text-lg md:text-base sm:text-sm'>Viewing Question</h1>
                            <p className='h-max w-full max-w-full m-1 px-4 py-4 bg-[#FFFFFF] shadow-[0px_0px_20px_rgba(205,225,255,0.15)] rounded-2xl font-mulish text-base md:text-sm sm:text-xs'>
                                <span className='h-max w-max m-0.5 px-4 py-2 bg-[#E9F2FF] rounded-xl font-extrabold'>{startQuestion}-{endQuestion}</span> out of <span className='h-max w-max m-0.5 px-4 py-2 bg-[#E9F2FF] rounded-xl font-extrabold'>{totalQuestions}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='h-max w-full max-w-full my-14 p-2 flex justify-center items-center flex-col flex-nowrap gap-12'>
                    {
                        currentQuestions.map((mcqItem) => (
                            <div className='h-max w-[1000px] max-w-full m-2 px-4 py-3 rounded-[10px] bg-[#EAF3FF]' key={mcqItem.no}>

                                <h3 className='h-max w-full max-w-full m-1 font-manrope font-bold text-xl md:text-lg sm:text-base'>Question {mcqItem.no}:</h3>

                                <p className='h-max w-full max-w-full my-2 p-3 rounded-[10px] bg-white font-manrope font-medium text-lg md:text-base sm:text-sm' style={{ whiteSpace: 'pre-wrap'}}>{mcqItem.question}</p>

                                <ul>
                                    <li className='h-max w-full max-w-full my-0.5 p-1 flex justify-start items-center flex-row flex-wrap'>
                                        <p className='h-max w-max max-w-full my-1 px-3 py-2 bg-white rounded-[10px] hover:cursor-pointer font-mulish font-normal text-base md:text-sm sm:text-sm'>
                                            <span className='h-3 w-3 m-0.5 px-2 md:px-1 sm:px-1 py-1 md:py-0 sm:py-0 border-2 border-[#61A1FF] text-[#0068FF] font-manrope font-bold text-sm sm:text-xs rounded-full'>A</span> {mcqItem["1"]}
                                        </p>

                                        {soln[mcqItem.no] && (
                                            <div>
                                                {mcqItem.answer.includes("A") && <p className='h-max w-max max-w-full mx-4 my-1 px-4 py-1 bg-[#27D076] border-4 border-white rounded-[10px] text-white font-manrope font-extrabold text-base md:text-sm sm:text-xs'>Correct Answer</p>}
                                            </div>
                                        )}
                                    </li>

                                    <li className='h-max w-full max-w-full my-0.5 p-1 flex justify-start items-center flex-row flex-wrap'>
                                        <p className='h-max w-max max-w-full my-1 px-3 py-2 bg-white rounded-[10px] hover:cursor-pointer font-mulish font-normal text-base md:text-sm sm:text-sm'>
                                            <span className='h-3 w-3 m-0.5 px-2 md:px-1 sm:px-1 py-1 md:py-0 sm:py-0 border-2 border-[#61A1FF] text-[#0068FF] font-manrope font-bold text-sm sm:text-xs rounded-full'>B</span> {mcqItem["2"]}
                                        </p>

                                        {soln[mcqItem.no] && (
                                            <div>
                                                {mcqItem.answer.includes("B") && <p className='h-max w-max max-w-full mx-4 my-1 px-4 py-1 bg-[#27D076] border-4 border-white rounded-[10px] text-white font-manrope font-extrabold text-base md:text-sm sm:text-xs'>Correct Answer</p>}
                                            </div>
                                        )}
                                    </li>

                                    <li className='h-max w-full max-w-full my-0.5 p-1 flex justify-start items-center flex-row flex-wrap'>
                                        <p className='h-max w-max max-w-full my-1 px-3 py-2 bg-white rounded-[10px] hover:cursor-pointer font-mulish font-normal text-base md:text-sm sm:text-sm'>
                                            <span className='h-3 w-3 m-0.5 px-2 md:px-1 sm:px-1 py-1 md:py-0 sm:py-0 border-2 border-[#61A1FF] text-[#0068FF] font-manrope font-bold text-sm sm:text-xs rounded-full'>C</span> {mcqItem["3"]}
                                        </p>

                                        {soln[mcqItem.no] && (
                                            <div>
                                                {mcqItem.answer.includes("C") && <p className='h-max w-max max-w-full mx-4 my-1 px-4 py-1 bg-[#27D076] border-4 border-white rounded-[10px] text-white font-manrope font-extrabold text-base md:text-sm sm:text-xs'>Correct Answer</p>}
                                            </div>
                                        )}
                                    </li>

                                    <li className='h-max w-full max-w-full my-0.5 p-1 flex justify-start items-center flex-row flex-wrap'>
                                        <p className='h-max w-max max-w-full my-1 px-3 py-2 bg-white rounded-[10px] hover:cursor-pointer font-mulish font-normal text-base md:text-sm sm:text-sm'>
                                            <span className='h-3 w-3 m-0.5 px-2 md:px-1 sm:px-1 py-1 md:py-0 sm:py-0 border-2 border-[#61A1FF] text-[#0068FF] font-manrope font-bold text-sm sm:text-xs rounded-full'>D</span> {mcqItem["4"]}
                                        </p>

                                        {soln[mcqItem.no] && (
                                            <div>
                                                {mcqItem.answer.includes("D") && <p className='h-max w-max max-w-full mx-4 my-1 px-4 py-1 bg-[#27D076] border-4 border-white rounded-[10px] text-white font-manrope font-extrabold text-base md:text-sm sm:text-xs'>Correct Answer</p>}
                                            </div>
                                        )}
                                    </li>

                                    {mcqItem["5"]!==1 && <li className='h-max w-full max-w-full my-0.5 p-1 flex justify-start items-center flex-row flex-wrap'>
                                        <p className='h-max w-max max-w-full my-1 px-3 py-2 bg-white rounded-[10px] hover:cursor-pointer font-mulish font-normal text-base md:text-sm sm:text-sm'>
                                            <span className='h-3 w-3 m-0.5 px-2 md:px-1 sm:px-1 py-1 md:py-0 sm:py-0 border-2 border-[#61A1FF] text-[#0068FF] font-manrope font-bold text-sm sm:text-xs rounded-full'>E</span> {mcqItem["5"]}
                                        </p>

                                        {soln[mcqItem.no] && (
                                            <div>
                                                {mcqItem.answer.includes("E") && <p className='h-max w-max max-w-full mx-4 my-1 px-4 py-1 bg-[#27D076] border-4 border-white rounded-[10px] text-white font-manrope font-extrabold text-base md:text-sm sm:text-xs'>Correct Answer</p>}
                                            </div>
                                        )}
                                    </li>}
                                </ul>

                                <div className='h-max w-full max-w-full m-1 p-1 flex justify-between items-center flex-row flex-wrap'>
                                    {
                                        soln[mcqItem.no]? 
                                            (<p className='h-max w-max max-w-full m-1 p-1 font-manrope font-extrabold text-xl md:text-lg sm:text-base'>Correct Answer is: {mcqItem.answer}</p>
                                            ):(
                                            <p></p>)
                                    }

                                    <button onClick={()=>changeSoln(mcqItem.no)} className='h-max w-max mx-2 my-1 px-4 py-1 bg-[#2281FF] border-2 border-[#006AF8] rounded-[10px] text-white font-manrope font-bold text-lg md:text-base sm:text-sm'>{soln[mcqItem.no] ? "Hide Solution":"Show Solution"}</button>
                                </div>
                            </div>
                    ))}
                </div>


                <div className='h-max w-full max-w-full my-4 p-2 flex justify-around items-center flex-row flex-nowrap'>
                    <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} className={`h-max w-max m-2 px-4 py-2 bg-[#E9F2FF] font-mulish rounded-xl hover:cursor-pointer ${currentPage === 1 ? 'hidden' : 'block'}`}>Previous Page</button>

                    <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} className={`h-max w-max m-2 px-4 py-2 bg-[#E9F2FF] font-mulish rounded-xl hover:cursor-pointer ${currentPage === totalPages ? 'hidden' : 'block'}`}>Next Page</button>
                </div>

            </div>

            <Footer/>
        </div>
    );
};

export default ExamMCQ;