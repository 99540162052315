import React, { useEffect } from 'react'
import Header from './Header'
import { Routes,Route } from 'react-router-dom'
import App from './App'
import NotFound from './NotFound'
import ComingSoon from './ComingSoon'
import ExamMCQ from './components/ExamMCQ'


const RouterRoutes = () => {
    

    return (
        <div className='min-h-screen w-[100vw] max-w-full m-0 p-0 overflow-x-hidden overflow-y-auto transition-all'>
            <Header/>

            <Routes>
                <Route path='/' element={<App />} />
                {/* <Route path='/pro' element={<ComingSoon />} /> */}

                <Route path='/:category'>
                    <Route path=':examId'>
                        <Route index element={<ExamMCQ />} />
                        <Route path=':page' element={<ExamMCQ />} />
                    </Route>
                </Route>

                <Route path="*" element={<NotFound />}/>
            </Routes>
        </div>
    )
}

export default RouterRoutes
