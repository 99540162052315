import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";  
import Aboutus from "./AboutUs";
import Certificate from "./Certificate";
import Detail from "./Detail";
import Feedback from "./Feedback";
import FreeSection from "./FreeSection";
import Hero from "./Hero";
import SocialMedia from "./SocialMedia";
import Footer from "./Footer";
import Exams from "./Exams";


function App() {

  useEffect(() => {
    AOS.init({ duration: 1000 });  // Optional: set custom settings
  }, []);

  return (
    <div className='min-h-screen w-[100vw] max-w-full m-0 p-0 overflow-x-hidden overflow-y-auto transition-all'>
      <Hero/>

      <Exams/>

      <Detail/>

      <Aboutus/>

      <FreeSection/>

      <Feedback/>
      
      <Certificate/>

      <SocialMedia/>

      <Footer/>
    </div>
    
  );
}

export default App;
