import React from 'react'
import { MdPerson } from "react-icons/md";

const FeedbackCard = ({name,message}) => {
  return (
    <div className='h-[300px] md:h-max sm:h-max w-[500px] max-w-full m-1 p-3 rounded-2xl bg-white flex justify-start items-center flex-col flex-nowrap' data-aos="zoom-in">
      
      <div className='h-[50px] w-full max-w-full m-2 flex justify-start items-center flex-row flex-nowrap'>
        <p className='h-full w-[50px] bg-[rgba(232,241,255,1)] m-1 rounded-full flex justify-center items-center'>
          <MdPerson className='text-3xl md:text-2xl sm:text-xl'/>
        </p>

        <h1 className='h-max w-max m-1 p-1 font-manrope font-medium text-2xl md:text-xl sm:text-lg'>{name}</h1>
      </div>

      <p className='m-1 p-1 font-mulish font-normal text-sm md:text-xs sm:text-[10px]' style={{ whiteSpace: 'pre-wrap'}}>{message}</p>
    </div>
  )
}

export default FeedbackCard
