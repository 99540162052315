import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Drawer = ({ isOpen, onClose }) => {
    const headerOptions= [
        {
            optionName:"Home",
            link:"/"
        },
        {
            optionName:"Aws",
            link:"/#exams"
        },
        {
            optionName:"Microsoft",
            link:"/#exams"
        },
        {
            optionName:"About Us",
            link:"/#aboutus"
        }
    ];

    return (
        <div className={`fixed inset-y-0 z-50 right-0 w-64 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out hidden md:block sm:block`}>
            <div className='p-4'>
                <button onClick={onClose} className='mb-4'>
                <IoMdClose className='text-2xl' />
                </button>
                <nav className='flex flex-col space-y-4 text-base font-mulish font-normal'>
                    {
                        headerOptions.map((ele,id)=>{
                            return <p key={id} onClick={onClose}><Link to={ele.link}>{ele.optionName}</Link></p>
                        })
                    }
                </nav>
            </div>
        </div>
    )
}

export default Drawer;