import React from 'react'

const Hero = () => {

    const cardDetails = [
        {
            stat:74,
            text:"Found our practice questions closely aligned with actual exam content."
        },
        {
            stat:89,
            text:"Achieved their desired scores using AceExams resources."
        },
        {
            stat:99,
            text:"Rated our study materials as essential for exam success."
        },
    ];
  return (
    <div className='h-max w-full max-w-full my-12 px-6 py-8 sm:py-0'>
        <div className=' h-max w-[1000px] max-w-full my-2 mx-auto p-2 rounded-2xl bg-[rgba(205,225,255,0.30)] shadow-[0px_0px_20px_5px_rgba(232,243,255,1)] flex justify-center items-center flex-col flex-nowrap'>
            <div className='h-max w-max max-w-full mx-2 my-10 p-2 flex justify-center items-center flex-col flex-nowrap'>
                <h1 className='h-max w-max max-w-full m-1 font-manrope font-bold text-center text-4xl md:text-3xl sm:text-xl'>Ace your exams with <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>AceExams!</span></h1>

                <h1 className='h-max w-max max-w-full m-1 text-center font-mulish font-normal text-xl md:text-lg sm:text-base'>Elevate Your Knowledge, Excel in Exams</h1>

                <div className='h-max w-full max-w-full my-8 sm:mb-4 flex justify-center items-center flex-row flex-wrap gap-4'>
                    
                    {
                        cardDetails.map((ele,id)=>{
                            return <>
                            <div className='h-[150px] md:h-[125px] sm:h-max w-[250px] md:w-[210px] sm:w-[170px] m-1 px-2 py-4 bg-white flex justify-start items-center flex-col flex-nowrap text-center font-mulish rounded-xl shadow-[0px_0px_20px_rgba(205,225,255,0.5)]' data-aos="fade-right">
                                <h1 className='mb-2 font-bold text-4xl md:text-3xl sm:text-2xl'>{ele.stat}%</h1>
                                <h1 className='font-normal text-base md:text-sm sm:text-xs'>{ele.text}</h1>
                            </div>
                            </>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero
