import React from 'react'
import FeedbackCard from './components/FeedbackCard'

const Feedback = () => {

    const feedbacks = [
        {
            userName:"Brad Haverford",
            feedback:`“I used AceExams to prepare for my AWS Certified Solutions Architect exam, and it was an absolute game-changer. The practice questions were spot on, reflecting the difficulty and structure of the actual exam. What I loved the most was how detailed the explanations were for each answer, helping me understand the concepts deeply. I went into my exam feeling confident and passed on my first attempt. Highly recommend AceExams to anyone looking to ace their certification exams!”`
        },
        {
            userName:"Steven Fasnacht",
            feedback:`“Thanks to AceExams, I passed my AZ-104 certification exam on the first try! The platform provided such a wide variety of practice questions that covered all the important areas of the exam. I was able to track my progress and focus on the sections where I needed more practice. The interface was user-friendly, and the experience felt very similar to the real test. AceExams gave me the confidence I needed, and I’m thrilled with the results!”`
        },
        {
            userName:"Greg Waller",
            feedback:`“Preparing for the Google Cloud Associate exam was stressful until I found AceExams. The practice questions were so well-organized and comprehensive that I didn’t need to look anywhere else. I appreciated how the platform mimicked the actual exam format, so when I sat for the test, I felt like I had already experienced it. I’m so thankful for this resource – I passed with ease and couldn’t have done it without AceExams!”`
        },
    ]
  return (
    <div className='h-max w-full max-w-full my-10 md:my-8 sm:my-6 flex justify-center items-center flex-col flex-nowrap'>
        <h1 className='h-max w-max max-w-full font-manrope font-bold text-center text-4xl md:text-3xl sm:text-2xl'>User <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>Feedbacks</span></h1>

        <div className='h-max w-full max-w-full my-12 px-3 py-6 flex justify-center items-start flex-row flex-wrap gap-9 md:gap-6 sm:gap-4 bg-[rgba(125,177,255,0.2)]'>
            {
                feedbacks.map((ele,id)=>{
                    return <FeedbackCard 
                    name={ele.userName}
                    message={ele.feedback}
                />
                })
            }
        </div>

    </div>
  )
}

export default Feedback
