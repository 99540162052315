import React from 'react'
import Notf from './images/notfound.png'

const NotFound = () => {
  return (
    <div className='h-full w-[100vw] max-w-full m-0 p-0 flex justify-center items-center'>
      <img src={Notf} alt='Not Found' className='mx-2 my-10 p-3'/>
    </div>
  )
}

export default NotFound
