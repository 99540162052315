import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";

const EachExamContainer = ({key,img,title,link}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="h-max w-[250px] max-w-full m-2 p-1 flex justify-center items-center flex-col flex-nowrap relative" key={key}>
      <div className="h-[150px] w-full m-1 p-1 aspect-video bg-[#F4F8FF] rounded-xl flex justify-center items-center">
        <img src={img} alt={title} />
      </div>

      <div className="h-max w-full bg-[#F4F8FF] m-0.5 p-2 rounded-md cursor-pointer flex justify-between items-center" onClick={toggleDropdown}>
        <span className="h-max w-max font-manrope font-semibold text-base md:text-sm sm:text-xs">{`${title} Certification`}</span>
        <button>
          {isOpen ? <FaChevronUp size={16} /> : <FaChevronDown size={16} />}
        </button>
      </div>

      {isOpen && (
        <div ref={dropdownRef} className="absolute top-full -left-6 sm:-left-8 w-[320px] sm:w-[280px] m-1 p-2 list-disc bg-white rounded-md border-2 border-[#DDDDDD] z-50">
          {link.length > 0 ? (
            link.map((data, index) => (
              <li key={index} className="flex">
                <Link to={data.url} className="h-max w-full flex items-start font-mulish font-bold text-[#0253CE] text-sm md:text-xs sm:text-[10px]" onClick={() => setIsOpen(false)}>
                  <span className="mr-2 text-black flex-shrink-0">•</span>
                  <span className="tracking-wide">{data.name}</span>
                </Link>
              </li>
            ))
          ) : (
            <p className="text-[#0253CE] font-mulish font-bold text-xl md:text-lg sm:text-base">Coming Soon</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EachExamContainer;
