import React from 'react'
import Instagram from './images/Instagram.svg'
import Reddit from './images/Reddit_black.svg'
import Twitter from './images/Twitter_black.svg'
import Mail from './images/Mail_black.svg'
import Youtube from './images/Youtube_black.svg'

const SocialMedia = () => {

  const socialMediaDetails = [
    {logo : Instagram},
    {logo : Reddit},
    {logo : Twitter},
    {logo : Mail},
    {logo : Youtube},
    ]
  return (
    <div className='h-max w-full max-w-full mt-6 px-3 py-5 flex justify-center items-center flex-col flex-nowrap bg-[#DFEAFF]'>
        <h1 className='h-max w-max max-w-full m-1 font-manrope font-bold text-center text-3xl md:text-2xl sm:text-xl'>Connect with us on <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>Social Media Platforms</span></h1>

        <div className='h-max w-[640px] max-w-full mt-2 flex justify-around items-center flex-row flex-wrap'>
          {
            socialMediaDetails.map((ele,id)=>{
              return  <img src={ele.logo} className='h-[60px] md:h-[50px] sm:h-[40px] aspect-square' data-aos="flip-down"/>

            })
          }
        </div>
    </div>
  )
}

export default SocialMedia
