import React from 'react'
import arrow1 from './images/arrow1.png'
import arrow2 from './images/arrow2.png'

const AboutUs = () => {
  return (
    <div id='aboutus' className='h-max w-full max-w-full flex justify-center items-start flex-col flex-nowrap px-6 py-4'>

        <h1 className='font-manrope font-semibold m-1 text-4xl md:text-3xl sm:text-2xl'>About Us</h1>

        <div className='h-max w-full max-w-full my-6 md:my-4 sm:my-2 flex justify-between items-center flex-row flex-wrap relative md:flex-col md:items-start sm:flex-col'>

            <div className='h-[350px] md:h-[250px] sm:h-[150px] w-[30%] md:w-[40%] sm:w-full m-1 p-3 border-8 border-[#E8F1FF] rounded-2xl bg-gradient-to-br from-[#0152CD] via-[#0093EC] to-[#2AAFFF] flex justify-center items-center' data-aos="fade-right">
                <h1 className='h-max w-full text-wrap m-1 p-1 font-mulish font-semibold text-white text-center rotate-[5deg] text-3xl md:text-lg sm:text-base'>Who are we?<br/>What do we get from this?</h1>
            </div>

            <img src={arrow1} className='absolute bottom-5 left-[28%] w-[15%] sm:w-[20%] md:top-[45%] md:left-[10%] md:rotate-45 sm:top-[26%] sm:left-[15%] sm:rotate-45 z-20'/>

            <div className='h-[350px] sm:h-max max-h-max w-[60%] md:w-[80%] sm:w-full bg-[#E8F1FF] m-1 md:ml-auto p-4 flex justify-center items-start gap-3 sm:gap-1 flex-col flex-nowrap text-wrap font-mulish rounded-2xl text-lg lg:text-base md:text-sm sm:text-[12px]' data-aos="fade-left">
                <p>High-quality exam prep materials at no cost, free from paywalls.</p>
                <p>Avoid paywalls and expensive resources, standing against exploitative practices.</p>
                <p>Powered by the IT community, our resources are available to everyone, regardless of financial situation.</p>
                <p>Provide equitable access as a free alternative to costly services.</p>
                <p>Founded by IT experts, aiming to reform the exam industry with fair and accessible options.</p>

            </div>

        </div>


        <div className='h-max w-full max-w-full my-6 md:my-4 sm:my-2 flex justify-between items-center flex-row flex-wrap relative md:flex-col-reverse md:items-start sm:flex-col-reverse'>

            <div className='h-[350px] sm:h-max max-h-max w-[60%] md:w-[80%] sm:w-full bg-[#E8F1FF] m-1 p-4 flex justify-center items-start gap-3 sm:gap-1 flex-col flex-nowrap text-wrap font-mulish rounded-2xl text-lg lg:text-base md:text-sm sm:text-[12px]' data-aos="fade-right">
                <p>High-quality exam prep materials at no cost, free from paywalls.</p>
                <p>Avoid paywalls and expensive resources, standing against exploitative practices.</p>
                <p>Powered by the IT community, our resources are available to everyone, regardless of financial situation.</p>
                <p>Provide equitable access as a free alternative to costly services.</p>
                <p>Founded by IT experts, aiming to reform the exam industry with fair and accessible options.</p>

            </div>

            <img src={arrow2} className='absolute bottom-5 right-[28%] w-[15%] sm:w-[20%] md:top-[45%] md:right-[10%] md:-rotate-45 sm:top-[26%] sm:right-[15%] sm:-rotate-45 z-20'/>

            <div className='h-[350px] md:h-[250px] sm:h-[150px] w-[30%] md:w-[40%] sm:w-full m-1 md:ml-auto p-3 border-8 border-[#E8F1FF] rounded-2xl bg-gradient-to-bl from-[#0152CD] via-[#0093EC] to-[#2AAFFF] flex justify-center items-center' data-aos="fade-left">
                <h1 className='h-max w-full text-wrap m-1 p-1 font-mulish font-semibold text-white text-center -rotate-[5deg] text-3xl md:text-lg sm:text-base'>Why do we do this?</h1>
            </div>

        </div>

    </div>
  )
}

export default AboutUs
