import React, { useContext } from 'react'
import EachExamContainer from './components/EachExamContainer'
import { AppContext } from './Appcontext/AppContext'

const Exams = () => {
    const {data} = useContext(AppContext);
  return (
    <div id='exams' className='h-max w-full max-w-full my-14 md:my-12 sm:my-10 px-4 py-8 flex justify-center items-center flex-col flex-nowrap'>
        <h1 className='h-max w-max max-w-full m-1 font-manrope font-bold text-center text-4xl md:text-3xl sm:text-xl'>Leading <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>Exams</span> & <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#1052CD] to-[#0496EE]'>Certification</span> Resources</h1>
        
        <h1 className='h-max w-max max-w-full m-1 text-center font-mulish font-normal text-xl md:text-lg sm:text-base'>AceExams is <b>not</b> affiliated or certified by any certification provider.</h1>

        <div className='h-max w-full max-w-full mx-2 my-4 px-6 py-6 flex justify-around items-center flex-row flex-wrap'>
            {
                data.map((exam) => {
                    return <EachExamContainer key={exam.id} img={exam.img} title={exam.title} link={exam.links} />
                })
            }
        </div>

        {/* <div className='h-max w-full max-w-full m-2 px-6 flex justify-end items-center'>
            <button className='h-max w-max max-w-full m-1 px-6 py-1 font-mulish font-black text-sm sm:text-xs bg-gradient-to-r from-[#0496EE] to-[#2AAFFF] text-white rounded-full'>View more</button>
        </div> */}
    </div>
  )
}

export default Exams
